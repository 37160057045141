import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetStaffDesignationList,
    SaveStaffDesignation,
    ActiveInactiveStaffDesignation,
    DeleteStaffDesignation,
} from '../../../Services/StaffDesignation'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import type {
    StaffDesignationListInterface,
    StaffDesignationFormDataInterface,
    StaffDesignationListDataTypeInterface,
} from '../../../Types/StaffDesignations'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import StaffDesignationForm from './staffDesignationForm'
import StaffDesignationExpandView from './staffDesignationExpandView'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const StaffDesignationItemDefaultData = {
    clientCategoryName: '',
    clientCategoryId: null,
    departmentName: '',
    departmentId: null,
    description: '',
    activeStatus: '',
    designationId: '0',
    designationName: '',
}

const StaffDesignationList = (
    props: StaffDesignationListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { staffDesignationList, staffDesignationListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [staffDesignationId, setStaffDesignationId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState('Create Designation')
    const [editData, setEditData] = useState<StaffDesignationFormDataInterface>(
        StaffDesignationItemDefaultData
    )
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const [isLoadingModal, setIsLoadingModal] = useState(false)
    const [restData, setResetData] = useState('false')
    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetStaffDesignationList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: staffDesignationListCount,
            },
        })
    }, [staffDesignationListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        staffDesignationListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<StaffDesignationListDataTypeInterface> = [
        {
            title: 'Company Group',
            dataIndex: 'clientCategoryName',
        },
        {
            title: 'Departments',
            dataIndex: 'departmentName',
        },
        {
            title: 'Staff Designations',
            dataIndex: 'designationName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: StaffDesignationListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1004', PermissionType.UPDATE) && (
                            <Tooltip title="Staff Designation">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.designationId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.activeStatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activeStatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.designationId,
                                        record.activeStatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1004', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.designationId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<StaffDesignationListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'designationName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let staffDesignationId = ''
        if (!deleteModalStatus) {
            staffDesignationId = id
        }
        setStaffDesignationId(staffDesignationId)
    }

    const deleteStaffDesignation = (): void => {
        DeleteStaffDesignation(dispatch, staffDesignationId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = staffDesignationList.find(
            (data: StaffDesignationFormDataInterface) => {
                return data.designationId === id
            }
        )

        let tittle = 'Create Designation'
        if (id !== null && id !== undefined && id !== '') {
            tittle = 'Edit Designation'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? StaffDesignationItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let staffDesignationId = ''
        if (!activeInactiveModalStatus) {
            staffDesignationId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setStaffDesignationId(staffDesignationId)
    }

    const updateActiveInactiveStaffDesignation = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveStaffDesignation(
            dispatch,
            staffDesignationId,
            status,
            getTableData
        )
        activeInactiveModalFunction('', '')
    }

    const onSaveStaffDesignation = (
        data: StaffDesignationFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveStaffDesignationData(data, callBack)
    }

    const saveStaffDesignationData = async (
        data: StaffDesignationFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        setIsLoadingModal(true)
        await SaveStaffDesignation(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                    setIsLoadingModal(false)
                }
            })
            .catch(() => {})
    }

    const expandableData = (
        record: StaffDesignationListDataTypeInterface
    ): React.ReactElement => {
        return <StaffDesignationExpandView staffDesignationData={record} />
    }

    return (
        <>
            <Card
                title="Staff Designations"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                            setResetData('true')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Designation by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={staffDesignationList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this designation. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteStaffDesignation}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this designation. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveStaffDesignation}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <StaffDesignationForm
                        onSave={onSaveStaffDesignation}
                        editData={editData}
                        isLoadingModal={isLoadingModal}
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): StaffDesignationListInterface => {
    return {
        staffDesignationList: state.initial.staffDesignationList,
        staffDesignationListCount: state.initial.staffDesignationListCount,
    }
}

export default connect(mapStateToProps)(StaffDesignationList)
