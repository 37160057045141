import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Switch, Select } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type ArticleData,
    type ArticalListFormInterface,
    type ArticalStateInterface,
} from '../../../Types/wikiArtical'
import S3UploadMultiple from '../../../Components/S3UploadMultiple'
import { connect, useDispatch } from 'react-redux'
import { GetArticalGropuList } from '../../../Services/wikiArtical'
import { Quill } from 'react-quill'
import CustomQuill from '../../../Components/CustomQuill'
const Inline = Quill.import('blots/inline')
class BoldBlot extends Inline {}
BoldBlot.blotName = 'bold'
BoldBlot.tagName = 'strong'
Quill.register(BoldBlot, true)

class ItalicBlot extends Inline {}
ItalicBlot.blotName = 'italic'
ItalicBlot.tagName = 'em'
Quill.register(ItalicBlot, true)
const ArticalDetailsForm = (
    props: ArticalListFormInterface
): React.ReactElement => {
    const { onSave, editData, articalGroupList, isLoadingModal } = props
    const [form] = Form.useForm()
    const [emailText, setEmailText] = useState('')
    const dispatch = useDispatch()
    const [articalGroupId, setArticalGroupId] = useState(0)
    useEffect(() => {
        form.setFieldsValue(editData)
        setEmailText(editData.articleDescription)
    }, [editData])
    useEffect(() => {
        GetArticalGropuList(dispatch)
    }, [dispatch])
    const resetForm = (): void => {
        form.resetFields()
        setEmailText('reset form function call')
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )
    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }
    const convertedString = uploadFileList
        .map((item) => item.slice())
        .join(', ')
    const [publishId, setPublishId] = useState(0)
    const onSwitchChange = (value: boolean): void => {
        setPublishId(value ? 1 : 0)
    }

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }
    const handeldatachange = (value: string): void => {
        const articalgroupId = articalGroupList.find(
            (artical) => artical.label === value
        )
        if (articalgroupId !== undefined) {
            setArticalGroupId(articalgroupId?.value)
        }
    }
    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    const datatodave = {
                        articleId: data.articleId,
                        articleGroupId: articalGroupId,
                        articleGroupName: data.articleGroupName,
                        articleName: data.articleName,
                        articleDescription: emailText,
                        icon: editData.icon,
                        iconType: editData.iconType,
                        isPublic: editData.isPublic,
                        isSharedInSocialMedia: editData.isSharedInSocialMedia,
                        isActive: editData.isActive,
                        attachmentURL: convertedString,
                        isPublishInNews: publishId,
                    }
                    onSave(datatodave, resetForm)
                }}
                requiredMark={customizeRequiredMark}
                form={form}
            >
                <Form.Item<ArticleData> name="articleId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ArticleData>
                            label="Article Group"
                            name="articleGroupName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Article Group!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={(value) => {
                                    handeldatachange(value)
                                }}
                                showSearch
                                options={[
                                    { label: 'Please select', value: null },
                                    ...articalGroupList?.map((service) => ({
                                        value: service.label,
                                        label: service.label,
                                    })),
                                ]}
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ArticleData>
                            label=" "
                            name="isPublishInNews"
                        >
                            <Switch
                                checked={editData.isPublishInNews === 1}
                                onClick={(checked) => {
                                    onSwitchChange(checked)
                                }}
                            />
                            <span>This Article is Created to news</span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ArticleData>
                            label="Article Name"
                            name="articleName"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your account number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="articleDescription"
                        >
                            {/* <ReactQuill
                                theme="snow"
                                value={emailText}
                                onChange={(value: string) => {
                                    onDescriptinChange(value)
                                }}
                            /> */}
                            <h3>Below is Custom Component</h3>
                            <CustomQuill
                                onDescriptionChange={onDescriptinChange}
                                emailText={emailText}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ArticleData>
                            label="Attachment"
                            name="attachmentURL"
                        >
                            <S3UploadMultiple
                                name={'attachmentURL'}
                                value={uploadFileList}
                                setFormData={handleSetFormData}
                                corporate={'Wiki'}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={Boolean(isLoadingModal)}
                        >
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): ArticalStateInterface => {
    return {
        articalGroupList: state.wiki.articalGroupList,
    }
}
export default connect(mapStateToProps)(ArticalDetailsForm)
