import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import ProjectForm from './ProjectManagementForm'
import calculatePagination from '../../../Utils/Pagination'
import {
    type ProjectManagmentFormDataInterface,
    type ProjectManagmentListDataTypeInterface,
    type ProjectManagmentListInterface,
} from '../../../Types/ProjectMangement'
import { EyeOutlined } from '@ant-design/icons'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import {
    DeleteProject,
    GetProjectList,
} from '../../../Services/ProjectManagement'
import ProjectManagmentExpandViews from './ProjectEditExpand'
import FullWidthModal from '../../../Components/FullWidthModal'
import { useNavigate } from 'react-router-dom'

export const ProjectItemDefaultData = {
    projectId: '0',
    projectName: '',
    departmentId: null,
    department: '',
    projectDescription: '',
    projectStartDate: '',
    projectEndDate: '',
    completedTaskCount: 0,
    completedHoursCount: 0,
    pendingTaskCount: 0,
    assignedTaskCount: 0,
    inProgressTaskCount: 0,
    clientCategoryName: '',
    clientCategoryId: null,
    projectLeadId: null,
    projectLeadName: '',
    assignUserId: null,
    assignUserName: '',
    projectPrefix: '',
}

const ProjectList = (
    props: ProjectManagmentListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { ProjectManagmentList, ProjectManagmentListCount } = props
    const navigate = useNavigate()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)

    useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('create')
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [editData, setEditData] = useState<ProjectManagmentFormDataInterface>(
        ProjectItemDefaultData
    )
    const hasFetchedOnce = useRef(false)
    const [projectId, setProjectId] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [restData, setResetData] = useState('false')
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetProjectList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: ProjectManagmentListCount,
            },
        })
    }, [ProjectManagmentListCount])
    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        ProjectManagmentListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const toggleExpandViewModal = (id: string): void => {
        const ProjectData = ProjectManagmentList.find(
            (item) => item.projectId === id
        )
        navigate(`/system-configuration/project-management/project-view`, {
            state: { ProjectData },
        })
    }

    const columns: ColumnsType<ProjectManagmentListDataTypeInterface> = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Operational Manager',
            dataIndex: 'projectLeadName',
        },
        {
            title: 'Start Date',
            dataIndex: 'projectStartDate',
        },

        {
            title: 'End Date',
            dataIndex: 'projectEndDate',
        },
        {
            title: 'Group & Partner',
            dataIndex: 'clientCategoryName',
        },
        {
            title: 'Department',
            dataIndex: 'department',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: ProjectManagmentListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit Details">
                            <EditIcon
                                onClick={() => {
                                    openEditModal(record?.projectId)
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="View Details">
                            <EyeOutlined
                                onClick={() => {
                                    toggleExpandViewModal(record.projectId)
                                }}
                                className={commonStyles.viewIcon}
                            />
                        </Tooltip>
                        <Tooltip title="Delete Details">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(record?.projectId)
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ProjectManagmentListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setCurrentPage(1) // Reset to first page
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1, // Reset to first page
            },
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const openEditModal = (id: string): void => {
        const datas = ProjectManagmentList.find(
            (data: ProjectManagmentFormDataInterface) => {
                return data.projectId === id
            }
        )
        let title = 'Create'
        if (id !== null && id !== undefined && id !== '') {
            title = 'Edit'
        }

        setEditModalTittle(title)
        setEditData(datas ?? ProjectItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let projectId = ''
        if (!deleteModalStatus) {
            projectId = id
        }
        setProjectId(projectId)
    }

    const deleteProject = (): void => {
        DeleteProject(dispatch, projectId, getTableData)
        deleteModalFunction('')
    }

    const onSaveSatus = (callBack: CallableFunction): void => {
        changeCreateEditModalStatus()
        getTableData()
        callBack()
    }

    const expandableData = (
        record: ProjectManagmentListDataTypeInterface
    ): React.ReactElement => {
        return <ProjectManagmentExpandViews ProjectData={record} />
    }

    return (
        <>
            <Card
                title="Project Management"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                            setResetData('true')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Project & Project Details by keywords'}
                    onChange={onChangeText}
                    onSearch={onChangeFilter}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={ProjectManagmentList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>

                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                    destroyOnClose={true}
                >
                    <ProjectForm
                        onSave={onSaveSatus}
                        editData={editData}
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                </Modal>

                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Project. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteProject}
                />
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ProjectManagmentListInterface => {
    return {
        ProjectManagmentList: state.initial.ProjectManagmentList,
        ProjectManagmentListCount: state.initial.ProjectManagmentListCount,
    }
}

export default connect(mapStateToProps)(ProjectList)
