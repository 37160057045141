import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetTemplateTypeList,
    SaveTemplateType,
    ActiveInactiveTemplateType,
    DeleteTemplateType,
    CheckTemplateTypeNameExist,
} from '../../../Services/TemplateType'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import type {
    TemplateListInterface,
    TemplateListDataTypeInterface,
    TemplateFormDataInterface,
} from '../../../Types/TemplateTypes'
import TemplateTypeForm from './templateTypeForm'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const TemplateTypeItemDefaultData = {
    templateTypeName: '',
    templateTypeId: '0',
    activeStatus: '',
}

const TemplateTypeList = (props: TemplateListInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { templateList, templateListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [templateTypeId, setTemplateTypeId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Email Template Type'
    )
    const [editData, setEditData] = useState<TemplateFormDataInterface>(
        TemplateTypeItemDefaultData
    )
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const [restData, setResetData] = useState('false')
    const [buttonloading, setButtonLoading] = useState(false)
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetTemplateTypeList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: templateListCount,
            },
        })
    }, [templateListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        templateListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<TemplateListDataTypeInterface> = [
        {
            title: 'Email Template Type',
            dataIndex: 'templateTypeName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            width: 1200,
        },
        {
            key: 'action',
            render: (_, record: TemplateListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1004', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Email Template Type">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.templateTypeId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.activeStatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activeStatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.templateTypeId,
                                        record.activeStatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1004', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.templateTypeId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<TemplateListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'templateTypeName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let templateTypeId = ''
        if (!deleteModalStatus) {
            templateTypeId = id
        }
        setTemplateTypeId(templateTypeId)
    }

    const deleteTemplateType = (): void => {
        DeleteTemplateType(dispatch, templateTypeId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = templateList.find((data: TemplateFormDataInterface) => {
            return data.templateTypeId === id
        })

        let tittle = 'Create Email Template Type'
        if (id !== null && id !== undefined && id !== '') {
            tittle = 'Edit Email Template Type'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? TemplateTypeItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let templateTypeId = ''
        if (!activeInactiveModalStatus) {
            templateTypeId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setTemplateTypeId(templateTypeId)
    }

    const updateActiveInactiveTemplate = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveTemplateType(
            dispatch,
            templateTypeId,
            status,
            getTableData
        )
        activeInactiveModalFunction('', '')
    }

    const onSaveTemplate = (
        data: TemplateFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void CheckTemplateTypeNameExist(
            dispatch,
            data?.templateTypeId,
            data?.templateTypeName
        ).then((result: boolean) => {
            if (!result) {
                void saveTemplateTypeData(data, callBack)
            }
        })
    }

    const saveTemplateTypeData = async (
        data: TemplateFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        setButtonLoading(true)
        SaveTemplateType(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                    setButtonLoading(false)
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card
                title="Email Template Type"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                            setResetData('true')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Email Template Type by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={templateList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Email Template Type. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteTemplateType}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Email Template Type. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveTemplate}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <TemplateTypeForm
                        onSave={onSaveTemplate}
                        editData={editData}
                        isLoadingModal={buttonloading}
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): TemplateListInterface => {
    return {
        templateList: state.initial.templateList,
        templateListCount: state.initial.templateListCount,
    }
}

export default connect(mapStateToProps)(TemplateTypeList)
