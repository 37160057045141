import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Form, Button, Select, Switch, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type ServiceUserSwitchUserInterface,
    type ServiceUserAddAssigneeStateInterface,
} from '../../../Types/ServiceSetup/ServiveUser'
import { GetUserList } from '../../../Services/User'
import {
    GetDefaultAssignee,
    GetDefaultWorkingUser,
} from '../../../Services/ServiceSetup'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const SwitchUserForm = (
    props: ServiceUserSwitchUserInterface
): React.ReactElement => {
    const { serviceId, userList, onSave } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const [labelName, setLabelName] = useState<string>('')
    const [newuserList, setnewUserList] = useState<ListFrontEndInterface[]>([])

    const [removeUserSwitch, setRemoveUserSwitch] = useState(false)
    const [addUserSwitch, setAddUserSwitch] = useState(true)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [])

    const handleUserTypeChange = (value: number): void => {
        if (value === 1) {
            setLabelName('Assignee User')
            void GetDefaultAssignee(
                dispatch,
                serviceId,
                (response: { data: string }) => {
                    const defaultUserIds = response.data.split(',')?.map(Number)

                    const filteredUsers = userList.filter((user) =>
                        defaultUserIds.includes(user.value)
                    )
                    setnewUserList(filteredUsers)
                }
            )
        } else if (value === 2) {
            setLabelName('Working User')
            void GetDefaultWorkingUser(
                dispatch,
                serviceId,
                (response: { data: string }) => {
                    const defaultUserIds = response.data.split(',')?.map(Number)

                    const filteredUsers = userList.filter((user) =>
                        defaultUserIds.includes(user.value)
                    )
                    setnewUserList(filteredUsers)
                }
            )
        }
    }

    const resetForm = (): void => {
        form.resetFields()
    }

    const handleRemoveUserSwitchChange = (checked: boolean): void => {
        setRemoveUserSwitch(checked)
    }
    const handleAddUserSwitchChange = (checked: boolean): void => {
        setAddUserSwitch(checked)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                requiredMark={customizeRequiredMark}
                onFinish={(data) => {
                    const newObj = {
                        switchUserType: data.userType,
                        serviceProcessId: 0,
                        existUserId: data.ExistUserId,
                        newUserId: data.userId,
                        existUserState: removeUserSwitch ? 1 : 0,
                        serviceId,
                        newUserState: addUserSwitch ? 1 : 0,
                    }
                    onSave(newObj, resetForm)
                }}
                form={form}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="User Type"
                            name="userType"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the User Type!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                options={[
                                    { label: 'Assignee User', value: 1 },
                                    { label: 'Working User', value: 2 },
                                ]}
                                onChange={handleUserTypeChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {labelName?.length > 0 && (
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={labelName}
                                name="ExistUserId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Please select"
                                    options={[
                                        { label: 'Please select', value: null },
                                        ...newuserList,
                                    ]}
                                    allowClear
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Switch User"
                            name="userId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Switch User!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Please select"
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                                allowClear
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="existUserState">
                            <div className={commonStyles.switchWrapper}>
                                <Switch
                                    checked={removeUserSwitch}
                                    onChange={handleRemoveUserSwitchChange}
                                />
                                <span style={{ marginLeft: 20 }}>
                                    Remove Exist User
                                </span>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="newUserState">
                            <div className={commonStyles.switchWrapper}>
                                <Switch
                                    checked={addUserSwitch}
                                    onChange={handleAddUserSwitchChange}
                                />
                                <span style={{ marginLeft: 20 }}>
                                    Add New User
                                </span>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('2000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save action swap">
                                <Button type="primary" htmlType="submit">
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): ServiceUserAddAssigneeStateInterface => {
    return {
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(SwitchUserForm)
