import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { type ArticleData } from '../../../Types/wikiArtical'
import {
    type ArticleGroupData,
    type ArticalGroupListFormInterface,
} from '../../../Types/wikiArticalGroup'
// import ReactQuill from 'react-quill'
import CustomQuill from '../../../Components/CustomQuill'
const ArticalGroupDetailsForm = (
    props: ArticalGroupListFormInterface
): React.ReactElement => {
    const { onSave, editData, isLoadingModal } = props
    const [form] = Form.useForm()
    const [emailText, setEmailText] = useState('')

    useEffect(() => {
        form.setFieldsValue(editData)
        setEmailText(editData.description)
    }, [editData])

    const resetForm = (): void => {
        form.resetFields()
        setEmailText('')
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }
    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    const datatodave = {
                        articleGroupId: editData.articleGroupId,
                        groupName: data.groupName,
                        articleName: data.articleName,
                        description: emailText,
                        icon: editData.icon,
                        iconType: editData.iconType,
                        isPublic: editData.isPublic,
                        isSharedInSocialMedia: editData.isSharedInSocialMedia,
                        isActive: editData.isActive,
                    }
                    onSave(datatodave, resetForm)
                }}
                requiredMark={customizeRequiredMark}
                form={form}
            >
                <Form.Item<ArticleData> name="articleId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ArticleGroupData>
                            label="Article Group"
                            name="groupName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Article Group Name!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Article Group " />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ArticleGroupData>
                            label="Description"
                            name="description"
                        >
                            <CustomQuill
                                onDescriptionChange={onDescriptinChange}
                                emailText={emailText}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save Article Group">
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={Boolean(isLoadingModal)}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ArticalGroupDetailsForm
