import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Form, Select, Button } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { useDispatch, connect } from 'react-redux'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { SearchOutlined } from '@ant-design/icons'
import SearchForm from '../BulksmsForm'
import { type BulkmailFormStateInterface } from '../../../Types/Bulksms'
import { GetContactDetail } from '../../../Services/Bulksms'

const BulkSms = (props: BulkmailFormStateInterface): React.ReactElement => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { categoryDropDownList } = props

    const [catergoryId, setcatergoryId] = useState<number | null>(0)
    const [modulld, setmoduleId] = useState<number[]>([])
    const [selectedStatus, setSelectedStatus] = useState<number>(1)
    const [isRedirecting, setIsRedirecting] = useState(false)
    const hasFetchedOnce1 = useRef(false)

    const [isModuleSelected, setIsModuleSelected] = useState(false)

    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [])

    useEffect(() => {
        if (catergoryId !== null && modulld.length > 0) {
            GetContactDetail(dispatch, catergoryId, modulld, selectedStatus)
        }
    }, [catergoryId, modulld, selectedStatus])

    const handleSearch = async (): Promise<void> => {
        try {
            // Validate the form fields
            await form.validateFields()

            // Check if both category and at least one module are selected
            if (catergoryId !== null && modulld.length > 0) {
                GetContactDetail(dispatch, catergoryId, modulld, selectedStatus)
                setIsRedirecting(true)
            } else {
                // Optionally show a message if validation fails
                // For example:
                // message.error("Please select both a category and at least one module.");
            }
        } catch (error) {
            // If validation fails, this block will run and the error will contain the details
            console.log('Validation failed:', error)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const handleSelectChange = (value: number): void => {
        setcatergoryId(value)
    }

    const handleSelectChange1 = (value: number[]): void => {
        setmoduleId(value)
        setIsModuleSelected(value.length > 0)
    }

    return (
        <>
            <div>
                <h2>Bulk SMS</h2>
            </div>
            <Card title="Bulk Sms">
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onFinish={handleSearch}
                >
                    <div>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Client Category"
                                    name="clientCategory"
                                >
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChange}
                                        showSearch
                                        allowClear
                                        defaultValue={0}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...categoryDropDownList,
                                        ]}
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Status" name="status">
                                    <Select
                                        placeholder="Select Status"
                                        value={selectedStatus}
                                        onChange={(value) => {
                                            setSelectedStatus(value)
                                        }}
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            typeof option?.children ===
                                                'string' &&
                                            (option.children as string)
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        defaultValue={1}
                                    >
                                        <Select.Option value={1}>
                                            PersonWise
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            ClientWise
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Module"
                                    name="module"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the Client Category!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a module"
                                        onChange={handleSelectChange1}
                                        value={modulld}
                                        mode="multiple"
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            typeof option?.children ===
                                                'string' &&
                                            (option.children as string)
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    >
                                        <Select.Option value={1}>
                                            Corporate
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            Sole Trader
                                        </Select.Option>
                                        <Select.Option value={3}>
                                            Individual
                                        </Select.Option>
                                        <Select.Option value={4}>
                                            Partnership
                                        </Select.Option>
                                        <Select.Option value={5}>
                                            UK-Staffs
                                        </Select.Option>
                                        <Select.Option value={6}>
                                            India Staffs
                                        </Select.Option>
                                        <Select.Option value={7}>
                                            VAT
                                        </Select.Option>
                                        <Select.Option value={8}>
                                            Payee
                                        </Select.Option>
                                        <Select.Option value={9}>
                                            Ad Hoc
                                        </Select.Option>
                                        <Select.Option value={10}>
                                            Other
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col offset={21} span={2}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={handleSearch}
                                    >
                                        <SearchOutlined />
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                        {isRedirecting && isModuleSelected && <SearchForm />}
                    </div>
                </Form>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): BulkmailFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        contactdepartmentDropDownList:
            state.initial.contactdepartmentDropDownList,
        contactdepartmentDropDownListCount:
            state.initial.contactdepartmentDropDownListCount,
    }
}

export default connect(mapStateToProps)(BulkSms)
