import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Card } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { SendOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { type ProspectClientFormDataInterface } from '../../../../Types/Client/ProspectClients/prospectClient'
import {
    GetInvidualProsPectDetails,
    SaveEmail,
} from '../../../../Services/ProspectClient'
import { useLocation, useNavigate } from 'react-router-dom'
import sendemail from './send_email.less'
import { ErrorMessage } from '../../../../Utils/Notification'
import S3UploadMultiple from '../../../../Components/S3UploadMultiple'
import ReactQuillComponet from '../../../../Components/ReactQillComponet'
const ProspectSendEmail = (): React.ReactElement => {
    const location = useLocation()
    const prospectClientId = location.state?.id
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [induvidualSmsDetails, setInduvidualSmsDetails] =
        useState<ProspectClientFormDataInterface | null>(null)
    const [emailText, setEmailText] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        const fetchData = (): void => {
            void GetInvidualProsPectDetails(
                dispatch,
                prospectClientId,
                (details: ProspectClientFormDataInterface) => {
                    setInduvidualSmsDetails(details)
                }
            )
        }
        fetchData()
    }, [prospectClientId])

    useEffect(() => {
        if (
            induvidualSmsDetails !== null &&
            typeof induvidualSmsDetails === 'object'
        ) {
            const newFieldValues = {
                emailAddress: induvidualSmsDetails?.emailAddress,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [induvidualSmsDetails, form])
    const [uploadFileList, setUploadFileList] = useState<string[]>([])
    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()
            const toAddress: string = formData.emailAddress
            const ccAddress: string = formData.emailCC
            const bccAddress: string = formData.emailBcc
            if (toAddress.includes('.com.')) {
                ErrorMessage('Plese Input Correct To Email')
            } else {
                const dataTosave = {
                    clientEmailId: 0,
                    templateId: 0,
                    serviceProcessId: 0,
                    companyTypeId: 6,
                    referenceId: prospectClientId,
                    serviceActionId: 0,
                    serviceActionStepId: 0,
                    fromAddress: 'info@iykons.com',
                    toAddress,
                    ccAddress,
                    bccAddress,
                    subject: formData.subject,
                    description: emailText,
                    emailStatus: 'Sucsss',
                    serviceId: 0,
                    contactName: '',
                    invoiceId: 0,
                }

                await SaveEmail(dispatch, dataTosave, uploadFileList)

                form.setFieldsValue({
                    description: '',
                    emailCC: '',
                    emailBcc: '',
                    subject: '',
                    emailAddress: '',
                })
                navigate('/clients/prospect-clients')
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleSetFormData = (name: string, locations: string[]): void => {
        form.setFieldValue(name, location)
        setUploadFileList(locations)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }

    return (
        <Card title={'Send Email'}>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    size="small"
                    requiredMark={customizeRequiredMark}
                >
                    <Form.Item<ProspectClientFormDataInterface>
                        name="prospectClientId"
                        hidden={true}
                        initialValue={prospectClientId}
                    >
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item<ProspectClientFormDataInterface>
                                label="Email To"
                                name="emailAddress"
                                rules={[
                                    // {
                                    //     pattern:
                                    //         /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}@[\w.-]+\.[a-zA-Z]{2,}$/,
                                    //     message: 'vaild Email is allowed',
                                    // },
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Email CC"
                                name="emailCC"
                                rules={[
                                    {
                                        // pattern:
                                        //     /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:\.[a-zA-Z]{2,})?$/,
                                        type: 'email',
                                        message:
                                            'Please enter a valid email address!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <span>
                                <i>
                                    Note:-This format only accepted
                                    (example@gmail.com,example1@gmail.com,example2@gmail.com)
                                </i>
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Email BCC "
                                name="emailBcc"
                                rules={
                                    [
                                        // {
                                        //     type: 'email',
                                        //     message: 'Please input Valid email!',
                                        // },
                                        // {
                                        //     pattern:
                                        //         /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        //     message:
                                        //         'Please enter a valid email address!',
                                        // },
                                        // {
                                        //     pattern:
                                        //         /^(?!.*\.\.)(?!.*\.$)(?!.*\.[a-zA-Z]{2,}\.[a-zA-Z]{2,}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/,
                                        //     message:
                                        //         'Please enter a valid email address!',
                                        // },
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                            <span>
                                <i>
                                    Note:-This format only accepted
                                    (example@gmail.com,example1@gmail.com,example2@gmail.com)
                                </i>
                            </span>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Subject',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Description" name="description">
                                <ReactQuillComponet
                                    value={emailText}
                                    onChange={(value: string) => {
                                        onDescriptinChange(value)
                                    }}
                                    className={sendemail.reactQuill}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Attachment" name="attachment">
                                <S3UploadMultiple
                                    name={'attachment'}
                                    value={uploadFileList}
                                    setFormData={handleSetFormData}
                                    corporate={''}
                                    corporateid={0}
                                    documenttype={''}
                                    documentbaseId={0}
                                    memberpersonId={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Button
                                type="primary"
                                onClick={onSave}
                                size="middle"
                            >
                                <SendOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Card>
    )
}

export default ProspectSendEmail
