import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import calculatePagination from '../../../Utils/Pagination'
import {
    ActiveInactiveClientDynCategory,
    DeleteClientDynCategory,
    GetParentCategory,
} from '../../../Services/CreateCatogry'
import {
    type CreateCategoryTableIntreface,
    type createCategoryParentStateInterface,
} from '../../../Types/CreateCategory'
import CreateCategory from './createCategory'

export const ClientDynItemDefaultData = {
    clientDynCategoryId: 0,
    clientDynCategoryName: '',
    clientDynCategoryParentId: 0,
    clientNoStartFrom: 0,
    isActive: '',
}

const CreateClientCatergoryList = (
    props: createCategoryParentStateInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { clientParentCategoryDropDown, clientParentCategoryDropDownCount } =
        props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [clinetDynamicategory, setDynamicClientCategory] = useState('')

    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Client Category'
    )
    const [restData, setResetData] = useState('false')
    const getTableData = useCallback(() => {
        const { pageNo, pageSize, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetParentCategory(dispatch, 0, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: clientParentCategoryDropDownCount,
            },
        })
    }, [clientParentCategoryDropDownCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        clientParentCategoryDropDownCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<CreateCategoryTableIntreface> = [
        {
            title: 'Category Name',
            dataIndex: 'clientDynCategoryName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: CreateCategoryTableIntreface) => {
                return (
                    <Space size="middle">
                        {record.clientDynCategoryParentId === 0 && (
                            <>
                                <Tooltip title="Edit Client Category Name">
                                    <EditIcon
                                        onClick={() => {
                                            openEditModal(
                                                record?.clientDynCategoryId.toString()
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={
                                        record.isActive === 'Active'
                                            ? 'Active'
                                            : 'Inactive'
                                    }
                                >
                                    <Switch
                                        checked={record.isActive === 'Active'}
                                        onClick={() => {
                                            activeInactiveModalFunction(
                                                record.clientDynCategoryId.toString(),
                                                record.isActive
                                            )
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <DeleteIcon
                                        onClick={() => {
                                            deleteModalFunction(
                                                record.clientDynCategoryId.toString()
                                            )
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<CreateCategoryTableIntreface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }
    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'clientDynCategoryName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let companyHolidayId = ''
        if (!deleteModalStatus) {
            companyHolidayId = id
        }
        setDynamicClientCategory(companyHolidayId)
    }

    const deleteHoliday = (): void => {
        DeleteClientDynCategory(dispatch, clinetDynamicategory, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        let tittle = 'Create Client Category'
        if (id !== null && id !== undefined && id !== '0') {
            tittle = 'Edit Client Category'
        }

        setEditModalTittle(tittle)
        changeCreateEditModalStatus()
        setDynamicClientCategory(id)
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
        getTableData()
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let companyHolidayId = ''
        if (!activeInactiveModalStatus) {
            companyHolidayId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setDynamicClientCategory(companyHolidayId)
    }

    const updateActiveInactiveHoliday = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveClientDynCategory(
            dispatch,
            clinetDynamicategory,
            status,
            getTableData
        )
        activeInactiveModalFunction('', '')
    }

    // const onSaveHoliday = (
    //     data: HolidayDetailsFormDataInterface,
    //     callBack: CallableFunction
    // ): void => {
    //     void saveHolidayData(data, callBack)
    // }
    // const saveHolidayData = async (
    //     data: HolidayDetailsFormDataInterface,
    //     callBack: CallableFunction
    // ): Promise<void> => {
    //     await SaveHoliday(dispatch, data)
    //         .then((result) => {
    //             if (result !== null && result !== undefined) {
    //                 changeCreateEditModalStatus()
    //                 getTableData()
    //                 callBack()
    //             }
    //         })
    //         .catch(() => {})
    // }
    // const filterList = clientParentCategoryDropDown?.filter(
    //     (item) => item?.clientDynCategoryParentId === 0
    // )
    // console.log('filterList', filterList)
    const updateList = clientParentCategoryDropDown?.map((item) => ({
        ...item,
        key: item?.clientDynCategoryId.toString(),
    }))
    return (
        <>
            <Card
                title="Client Category Name"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('0')
                            setResetData('true')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search client CategoryName by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={updateList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Client Category Name. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteHoliday}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Client Category Name. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveHoliday}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <CreateCategory
                        changeCreateEditModalStatus={
                            changeCreateEditModalStatus
                        }
                        clinetDynamicategory={clinetDynamicategory}
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): createCategoryParentStateInterface => {
    return {
        clientParentCategoryDropDown:
            state.initial.clientParentCategoryDropDown,
        clientParentCategoryDropDownCount:
            state.initial.clientParentCategoryDropDownCount,
    }
}

export default connect(mapStateToProps)(CreateClientCatergoryList)
