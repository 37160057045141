import React, { useEffect, useState } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Space,
    DatePicker,
    Switch,
    type DatePickerProps,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import { useDispatch } from 'react-redux'
import partnerless from './Partnershipserviceless.less'
import { type AnnualEditData } from '../../../../../../Types/Client/RegisteredClients/Partnership'
import moment from 'moment'
import dayjs from 'dayjs'
import { SaveAnnualData } from '../../../../../../Services/Parnership'

const { RangePicker } = DatePicker

const AnnualEditForm = (props: AnnualEditData): React.ReactElement => {
    const { editData, partnershipId, handleChildFormSave } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [period, setPeriod] = useState<string[]>([])
    const [servicesEnabled, setServicesEnabled] = useState(false)
    useEffect(() => {
        form.setFieldsValue(editData)
        if (editData !== null) {
            const deadlineDate = moment(
                editData.deadlineDate,
                'M/D/YYYY h:mm:ss A'
            )
            const formattedDate = deadlineDate.format('MM/DD/YYYY')
            setSelectedDate(formattedDate)
            setPeriod([editData.fromDate, editData.toDate])

            setServicesEnabled(editData.status === 'Active')
        }
    }, [editData])

    useEffect(() => {
        form.setFieldValue('deadlineDate', selectedDate)
        form.setFieldValue('period', period)
    }, [selectedDate, period])

    const onChange = (
        deadlineDate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (deadlineDate != null) {
            const isoDate = deadlineDate.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const onRangePickerChange = (
        dates: any,
        dateStrings: [string, string]
    ): void => {
        if (dates !== null) {
            const formattedDates = dates.map((date: moment.Moment) =>
                date.format('YYYY-MM-DD')
            )
            setPeriod(formattedDates)
        }
    }

    const handleServiceSwitchChange = (checked: boolean): void => {
        setServicesEnabled(checked)
    }

    const handleSave = (): void => {
        void handleSaveData()
    }
    const handleSaveData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formdata = form.getFieldsValue()

            const dataToSave = {
                annualAccountId: editData.annualAccountId,
                companyType: 4,
                referenceId: partnershipId,
                fromDate: moment(period[0]).format('DD/MM/YYYY'),
                toDate: moment(period[1]).format('DD/MM/YYYY'),
                deadlineDate: moment(formdata.deadlineDate).format(
                    'DD/MM/YYYY'
                ),
                status: servicesEnabled ? 'Active' : 'Inactive',
            }

            void SaveAnnualData(dispatch, dataToSave)
            handleChildFormSave()
        } catch (error) {}
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                form={form}
                name="ct-form"
                layout={'vertical'}
                autoComplete="off"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16} className={partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Period"
                            name="period"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Start Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <RangePicker
                                    format="MM/DD/YYYY"
                                    className={partnerless.dateWidth}
                                    onChange={onRangePickerChange}
                                    value={
                                        period != null
                                            ? [
                                                  dayjs(period[0]),
                                                  dayjs(period[1]),
                                              ]
                                            : null
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Deadline Date"
                            name="deadlineDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Start Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    className={partnerless.dateWidth}
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Enable/Disable Services"
                            name="status"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                onChange={handleServiceSwitchChange}
                                checked={servicesEnabled}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button type="primary" onClick={handleSave}>
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AnnualEditForm
