import React, { useEffect, useState } from 'react'
import {
    Card,
    Col,
    Row,
    Form,
    Select,
    Input,
    type SelectProps,
    Button,
    Tooltip,
} from 'antd'
import { SendOutlined, PlusOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import { connect, useDispatch } from 'react-redux'
import styles from '../../../Layouts/Less/style.less'
// import invoive from './clientform.less'
import axios from '../../../Config/AxiosConfig'
import { ErrorMessage } from '../../../Utils/Notification'
import {
    GetAmentMetDetails,
    GetEmailTemplate,
    GetFortoClienDetails,
} from '../../../Services/HelpDeskClientToform'
import {
    GetFormAppendFormList,
    GetFormToCientEmail,
} from '../../../Services/Common'
import {
    type ApendFormData,
    type clientoformListInterface,
    type AttachmentProperty,
} from '../../../Types/HelpDesk'
import FullWidthModal from '../../../Components/FullWidthModal'
import S3UploadMultiple from '../../../Components/S3UploadMultiple'
import { SaveFormToClient } from '../../../Services/EmailService'
import ReactQuillComponet from '../../../Components/ReactQillComponet'

const extractTextFromHtml = (htmlString: any): string => {
    const tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = htmlString
    return tempDivElement.innerHTML
}

const ClientView = (props: clientoformListInterface): React.ReactElement => {
    const { formtoCLientEmailList, appendFormList } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<SelectProps['options']>([])
    const [emailText, setEmailText] = useState('')
    const [tempId, setTempId] = useState(0)
    const [ClientId, setClientId] = useState('')
    const [formAmentModelStatus, setFormAmentModelStatus] = useState(false)
    const [tempName, setTempName] = useState('')
    const [formAmenment, setFormAmenment] = useState<number[]>([])
    const [amenTmentData, setAmentMentData] = useState<ApendFormData[]>()
    const [clientFormsJSX, setClientFormsJSX] = useState<string>('')
    const [change64, setChange64] = useState<string[] | undefined>(undefined)
    useEffect(() => {
        GetFormToCientEmail(dispatch)
    }, [])

    useEffect(() => {
        const GetEmailDeatils = async (): Promise<void> => {
            if (
                tempId !== 0 &&
                tempId !== undefined &&
                ClientId !== '' &&
                ClientId !== undefined
            ) {
                const parts = ClientId?.split('-')
                const businesType = parts[0]
                const referenceId = parts[1]
                const emaildata = await GetEmailTemplate(
                    dispatch,
                    businesType,
                    referenceId,
                    tempId
                )
                const description = extractTextFromHtml(emaildata?.emailContent)
                const task = convertHtmlToText(description)
                setEmailText(task)
                setTempName(emaildata?.templateName)
            }
        }
        void GetEmailDeatils()
    }, [tempId])

    useEffect(() => {
        const GetEmailDeatils = async (): Promise<void> => {
            if (ClientId !== '' && ClientId !== undefined) {
                const parts = ClientId?.split('-')
                const businesType = parts[0]
                const referenceId = parts[1]
                const emaildata = await GetFortoClienDetails(
                    dispatch,
                    businesType,
                    referenceId
                )
                form.setFieldValue('emailTo', emaildata?.emailAddress)
                form.setFieldValue(
                    'emailCC',
                    emaildata?.alternativeEmailAddress
                )
            }
        }
        void GetEmailDeatils()
    }, [ClientId])

    const convertHtmlToText = (html: string): string => {
        const doc = new DOMParser().parseFromString(html, 'text/html')
        return doc.body.textContent ?? ''
    }

    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/1/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <div>
                                    <div
                                        className={styles.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            Name-{client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <span
                                            className={
                                                styles.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Email-{client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        if (tempId !== 0 && tempId !== undefined) {
            GetFormAppendFormList(dispatch, tempId)
        }
    }, [tempId])

    const onChangeSearch = (value: string): void => {
        setClientId(value)
    }
    const handeleEmailTemChange = (value: number): void => {
        setTempId(value)
    }

    const handeleAppenFormChange = (value: number[]): void => {
        setFormAmenment(value)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const CloseModalFunction = (): void => {
        setFormAmentModelStatus(!formAmentModelStatus)
    }

    const FullwidthModelYesFunction = (): void => {
        const htmlContent = emailText.replace('FORM AMENDMENT', clientFormsJSX)
        setEmailText(htmlContent)
        setFormAmentModelStatus(!formAmentModelStatus)
    }

    const openModel = (): void => {
        if (formAmenment.length !== 0) {
            const convertedString = formAmenment.map(String).join(', ')
            void GetAmentMetDetails(dispatch, convertedString).then((data) => {
                if (data !== null) {
                    setAmentMentData(data?.data)
                }
            })
            setFormAmentModelStatus(!formAmentModelStatus)
        } else {
            ErrorMessage('Please Select Atleast One Form amenment')
        }
    }

    useEffect(() => {
        const generateContent = (): void => {
            let dataarr = '<ul>'

            amenTmentData?.forEach((client: ApendFormData) => {
                dataarr += `<li><a href="${client.pdfUrl}" target='_blank'>${client.formName}</a>`
                if (client.annexurePdfUrl !== null) {
                    dataarr += `</br><a href="${client.annexurePdfUrl}" target='_blank'>${client.formName} - II</a>`
                }
                if (client.annexure1PdfUrl !== null) {
                    dataarr += `</br><a href="${client.annexure1PdfUrl}" target='_blank'>${client.formName} - III</a>`
                }
                if (client.annexure2PdfUrl !== null) {
                    dataarr += `</br><a href="${client.annexure2PdfUrl}" target='_blank'>${client.formName} - IV</a>`
                }
                dataarr += `</li><br/>`
            })

            dataarr += '</ul>'
            setClientFormsJSX(dataarr)
        }

        generateContent()
    }, [amenTmentData])

    useEffect(() => {
        let name: any = ''
        if (data !== undefined) {
            const matchingLab = data.find((lab) => lab?.value === ClientId)
            if (matchingLab !== undefined) {
                name = matchingLab?.label
            }
        }
        const concat = `${tempName} - ${name}`
        form.setFieldValue('subject', concat)
    }, [data, ClientId, form, tempName])

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        form.setFieldValue(name, location)
        setUploadFileList(locations)
    }

    const setBase64 = (name: string[] | undefined): void => {
        setChange64(name)
    }
    const onSave = (): void => {
        void onSaveEmail()
    }

    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()
            const emailAttachmentItemList: AttachmentProperty[] = []

            uploadFileList.forEach((file, index) => {
                if (change64 !== undefined) {
                    const fileName = file
                    const fileContent = change64[index]

                    const emailAttachmentItem: AttachmentProperty = {
                        fileContent,
                        fileName,
                    }

                    emailAttachmentItemList.push(emailAttachmentItem)
                }
            })
            const parts = ClientId?.split('-')
            const businesType = parts[0]
            const referenceId = Number(parts[1])

            // const dataToSave = {
            //     fromEmail: 'info@iykons.com',
            //     fromName: 'Iykons Private Limited',
            //     toEmail: formData.emailTo,
            //     ccEmail: formData.emailCC,
            //     bccEmail: '',
            //     subject: formData.subject,
            //     body: emailText,
            //     emailAttachment: emailAttachmentItemList,

            const clientEmailData = {
                clientEmailId: 0,
                templateId: tempId,
                serviceProcessId: 0,
                companyTypeId: Number(businesType) ?? 0,
                referenceId,
                serviceActionId: 0,
                serviceActionStepId: 0,
                fromAddress: 'info@iykons.com',
                toAddress: formData.emailTo,
                ccAddress: formData.emailCC,
                bccAddress: '',
                subject: formData.subject,
                description: emailText,
                emailStatus: '',
                serviceId: 0,
                contactName: '',
                invoiceId: 0,
            }
            await SaveFormToClient(dispatch, clientEmailData, uploadFileList)

            form.resetFields()
            setChange64([])
            setUploadFileList([])
        } catch (error) {}
    }

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }

    return (
        <>
            <Card title="Forms to Clients">
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <div>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Client Name"
                                    name="clientCategory"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the Client Category!',
                                        },
                                    ]}
                                >
                                    <Select
                                        // style={{ width: '378px' }}
                                        loading={isLoading}
                                        showSearch
                                        placeholder="To filter the clients by number /name"
                                        onChange={(value) => {
                                            onChangeSearch(value)
                                        }}
                                        onSearch={(value) => {
                                            setSearchValue(value)
                                        }}
                                        filterOption={false}
                                        allowClear
                                        onClear={() => {
                                            setData([])
                                        }}
                                        optionLabelProp="label"
                                        options={data}
                                        optionRender={(option) => (
                                            <>{option.data.desc}</>
                                        )}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Email Template"
                                    name="emaltemplate"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select email template',
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Please select"
                                        onChange={handeleEmailTemChange}
                                        optionFilterProp="children"
                                        options={[
                                            {
                                                label: 'Please select',
                                                value: null,
                                            },
                                            ...formtoCLientEmailList?.map(
                                                (service) => ({
                                                    value: service.value,
                                                    label: service.label,
                                                })
                                            ),
                                        ]}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Email To"
                                    name="emailTo"
                                    rules={[
                                        {
                                            type: 'email',
                                            required: true,
                                            message:
                                                'Please input valid email!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Email CC"
                                    name="emailCC"
                                    rules={[
                                        {
                                            type: 'email',
                                            message:
                                                'Please input valid email!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <span>
                                    <i>
                                        Note:-This format only accepted
                                        (example@gmail.com,example1@gmail.com,example2@gmail.com)
                                    </i>
                                </span>
                            </Col>
                        </Row>
                        {ClientId !== '' && tempId !== 0 && (
                            <>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Forms"
                                            name="forms"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select the forms!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Select a  Module"
                                                mode="multiple"
                                                onChange={
                                                    handeleAppenFormChange
                                                }
                                                optionFilterProp="children"
                                                options={appendFormList}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '')
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={5}>
                                        <Button
                                            type="primary"
                                            onClick={openModel}
                                        >
                                            <PlusOutlined /> Append Forms
                                        </Button>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Subject"
                                            name="subject"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select subject!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="Description">
                                            <ReactQuillComponet
                                                value={emailText}
                                                onChange={(value: string) => {
                                                    onDescriptinChange(value)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Attachment"
                                            name="imageUpload"
                                        >
                                            <S3UploadMultiple
                                                name={'imageUpload'}
                                                value={uploadFileList}
                                                setFormData={handleSetFormData}
                                                setBase64={setBase64}
                                                corporate={'BulkMailAttachment'}
                                                corporateid={0}
                                                documenttype={''}
                                                memberpersonId={0}
                                                documentbaseId={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col offset={21} span={2}>
                                        <Form.Item>
                                            <Tooltip title="Send Email">
                                                <Button
                                                    type="primary"
                                                    onClick={onSave}
                                                >
                                                    <SendOutlined
                                                        style={{
                                                            transform:
                                                                'rotate(-45deg)',
                                                        }}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </Form>
                <FullWidthModal
                    modalStatus={formAmentModelStatus}
                    closeModal={CloseModalFunction}
                    tittle="You are about to append selected forms in this mail. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={FullwidthModelYesFunction}
                />
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): clientoformListInterface => {
    return {
        formtoCLientEmailList: state.common.formtoCLientEmailList,
        appendFormList: state.common.appendFormList,
    }
}

export default connect(mapStateToProps)(ClientView)
