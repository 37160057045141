import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    SaveBankDetails,
    DeleteBankDetails,
    GetBankingDetailsList,
} from '../../../Services/BankingDetials'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import BankingDetailsForm from './bankingDetailsForm'
import type {
    BankingDetailsListInterface,
    BankingDetailsListDataTypeInterface,
    BankDetailsFormDataInterface,
} from '../../../Types/BankDetails'
import BankDetailsExpandView from './bankDetailsExpandView'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const BankDetailsItemDefaultData = {
    accountName: '',
    accountNo: '',
    branch: '',
    description: '',
    iban: '',
    paymentOptionId: '0',
    paymentOptionTitle: '',
    swiftCode: '',
}

const BankingDetailsList = (
    props: BankingDetailsListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { bankingDetailsList, bankingDetailsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [bankDetailsId, setBankDetailsId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Banking Details'
    )
    const [editData, setEditData] = useState<BankDetailsFormDataInterface>(
        BankDetailsItemDefaultData
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const hasFetchedOnce = useRef(false)
    const [isLoadingModal, setIsLoadingModal] = useState(false)
    const [restData, setResetData] = useState('false')
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetBankingDetailsList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }

        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: bankingDetailsListCount,
            },
        })
    }, [bankingDetailsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const handlePageSizeChange = (pageSize: number): void => {
        setItemsPerPage(pageSize)
        setCurrentPage(1)
    }
    const pagination = calculatePagination(
        currentPage,
        bankingDetailsListCount,
        itemsPerPage,
        handlePageSizeChange
    )

    const columns: ColumnsType<BankingDetailsListDataTypeInterface> = [
        {
            title: 'Title',
            dataIndex: 'paymentOptionTitle',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Account Name',
            dataIndex: 'accountName',
        },
        {
            title: 'Account Number',
            dataIndex: 'accountNo',
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
        },
        {
            title: 'Sort Code',
            dataIndex: 'swiftCode',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: BankingDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1001', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Banking Detail">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.paymentOptionId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        {ActivePremission('1001', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.paymentOptionId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<BankingDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'paymentOptionTitle',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let bankDetailsId = ''
        if (!deleteModalStatus) {
            bankDetailsId = id
        }
        setBankDetailsId(bankDetailsId)
    }

    const deleteBankDetails = (): void => {
        DeleteBankDetails(dispatch, bankDetailsId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = bankingDetailsList.find(
            (data: BankDetailsFormDataInterface) => {
                return data.paymentOptionId === id
            }
        )

        let tittle = 'Create Banking Details'
        if (id !== null && id !== undefined && id !== '') {
            tittle = 'Edit Banking Details'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? BankDetailsItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveBankDetails = (
        data: BankDetailsFormDataInterface,
        callBack: CallableFunction
    ): void => {
        const saveData = {
            paymentOptionId: data.paymentOptionId ?? 0,
            paymentOptionTitle: data.paymentOptionTitle,
            accountName: data.accountName,
            accountNo: data.accountNo,
            branch: data.branch,
            swiftCode: data.swiftCode ?? '',
            description: data.description ?? '',
            iban: data.iban ?? '',
        }
        void saveBanDetailsData(saveData, callBack)
    }

    const saveBanDetailsData = async (
        data: BankDetailsFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        setIsLoadingModal(true)
        await SaveBankDetails(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                    setIsLoadingModal(false)
                }
            })
            .catch(() => {})
    }

    const expandableData = (
        record: BankingDetailsListDataTypeInterface
    ): React.ReactElement => {
        return <BankDetailsExpandView bankDetailsData={record} />
    }

    return (
        <>
            <Card
                title="Banking Details"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                            setResetData('true')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Banking Details by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={bankingDetailsList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Banking Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteBankDetails}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <BankingDetailsForm
                        onSave={onSaveBankDetails}
                        editData={editData}
                        isLoadingModal={isLoadingModal}
                        resetdata={restData}
                        setResetData={setResetData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): BankingDetailsListInterface => {
    return {
        bankingDetailsList: state.initial.bankingDetailsList,
        bankingDetailsListCount: state.initial.bankingDetailsListCount,
    }
}

export default connect(mapStateToProps)(BankingDetailsList)
