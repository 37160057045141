import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    type PartneshipSupplierDetailsFormData,
    type PartneshipSupplierForm,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import { Button, Card, Col, Collapse, Form, Input, Row, Switch } from 'antd'
import supplerinf from './supplerinfo.less'
import { SaveOutlined } from '@ant-design/icons'
import { SaveSupperInfo } from '../../../../../../Services/Parnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import { SuccessMessage } from '../../../../../../Utils/Notification'

type ShowDetailsState = Record<number, boolean>

const SupplierInfo = (props: PartneshipSupplierForm): React.ReactElement => {
    const { partnershipTaps, partnershipId } = props
    const [form] = Form.useForm()
    const [showDetails, setShowDetails] = useState<ShowDetailsState>({})
    const dispatch = useDispatch()

    useEffect(() => {
        if (Array.isArray(partnershipTaps)) {
            const initialValues = partnershipTaps.reduce((values, supplier) => {
                const initialValues = {
                    [`userName_${supplier.supplierId}`]: supplier.userName,
                    [`password_${supplier.supplierId}`]: supplier.password,
                    [`consumerNo_${supplier.supplierId}`]: supplier.consumerNo,
                    [`url_${supplier.supplierId}`]: supplier.url,
                }
                return {
                    ...values,
                    ...initialValues,
                }
            }, {})

            form.setFieldsValue(initialValues)

            // Update showDetails after setting form values
            const updatedShowDetails = partnershipTaps.reduce(
                (details, supplier) => ({
                    ...details,
                    [supplier.supplierId]: supplier.status === 'active',
                }),
                {}
            )
            setShowDetails(updatedShowDetails)
        }
    }, [partnershipTaps])

    useEffect(() => {
        // After showDetails is updated, ensure switches reflect the correct state
        form.setFieldsValue(showDetails)
    }, [showDetails])

    const handleSwitchChange = (supplierId: number) => (checked: boolean) => {
        setShowDetails({ ...showDetails, [supplierId]: checked })
    }

    const onFinsh = (): void => {
        for (const supplier of partnershipTaps) {
            const dataToSave = {
                status: showDetails[supplier.supplierId]
                    ? 'active'
                    : 'inactive',
                supplierId: supplier.supplierId,
                clientCompanySupplierId: supplier.clientCompanySupplierId,
                companyType: 4,
                referenceId: partnershipId,
                userName:
                    form.getFieldValue(`userName_${supplier.supplierId}`) ?? '',
                password:
                    form.getFieldValue(`password_${supplier.supplierId}`) ?? '',
                url: form.getFieldValue(`url_${supplier.supplierId}`) ?? '',
                consumerNo:
                    form.getFieldValue(`consumerNo_${supplier.supplierId}`) ??
                    '',
            }
            void SaveSupperInfo(dispatch, dataToSave)
        }
        SuccessMessage('Suplier Information Update Successfully')
    }
    return (
        <Card title="Supplier Information">
            <Collapse defaultActiveKey={['']}>
                <Collapse.Panel header={'Supplier Information'} key={1}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                    >
                        {Array.isArray(partnershipTaps) &&
                            partnershipTaps?.map(
                                (
                                    supplier: PartneshipSupplierDetailsFormData
                                ) => (
                                    <>
                                        <Form.Item key={supplier.supplierId}>
                                            <Switch
                                                onChange={handleSwitchChange(
                                                    supplier.supplierId
                                                )}
                                                checked={
                                                    showDetails[
                                                        supplier.supplierId
                                                    ]
                                                }
                                            />
                                            <label className={supplerinf.lable}>
                                                {supplier.supplierName}
                                            </label>
                                        </Form.Item>

                                        {Boolean(
                                            showDetails[supplier.supplierId]
                                        ) && (
                                            <div
                                                className={
                                                    supplerinf.suplerdatabox
                                                }
                                            >
                                                <Row gutter={[24, 8]}>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name={`userName_${supplier.supplierId}`}
                                                            label="Username"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name={`password_${supplier.supplierId}`}
                                                            label="Password"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name={`consumerNo_${supplier.supplierId}`}
                                                            label="Customer #"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            name={`url_${supplier.supplierId}`}
                                                            label="URL"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </>
                                )
                            )}
                        {ActivePremission('3000', PermissionType.SAVE) && (
                            <Row gutter={16}>
                                <Col offset={21} span={2}>
                                    <Button type="primary" onClick={onFinsh}>
                                        <SaveOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </Collapse.Panel>
            </Collapse>
        </Card>
    )
}
const mapStateToProps = (state: any): any => {
    return {
        partnershipTaps: state.client.partnershipTaps,
    }
}

export default connect(mapStateToProps)(SupplierInfo)
