/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Form, Button, Select, Card, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetRegisteClientDetails } from '../../../Services/Common'
import { GetServiceDropDownList } from '../../../Services/Corporate'

import {
    type OnboardingReportsData,
    type onboardingReportInterface,
} from '../../../Types/Reports'
import { GetonboardingstatusReportData } from '../../../Services/Reports'
import jsPDF from 'jspdf'
import hederimage from '../../../Assest/Svg/pattern-2.svg'

const OnboardingReport = (
    props: onboardingReportInterface
): React.ReactElement => {
    const { categoryDropDownList, regiterClientDetails } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [noRecordsFound, setNoRecordsFound] = useState(false)
    const [selectedSatusId, setSelectedStatusId] = useState(1)
    const [selectedCompanyGroupId, setSelectedCompanyGroupsId] = useState(0)
    const [selectedBusinessId, setSelectedBusinessId] = useState(0)
    const [loading, setLoading] = useState(false)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetRegisteClientDetails(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const handleSelectChangeStatus = (value: number): void => {
        setSelectedStatusId(value)
    }

    const handleSelectChangeCompanyGroup = (value: number): void => {
        setSelectedCompanyGroupsId(value)
    }

    const handleSelectChangeBusinesType = (value: number): void => {
        setSelectedBusinessId(value)
    }

    const getCompanyGroupLabel = (id: number): string => {
        if (id === 0) return 'All'
        const group = categoryDropDownList.find((item) => item.value === id)
        return group != null ? group.label : id.toString()
    }

    const fetchData = async (): Promise<void> => {
        try {
            const reportData: OnboardingReportsData =
                await GetonboardingstatusReportData(
                    dispatch,
                    selectedCompanyGroupId,
                    selectedBusinessId,
                    selectedSatusId
                )
            if (
                reportData?.onboardingClientStatus !== null &&
                reportData?.onboardingClientStatus !== undefined &&
                reportData?.onboardingClientStatus.length > 0
            ) {
                generatePDF(reportData)
                setNoRecordsFound(false)
            } else {
                setNoRecordsFound(true)
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const generatePDF = (data: any): void => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()
        const missingCompanyCategoryLabel = getCompanyGroupLabel(
            selectedCompanyGroupId
        )
        const headerColor: [number, number, number] = [0, 51, 153]
        doc.setTextColor(headerColor[0], headerColor[1], headerColor[2])
        const headerFontSize = 18
        const subHeaderFontSize = 20
        const addressFontSize = 10
        const categoryFontSize = 10
        const img = new Image()
        img.src = hederimage

        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const dataUrl = canvas.toDataURL('image/png')
            const logo = data?.businessDocumentTemplate?.companyLogo
            const marginTop = 5
            const marginLeftRight = 10
            const imageWidth =
                doc.internal.pageSize.getWidth() - 2 * marginLeftRight

            doc.addImage(
                dataUrl,
                'PNG',
                marginLeftRight,
                marginTop,
                imageWidth,
                5
            )

            const logoWidth = 50
            const logoHeight = 10
            const logoXPosition =
                doc.internal.pageSize.getWidth() - logoWidth - 10
            const logoYPosition = marginTop + 10

            if (logo) {
                doc.addImage(
                    logo,
                    'PNG',
                    logoXPosition,
                    logoYPosition,
                    logoWidth,
                    logoHeight
                )
            }

            const textXPosition = 14
            const textYPosition = logoYPosition + marginTop

            doc.setFontSize(headerFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text(missingCompanyCategoryLabel, textXPosition, textYPosition)

            const addressYPosition = textYPosition + 5
            doc.setFontSize(addressFontSize)
            doc.setTextColor(160, 160, 160)
            doc.text(
                '14 Elm Road, Chessington, KT9 1AW, UNITED KINGDOM',
                textXPosition,
                addressYPosition
            )

            const subHeaderYPosition = addressYPosition + 10
            doc.setFontSize(subHeaderFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text('Client Status Report', textXPosition, subHeaderYPosition)

            const lineStartX =
                textXPosition + doc.getTextWidth('Client Status Report') + 5
            const lineEndX = lineStartX + 80
            const lineY = subHeaderYPosition
            doc.setDrawColor(0, 51, 153)
            doc.setLineWidth(1)
            doc.line(lineStartX, lineY, lineEndX, lineY)

            doc.setFontSize(categoryFontSize)
            doc.setTextColor(0, 0, 0)
            const headers = [
                [
                    { content: 'Client Number', styles: { halign: 'center' } },
                    { content: 'Company Name', styles: { halign: 'center' } },
                    { content: 'Contact Name', styles: { halign: 'center' } },
                    { content: 'Contact Info', styles: { halign: 'center' } },
                    { content: 'Services', styles: { halign: 'center' } },
                ],
            ]

            const tabledata =
                data?.onboardingClientStatus?.map(
                    (item1: {
                        clientNumber: any
                        clientName: any
                        contactName: any
                        contactNumber: any
                        emailAddress: any
                        alternativeEmailAddress: any
                        serviceList: any
                    }) => [
                        item1.clientNumber,
                        item1.clientName,
                        item1.contactName,
                        `${item1.contactNumber}\n${item1.emailAddress}\n${item1.alternativeEmailAddress}`,
                        item1.serviceList,
                    ]
                ) || []

            const columnWidths = [15, 55, 38, 50, 30]

            doc.autoTable({
                head: headers,
                body: tabledata,
                startY: subHeaderYPosition + 15,
                theme: 'grid',
                tableWidth: 'auto',
                columnStyles: {
                    0: { cellWidth: columnWidths[0] },
                    1: { cellWidth: columnWidths[1] },
                    2: { cellWidth: columnWidths[2] },
                    3: { cellWidth: columnWidths[3] },
                    4: { cellWidth: columnWidths[4] },
                },
                styles: {
                    cellPadding: 2,
                    fontSize: 8,
                    halign: 'center',
                    valign: 'middle',
                    overflow: 'linebreak',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineColor: [160, 160, 160],
                    lineWidth: 0.05,
                },
            })

            const today = new Date()
            const formattedDate = `${
                today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}`
            const totalPages = doc.getNumberOfPages()

            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)
                doc.setFontSize(10)

                const footerText = `Printed on: ${formattedDate} ${i}/${totalPages} Total Count: ${tabledata?.length}`
                const textWidth = doc.getTextWidth(footerText)
                const pageWidth = doc.internal.pageSize.getWidth()
                const xPosition = (pageWidth - textWidth) / 2
                const yPosition = doc.internal.pageSize.getHeight() - 10

                doc.text(footerText, xPosition, yPosition)
            }

            doc.save('Onboarding_status_report.pdf')
        }
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const StatustDropdowlist = [
        {
            value: 1,
            label: 'Active',
        },
        {
            value: 2,
            label: 'In-active',
        },
    ]
    return (
        <>
            <Card title="Onboarding Reports" style={{ marginTop: '20px' }}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Company Group">
                                    <Select
                                        placeholder="Please select"
                                        onChange={
                                            handleSelectChangeCompanyGroup
                                        }
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        value={selectedCompanyGroupId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...categoryDropDownList,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Business Type">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeBusinesType}
                                        value={selectedBusinessId}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...regiterClientDetails,
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Status">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeStatus}
                                        value={selectedSatusId}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={StatustDropdowlist}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={21} span={2}>
                                <Tooltip title="Download Report">
                                    <Button
                                        type="primary"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={fetchData}
                                        loading={loading}
                                    >
                                        <CloudDownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {noRecordsFound && (
                    <div style={{ textAlign: 'left', marginTop: '20px' }}>
                        <span
                            style={{
                                color: '#808080',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            No Records found
                        </span>
                    </div>
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): onboardingReportInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
        regiterClientDetails: state.common.regiterClientDetails,
        onboardingstausList: state.reports.onboardingstausList,
    }
}

export default connect(mapStateToProps)(OnboardingReport)
