import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button, Select } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type AssigneeUserStateInterface,
    type ClientServiceUserSwitchUserInterface,
} from '../../../../Types/ServiceSetup/ClientServiceUser'
import { GetClientServiceUserList } from '../../../../Services/ServiceSetup'
import { GetServiceDropDownListBusinessType } from '../../../../Services/Corporate'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'

const AssigneeUser = (
    props: ClientServiceUserSwitchUserInterface
): React.ReactElement => {
    const {
        clientServiceDropDownList,
        ClientserviceUserList,
        flegId,
        businessTypeId,
        referenceId,
        onSave,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [serviceId, setServiceId] = useState(0)

    useEffect(() => {
        GetServiceDropDownListBusinessType(
            dispatch,
            businessTypeId,
            referenceId
        )
    }, [referenceId, businessTypeId])

    useEffect(() => {
        GetClientServiceUserList(dispatch, flegId, serviceId)
    }, [serviceId])

    const resetForm = (): void => {
        form.resetFields()
    }
    const handeleserviceChange = (value: number): void => {
        setServiceId(value)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                requiredMark={customizeRequiredMark}
                onFinish={(data) => {
                    const newObj = {
                        serviceId: data.serviceId,
                        businessTypeId,
                        referenceId,
                        assigneeUserId: flegId === 1 ? data.userId : 0,
                        workingUserId: flegId === 2 ? data.userId : 0,
                        portfolioManagerId: 0,
                        accountManagerId: 0,
                    }
                    onSave(newObj, resetForm)
                }}
                form={form}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Service"
                            name="serviceId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Please select"
                                allowClear
                                options={clientServiceDropDownList}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                onChange={(value) => {
                                    handeleserviceChange(value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {serviceId !== 0 && (
                    <>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label={
                                        flegId === 1
                                            ? 'Assignee User'
                                            : 'Working User'
                                    }
                                    name="userId"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the department!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Please select"
                                        options={[
                                            {
                                                label: 'Please select',
                                                value: null,
                                            },
                                            ...ClientserviceUserList,
                                        ]}
                                        allowClear
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {ActivePremission('2001', PermissionType.SAVE) && (
                            <Row gutter={16}>
                                <Col offset={21} span={2}>
                                    <Button type="primary" htmlType="submit">
                                        <SaveOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): AssigneeUserStateInterface => {
    return {
        ClientserviceUserList: state.serviceSetup.ClientserviceUserList,
        clientServiceDropDownList: state.initial.clientServiceDropDownList,
    }
}

export default connect(mapStateToProps)(AssigneeUser)
