import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Input, Form, Button, Select, Switch, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { WorkflowDetailsItemDefaultData } from './WorkflowDetailsList'
import { GetDepartmentDropDownList } from '../../../Services/Department'
import type {
    WorkflowDetailsFormInterface,
    WorkflowDetailsFormDataInterface,
    WorkflowDetailsFormStateInterface,
} from '../../../Types/WorkflowDetails'
import { GetWorkflowTypeList } from '../../../Services/Workflow'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import { pad2 } from '../../../Utils/Common'
import { GetApproveByList } from '../../../Services/WorkflowDetails'
import { GetUserList } from '../../../Services/User'
import { useLocation } from 'react-router-dom'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const WorkflowDetailsForm = (
    props: WorkflowDetailsFormInterface
): React.ReactElement => {
    const {
        onSave,
        editData,
        workflowDetail,
        workflowDetailsList,
        accessLevelList,
        categoryDropDownList,
        departmentDropDownList,
        approveByDropDownList,
        userList,
    } = props
    const [clientCategoryId, setClientCategoryId] = useState(0)
    const [departmentId, setDepartmentId] = useState(0)
    const [popUp, setPopUp] = useState(false)
    const [accessLevelId, setAccessLevelId] = useState(0)
    const [canSkipData, setCanSkipData] = useState(false)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const location = useLocation()
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
        GetWorkflowTypeList(dispatch)
    }, [])

    useEffect(() => {
        const levelTypeData = accessLevelList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.levelTypeName
        )
        const clientCategoryData = categoryDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.clientCategoryName
        )

        const newData = {
            ...editData,
            canSkip:
                editData?.canSkip !== undefined &&
                editData?.canSkip !== null &&
                editData?.canSkip !== 0
                    ? 1
                    : 0,
            alternativeBy: editData?.alternativeBy ?? '',
            departmentId: editData?.departmentId ?? '0',
            levelType: levelTypeData?.value ?? '',
            clientCategoryId: clientCategoryData?.value ?? '',
            levelReference: editData?.levelReference ?? '',
        }
        form.setFieldsValue(newData)
        setCanSkipData(
            editData?.canSkip !== undefined &&
                editData?.canSkip !== null &&
                editData?.canSkip !== 0
        )
        onChangeAccessLevel(levelTypeData?.value ?? 0)
        setClientCategoryId(
            clientCategoryData?.value !== 0 &&
                clientCategoryData?.value !== undefined &&
                !isNaN(clientCategoryData?.value)
                ? clientCategoryData?.value
                : 0
        )
        const departmentId = editData?.departmentId

        const parsedDepartmentId =
            departmentId !== '' ? parseInt(departmentId) : 0
        setDepartmentId(parsedDepartmentId)

        const count = workflowDetailsList?.length + 1
        const level =
            editData?.workflowLevel !== ''
                ? editData?.workflowLevel
                : 'Level ' + String(pad2(count))
        form.setFieldValue('workflowLevel', level)

        const sequenceNo =
            editData.sequenceNo !== '' ? editData.sequenceNo : count
        form.setFieldValue('sequenceNo', sequenceNo)

        const queryParams = new URLSearchParams(location.search)
        const workflowId = queryParams.get('id') ?? ''
        form.setFieldValue('workflowId', workflowId)
    }, [editData])

    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        if (clientCategoryId !== 0) {
            GetDepartmentDropDownList(dispatch, clientCategoryId)
        }
    }, [clientCategoryId])

    useEffect(() => {
        if (accessLevelId !== 0 && departmentId !== 0) {
            GetApproveByList(dispatch, accessLevelId, departmentId)
        }
    }, [departmentId])

    const resetForm = (): void => {
        form.setFieldsValue(WorkflowDetailsItemDefaultData)
    }

    const onChangeAccessLevel = (value: number): void => {
        const data: ListFrontEndInterface | undefined = accessLevelList?.find(
            (data: ListFrontEndInterface) => {
                return data.value === value
            }
        )
        let popUp = false
        if (data?.label !== 'Reporting Person' && data !== undefined) {
            popUp = true
        }
        setAccessLevelId(data?.value ?? 0)
        setPopUp(popUp)
    }

    const onChangeClientCategory = (value: number): void => {
        setClientCategoryId(value)
    }

    const onChangeApprovelDepartment = (value: number): void => {
        setDepartmentId(value)
    }

    const onChangeCanSkip = (value: boolean): void => {
        setCanSkipData(value)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<WorkflowDetailsFormDataInterface>
                    name="workflowId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item<WorkflowDetailsFormDataInterface>
                    name="sequenceNo"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item<WorkflowDetailsFormDataInterface>
                    name="workflowLevelId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <label> Workflow Client Category </label>
                        <Input
                            disabled={true}
                            className={commonStyles.viewInput}
                            value={workflowDetail.clientCategoryName}
                        />
                    </Col>
                    <Col span={12}>
                        <label> Workflow Department </label>
                        <Input
                            disabled={true}
                            className={commonStyles.viewInput}
                            value={workflowDetail.departmentName}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <label> Workflow For </label>
                        <Input
                            disabled={true}
                            className={commonStyles.viewInput}
                            value={workflowDetail.workflowForName}
                        />
                    </Col>
                    <Col span={12}>
                        <label> Workflow Name </label>
                        <Input
                            disabled={true}
                            className={commonStyles.viewInput}
                            value={workflowDetail.workflowName}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<WorkflowDetailsFormDataInterface>
                            label="Level"
                            name="workflowLevel"
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<WorkflowDetailsFormDataInterface>
                            label="Access Level"
                            name="levelType"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the access level!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={accessLevelList}
                                onChange={onChangeAccessLevel}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {popUp && (
                    <>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item<WorkflowDetailsFormDataInterface>
                                    label="Client Category"
                                    name="clientCategoryId"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the client category!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Please select"
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={categoryDropDownList}
                                        onChange={onChangeClientCategory}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item<WorkflowDetailsFormDataInterface>
                                    label="Approval Department"
                                    name="departmentId"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the department!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Please select"
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={departmentDropDownList}
                                        onChange={onChangeApprovelDepartment}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item<WorkflowDetailsFormDataInterface>
                                    label="Approve By"
                                    name="levelReference"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the approve by!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Please select"
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={approveByDropDownList}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item<WorkflowDetailsFormDataInterface>
                                    label="Alternative Person"
                                    name="alternativeBy"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select the alternative person!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Please select"
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={userList}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                <Row gutter={16}>
                    {!popUp && (
                        <Col span={12}>
                            <Form.Item<WorkflowDetailsFormDataInterface>
                                label="Alternative Person"
                                name="alternativeBy"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select the alternative person!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Please select"
                                    filterOption={(input, option) =>
                                        !(option == null) &&
                                        option.label
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={userList}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item<WorkflowDetailsFormDataInterface>
                            label="Can Skip"
                            name="canSkip"
                        >
                            <Switch
                                checked={canSkipData}
                                onChange={onChangeCanSkip}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1007', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save">
                                <Button type="primary" htmlType="submit">
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): WorkflowDetailsFormStateInterface => {
    return {
        workflowDetail: state.initial.workflowDetail,
        workflowDetailsList: state.initial.workflowDetailsList,
        accessLevelList: state.common.accessLevelList,
        categoryDropDownList: state.initial.categoryDropDownList,
        departmentDropDownList: state.initial.departmentDropDownList,
        approveByDropDownList: state.initial.approveByDropDownList,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(WorkflowDetailsForm)
