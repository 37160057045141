import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Switch,
    Row,
    Col,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
    Tooltip,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import type {
    TogilleAddress,
    CtStateInterface,
    vatFormInterface,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import dayjs from 'dayjs'
import { connect, useDispatch } from 'react-redux'
import { GetCountryList } from '../../../../../../Services/Common'
import {
    GetAddress,
    SaveVATServices,
} from '../../../../../../Services/Corporate'
import moment from 'moment'
import Servicesless from '../../../../../../Layouts/Less/style.less'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const { Option } = Select
const VATForm = (props: vatFormInterface): React.ReactElement => {
    const { countryList, corporateid, vateditdata, handleChildFormSave } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [addressDatas] = useState<TogilleAddress | null>(null)
    const [VatRegistrationDate, setSelectedDate] = useState<string>('')
    const [EffectiveDate, setSelectedEffectiveDate] = useState<string>('')
    const [EndingDate, setSelectedEndingDate] = useState<string>('')

    useEffect(() => {
        GetCountryList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if ((vateditdata ?? null) !== null && countryList !== null) {
            const countryData = countryList?.find(
                (data) => data.label === vateditdata?.country
            )

            const newData = {
                ...vateditdata,
                country: countryData != null ? countryData.value : '',
            }

            form.setFieldsValue(newData)
        }
    }, [vateditdata, countryList])

    useEffect(() => {
        if (vateditdata != null) {
            form.setFieldsValue({
                vatNumber: vateditdata?.vatNumber,
                vatScheme: vateditdata?.vatScheme,
                vatRegistrationDate: vateditdata?.vatRegistrationDate,
                effectiveDate: vateditdata?.effectiveDate,
                endingDate: vateditdata?.endingDate,
                addressLineOne: vateditdata?.addressLineOne,
                addressLineTwo: vateditdata?.addressLineTwo,
                town: vateditdata?.town,
                country: vateditdata?.country,
                postCode: vateditdata?.postCode,
                dataInput: vateditdata?.dataInput,
                status: vateditdata?.status,
                hmrcGatewayId: vateditdata?.hmrcGatewayId,
                hmrcPassword: vateditdata?.hmrcPassword,
                vatFrequency: vateditdata?.vatFrequency,
                vatFrequencyName: vateditdata?.vatFrequencyName,
            })
        }
    }, [vateditdata, form])

    useEffect(() => {
        if (vateditdata !== null) {
            const vatRegistrationDate = moment(
                vateditdata.vatRegistrationDate,
                'M/D/YYYY h:mm:ss A'
            )

            const formattedDate = vatRegistrationDate.format('MM/DD/YYYY')
            setSelectedDate(formattedDate)

            const effectiveDate = moment(
                vateditdata.effectiveDate,
                'M/D/YYYY h:mm:ss A'
            )

            const formattedDate1 = effectiveDate.format('MM/DD/YYYY')
            setSelectedEffectiveDate(formattedDate1)

            const endingDate = moment(
                vateditdata.endingDate,
                'M/D/YYYY h:mm:ss A'
            )

            const formattedDate2 = endingDate.format('MM/DD/YYYY')
            setSelectedEndingDate(formattedDate2)
        }
    }, [vateditdata])

    useEffect(() => {
        form.setFieldValue('vatRegistrationDate', VatRegistrationDate)
        form.setFieldValue('effectiveDate', EffectiveDate)
        form.setFieldValue('endingDate', EndingDate)
    }, [VatRegistrationDate, EffectiveDate, EndingDate])

    const onChange = (
        vatRegistrationDate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (vatRegistrationDate != null) {
            const isoDate = vatRegistrationDate.toISOString()

            setSelectedDate(isoDate)
        }
    }

    const onChange1 = (
        EffectiveDate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (EffectiveDate != null) {
            const isoDate1 = EffectiveDate.toISOString()
            setSelectedEffectiveDate(isoDate1)
        }
    }
    const onChange2 = (
        endingDate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (endingDate != null) {
            const isoDate2 = endingDate.toISOString()
            setSelectedEndingDate(isoDate2)
        }
    }

    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })
    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }

        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetAddress(
                dispatch,
                corporateid,
                switchId,
                (addressData: TogilleAddress) => {
                    form.setFieldsValue({
                        addressLineOne: addressData.addressLineOne ?? '',
                        addressLineTwo: addressData.addressLineTwo ?? '',
                        town: addressData.town ?? '',
                        postCode: addressData.postCode ?? '',
                    })
                    const countryData = countryList?.find(
                        (data) => data.label === addressData?.country
                    )
                    form.setFieldValue('country', countryData?.label)
                }
            )
        }
        setSwitchStates(newSwitchStates)
    }

    useEffect(() => {
        if (addressDatas != null && addressDatas.addressLineOne?.length > 0) {
            const newFieldValues = {
                addressLineOne: addressDatas.addressLineOne,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [addressDatas])

    const handleClick = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formdata = form.getFieldsValue()
            const vatFrequencyName = formdata.vatFrequencyName
            const countryName = countryList?.find(
                (c) => c.value === formdata.country
            )
            let vatFrequency: number

            if (vatFrequencyName === 'QUATARY') {
                vatFrequency = 1
            } else {
                vatFrequency = 2
            }
            const dataToSave = {
                vatId: vateditdata.vatId,
                companyType: 1,
                referenceId: corporateid,
                vatNumber: formdata.vatNumber,
                vatScheme: formdata.vatScheme,
                vatRegistrationDate: moment(
                    formdata.vatRegistrationDate
                ).format('DD/MM/YYYY'),
                addressLineOne: formdata.addressLineOne,
                addressLineTwo: formdata.addressLineTwo,
                town: formdata.town,
                country: countryName?.label ?? '',
                postCode: formdata.postCode,
                effectiveDate: moment(formdata.effectiveDate).format(
                    'DD/MM/YYYY'
                ),
                endingDate: moment(formdata.endingDate).format('DD/MM/YYYY'),
                dataInput: formdata.dataInput,
                status: servicesEnabled ? 'Active' : 'Inactive',
                hmrcGatewayId: formdata.hmrcGatewayId,
                hmrcPassword: formdata.hmrcPassword,
                vatFrequency: vatFrequency ?? 0,
                vatFrequencyName: formdata.vatFrequencyName,
            }

            void SaveVATServices(dispatch, dataToSave)
            handleChildFormSave()
        } catch (error) {}
    }

    const [servicesEnabled, setServicesEnabled] = useState(
        vateditdata?.status === 'Active'
    )
    useEffect(() => {
        if (vateditdata?.status === 'Active') {
            setServicesEnabled(true)
        } else {
            setServicesEnabled(false)
        }
    }, [vateditdata?.status])
    const handleServiceSwitchChange = (checked: boolean): void => {
        setServicesEnabled(checked)
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                form={form}
                name="ct-form"
                layout={'vertical'}
                autoComplete="off"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={24} className={Servicesless.services}>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Office"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="1"
                                checked={switchStates[1]}
                                onChange={() => {
                                    handleSwitchChange(1)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Business"
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="2"
                                checked={switchStates[2]}
                                onChange={() => {
                                    handleSwitchChange(2)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Correspondence"
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="3"
                                checked={switchStates[3]}
                                onChange={() => {
                                    handleSwitchChange(3)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            name="vatNumber"
                            label="VAT Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your VAT Number!',
                                },
                                {
                                    pattern: /^\d{9}$/,
                                    message: 'VAT Number must be 9 digits!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="addressLineOne"
                            label="Address 1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Address 1!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item label="VAT Scheme" name="vatScheme">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="STANDARD">STANDARD</Option>
                                <Option value="FLAT RATE">FLAT RATE</Option>
                                <Option value="ANNUAL">ANNUAL</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Address 2" name="addressLineTwo">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="VAT Registration Date"
                            name="vatRegistrationDate"
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        VatRegistrationDate?.length > 0
                                            ? dayjs(VatRegistrationDate)
                                            : null
                                    }
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="town"
                            label="Town"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Town!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="VAT Effective Date"
                            name="effectiveDate"
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange1(date, dateString)
                                    }}
                                    value={
                                        EffectiveDate?.length > 0
                                            ? dayjs(EffectiveDate)
                                            : null
                                    }
                                    className={commonStyles.dateWidth}
                                    format="DD/MM/YYYY"
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Country " name="country">
                            <Select
                                allowClear
                                placeholder="Please select"
                                showSearch
                                onChange={() => {}}
                                options={countryList}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="First Period Ending Date."
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your First Peirod Ending Date',
                                },
                            ]}
                            name="endingDate"
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange2(date, dateString)
                                    }}
                                    value={
                                        EndingDate != null
                                            ? dayjs(EndingDate)
                                            : null
                                    }
                                    className={commonStyles.dateWidth}
                                    format="DD/MM/YYYY"
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Post Code"
                            name="postCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Post Code!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item label="HMRC Gateway ID" name="hmrcGatewayId">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Password" name="hmrcPassword">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="VAT Frequency"
                            name="vatFrequencyName"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="ANNUALY">ANNUALY</Option>
                                <Option value="QUATARY">QUATARY</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Data Input" name="dataInput">
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="ANNUALY">RECEIPT BANK</Option>
                                <Option value="BY POST">BY POST</Option>
                                <Option value="NIL">NIL</Option>
                                <Option value="NOT REQUIRED">
                                    NOT REQUIRED
                                </Option>
                                <Option value="MANUAL PROCESS">
                                    MANUAL PROCESS
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Row>
                        <Col span={8}>
                            <Form.Item>
                                <Row gutter={8} align="middle">
                                    <Col span={24}>
                                        <span>Enable/Disable Services</span>
                                    </Col>
                                </Row>
                                <Row gutter={8} align="middle">
                                    <Col span={10}>
                                        <Switch
                                            onChange={handleServiceSwitchChange}
                                            checked={servicesEnabled}
                                        />
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save VAT Service">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleClick()
                                            .then(() => {})
                                            .catch((error: any) => {
                                                console.error(
                                                    'Error submitting form:',
                                                    error
                                                )
                                            })
                                    }}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): CtStateInterface => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(VATForm)
