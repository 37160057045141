import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Switch,
    Row,
    Col,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
    Tooltip,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'

import Partnerless from '../Partnershipserviceless.less'
import {
    type VatpageStateProp,
    type PayeEditpageProps,
    type TogilleAddress,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import { GetCountryList } from '../../../../../../Services/Common'
import moment from 'moment'
import dayjs from 'dayjs'
import { GetAddress, PayeSaveData } from '../../../../../../Services/SoleTrader'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
const { Option } = Select

const PayeEditForm = (props: PayeEditpageProps): React.ReactElement => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [servicesEnabled, setServicesEnabled] = useState(false)
    const [CISFillingEnabled, setCISFillingEnabled] = useState(false)
    const [schemeStartDate, setschemeStartDate] = useState<string>('')
    const { PayeeditData, handleChildFormSave, soletrderId, countryList } =
        props

    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })
    const [addressDatas, setAddressData] = useState<TogilleAddress | null>(null)

    useEffect(() => {
        GetCountryList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if (countryList !== null && PayeeditData !== null) {
            const countryData = countryList.find(
                (data: ListFrontEndInterface) =>
                    data.label === PayeeditData.country
            )
            const newData = {
                ...PayeeditData,
                country: countryData?.label ?? 'Please Select',
            }
            form.setFieldsValue(newData)
            setServicesEnabled(PayeeditData.status === 'Active')
            setCISFillingEnabled(PayeeditData.csiFiling === 1)
        }
    }, [PayeeditData, countryList])

    const handleServiceSwitchChange = (checked: boolean): void => {
        setServicesEnabled(checked)
    }

    const handleCISFillingChange = (checked: boolean): void => {
        setCISFillingEnabled(checked)
    }
    useEffect(() => {
        form.setFieldsValue(PayeeditData)
        if (PayeeditData !== null) {
            const SchemeStartDate = moment(
                PayeeditData.schemeStartDate,
                'M/D/YYYY h:mm:ss A'
            )
            const formastartdate = SchemeStartDate.format('MM/DD/YYYY')
            setschemeStartDate(formastartdate)
        }
    }, [PayeeditData])

    useEffect(() => {
        form.setFieldValue('schemeStartDate', schemeStartDate)
    }, [schemeStartDate])

    const onChangeStartDate = (
        deadlineDate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (deadlineDate != null) {
            const isoDate = deadlineDate.toISOString()
            setschemeStartDate(isoDate)
        }
    }

    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }
        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetAddress(
                dispatch,
                soletrderId,
                switchId,
                (addressData: TogilleAddress) => {
                    setAddressData(addressData)
                }
            )
        }
        setSwitchStates(newSwitchStates)
    }

    useEffect(() => {
        if (addressDatas !== null && typeof addressDatas === 'object') {
            const newFieldValues = {
                addressLineOne: addressDatas?.addressLineOne,
                addressLineTwo: addressDatas?.addressLineTwo,
                town: addressDatas?.town,
                country: addressDatas?.country,
                postCode: addressDatas?.postCode,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [addressDatas])

    const handelsave = (): void => {
        void handelDatasave()
    }
    const handelDatasave = async (): Promise<void> => {
        try {
            await form.validateFields()
            await form.validateFields(['schemeStartDate'])
            const formData = form.getFieldsValue()

            const filingMethodName = formData.filingMethodName
            let filingMethod: number
            if (filingMethodName === 'Nil Return') {
                filingMethod = 1
            } else if (filingMethodName === 'Standard Filing') {
                filingMethod = 2
            } else if (
                filingMethodName === 'Hours to be obtained WEEKLY Filing'
            ) {
                filingMethod = 3
            } else {
                filingMethod = 0
            }

            const dataToSave = {
                payeId: PayeeditData.payeId,
                companyType: 2,
                referenceId: soletrderId,
                payeReference: formData.payeReference,
                accountOfficeReference: formData.accountOfficeReference,
                payrollFrequency: PayeeditData.payrollFrequency,
                schemeStartDate: moment(formData.schemeStartDate).format(
                    'DD/MM/YYYY'
                ),
                addressLineOne: formData.addressLineOne,
                addressLineTwo: formData.addressLineTwo,
                town: formData.town,
                country: formData.country,
                postCode: formData.postCode,
                gateWayId: formData.gateWayId,
                password: PayeeditData.password,
                status: servicesEnabled ? 'Active' : 'Inactive',
                csiFiling: CISFillingEnabled ? 1 : 0,
                payeProcess: PayeeditData.payeProcess,
                filingMethod: filingMethod ?? 0,
                payeProcessName: PayeeditData.payeProcessName,
                filingMethodName: formData.filingMethodName,
                hmrcPaymentFrequency:
                    formData.hmrcPaymentFrequencyName === 'Monthly' ? 1 : 2,
                hmrcPaymentFrequencyName: formData.hmrcPaymentFrequencyName,
            }
            void PayeSaveData(dispatch, dataToSave)
            handleChildFormSave()
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                form={form}
                name="ct-form"
                layout={'vertical'}
                autoComplete="off"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Office"
                            name="businessSwitch"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                id="2"
                                checked={switchStates[1]}
                                onChange={() => {
                                    handleSwitchChange(1)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Business"
                            name="businessSwitch"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                id="2"
                                checked={switchStates[2]}
                                onChange={() => {
                                    handleSwitchChange(2)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Correspondence"
                            name="correspondenceSwitch"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                id="3"
                                checked={switchStates[3]}
                                onChange={() => {
                                    handleSwitchChange(3)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="PAYE Reference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your PAYE Reference!',
                                },
                            ]}
                            name="payeReference"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="addressLineTwo" label="Address 2">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Account Office Reference"
                            name="accountOfficeReference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your Account Office Reference!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Town "
                            name="town"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Town!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Payroll Frequency"
                            name="payrollFrequency"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="WEEKLY">WEEKLY</Option>
                                <Option value="BI WEEKLY">BI WEEKLY</Option>
                                <Option value="FOUR WEEKLY">FOUR WEEKLY</Option>
                                <Option value="MONTHLY">MONTHLY</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Country " name="country">
                            <Select
                                allowClear
                                placeholder="Please select"
                                showSearch
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country.label,
                                    label: country.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Scheme Start Date"
                            name="schemeStartDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Start Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    className={commonStyles.dateWidth}
                                    onChange={(date, dateString) => {
                                        onChangeStartDate(date, dateString)
                                    }}
                                    value={
                                        schemeStartDate.length > 0
                                            ? dayjs(schemeStartDate)
                                            : null
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Post Code"
                            name="postCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Post Code!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Address 1"
                            name="addressLineOne"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input addressLineOne!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="HMRC Gateway ID" name="gateWayId">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item label="CIS Filing" name="csiFiling">
                            <Switch
                                onChange={handleCISFillingChange}
                                checked={CISFillingEnabled}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Filing Method"
                            name="filingMethodName"
                        >
                            <Select
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="Nil Return">Nil Return</Option>
                                <Option value="Standard Filing">
                                    Standard Filing
                                </Option>
                                <Option value="Hours to be obtained WEEKLY">
                                    Hours to be obtained WEEKLY
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Partnerless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Enable/Disable Services"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                onChange={handleServiceSwitchChange}
                                checked={servicesEnabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="HMRC Payment Frequency"
                            name="hmrcPaymentFrequencyName"
                        >
                            <Select
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="Monthly">MONTHLY</Option>
                                <Option value="Quaterly">QUATERLY</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save PAYE">
                                <Button type="primary" onClick={handelsave}>
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}
const mapStateToProps = (state: any): VatpageStateProp => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(PayeEditForm)
