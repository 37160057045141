import { Col, Flex, Row, Space, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import { useDispatch, connect } from 'react-redux'
import {
    GetNotificationDetials,
    SetReadMessage,
} from '../../Services/Notificcations'
import {
    type Message,
    type MessageListInterface,
} from '../../Types/Notifications'
import style from './test.less'
import notification from './notification.less'
import { servicedataId } from '../../Reducer/DashboardFlagReducer'
import { useNavigate } from 'react-router-dom'
const DashboarNotificationPopup = (
    props: MessageListInterface
): React.ReactElement => {
    const { messageList } = props
    const dispatch = useDispatch()
    const [clickedIndex, setClickedIndex] = useState(1)
    const navigate = useNavigate()
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetNotificationDetials(dispatch)
    }, [])

    const handleItemClick = (index: number): void => {
        setClickedIndex(index === clickedIndex ? 1 : index)
    }
    // const handleMouseEnter = (index: number): void => {
    //     setHoveredIndex(index)
    // }

    // const handleMouseLeave = (): void => {
    //     setHoveredIndex(null)
    // }

    const handleCheckClick = (notificationId: number, status: number): void => {
        SetReadMessage(dispatch, clickedIndex, notificationId, status)
        GetNotificationDetials(dispatch)
    }

    const handelNavigateServiceProcessPage = (message: Message): void => {
        if (
            message.notificationReferenceType === 1 &&
            message.notificationReferenceId !== 0 &&
            message.fromUserId !== -1 &&
            message.messageTitle !== 'Task' &&
            message.messageTitle !== ''
        ) {
            const serviceprocessId = message.notificationReferenceId
            const serviceId = 1
            dispatch(
                servicedataId({
                    serviceProcesssId: serviceprocessId,
                    serviceId,
                })
            )
            navigate(`/thingto-do/service-process`)
        }
    }
    const renderMessages = (messages: Message[]): any => {
        return messages?.map((message, index) => (
            <div className={style.cont} key={index}>
                <div
                    className={`${style.sec}`}
                    onClick={() => {
                        handelNavigateServiceProcessPage(message)
                    }}
                >
                    <div className={style.profCont}>
                        <img
                            className={style.profile}
                            src={message.fromUserImageUrl}
                        />
                    </div>
                    <div className={style.txt}>{message.messageTitle}</div>

                    <div className={`${style.sub}`}>
                        {message.messgeContent}
                    </div>
                    <div className={style.sub}>{message.dateInfo}</div>
                    <div className={style.container}>
                        <Flex justify="space-between">
                            <div></div>
                            <Tooltip title={'Mark As Read'}>
                                <CheckOutlined
                                    onClick={() => {
                                        handleCheckClick(message.messageId, 1)
                                    }}
                                    className={style.checkIcon}
                                />
                            </Tooltip>
                        </Flex>
                    </div>
                </div>
            </div>
        ))
    }

    return (
        <>
            <div className={notification.contrinert}>
                <p className={notification.notificationCount}>
                    {messageList?.totalUnreadNotificationCount} New
                </p>
                <p>User Notification</p>
            </div>
            <hr />
            <div className={notification.contentDisplay}>
                <Row>
                    <Col span={12}>
                        <div
                            className={
                                clickedIndex === 1
                                    ? `${notification.messagestyle}`
                                    : `${notification.messagestyle2}`
                            }
                            onClick={() => {
                                handleItemClick(1)
                            }}
                        >
                            Message({messageList?.unreadMessageCount})
                        </div>
                    </Col>
                    <Col span={12}>
                        <div
                            className={
                                clickedIndex === 2
                                    ? `${notification.messagestyle}`
                                    : `${notification.messagestyle2}`
                            }
                            onClick={() => {
                                handleItemClick(2)
                            }}
                        >
                            Feeds({messageList?.unreadFeedCount})
                        </div>
                    </Col>
                </Row>
            </div>

            {clickedIndex === 1 && (
                <div className={style.box}>
                    <Space direction="vertical" size="large">
                        {renderMessages(messageList?.messageList)}
                    </Space>
                </div>
            )}
            {clickedIndex === 2 && (
                <div>
                    <h5> No record Found</h5>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state: any): MessageListInterface => {
    return {
        messageList: state.notification.messageList,
    }
}

export default connect(mapStateToProps)(DashboarNotificationPopup)
