import React from 'react'
import { Col, Row, Input, Form, Button, Card, Tooltip } from 'antd'
import commonStyles from '../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import { SaveChangePin } from '../../Services/Profile'

const ChangePin = (): React.ReactElement => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString != null ? JSON.parse(tokenDetailsString) : null

    const onSave = (): void => {
        void onSavePin()
    }
    const onSavePin = async (): Promise<void> => {
        await form.validateFields()

        const formData = form.getFieldsValue()

        if (formData.conformPin === formData.newPin) {
            try {
                SuccessMessage('PIN Match')
                const dataTosave = {
                    userId: tokenDetails?.userId,
                    newPIN: formData.newPin,
                }
                await SaveChangePin(dispatch, dataTosave)

                form.resetFields()
            } catch (error) {
                console.error('Error:', error)
            }
        } else {
            ErrorMessage('The two PIN do not match!')
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Card title={'Change Pin'}>
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Current Pin"
                                    name="currentPin"
                                    rules={[
                                        {
                                            pattern: /^[0-9]{4}$/,
                                            message: 'Only numbers are allowed',
                                        },
                                        {
                                            required: true,
                                            message:
                                                'Please input your Current Pin!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your four digit current PIN" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="New Pin (Numbers Only)"
                                    name="newPin"
                                    rules={[
                                        {
                                            pattern: /^[0-9]{4}$/,
                                            message: 'Only numbers are allowed',
                                        },
                                        {
                                            required: true,
                                            message:
                                                'Please input your New Pin!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter your four digit PIN" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Confirm Pin (Numbers Only)"
                                    name="conformPin"
                                    rules={[
                                        {
                                            pattern: /^[0-9]{4}$/,
                                            message: 'Only numbers are allowed',
                                        },
                                        {
                                            required: true,
                                            message:
                                                'Please input your Conform pin!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Re-type your four digit PIN" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col offset={21} span={2}>
                                <Tooltip title="change pin">
                                    <Button type="primary" onClick={onSave}>
                                        <SaveOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        </>
    )
}

export default ChangePin
