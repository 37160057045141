import {
    Card,
    Collapse,
    Space,
    Form,
    Row,
    Col,
    Input,
    Button,
    DatePicker,
    Select,
    type SelectProps,
    type CollapseProps,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import commonStyles from '../../../Utils/Common.less'
import CommonButton from '../../../Components/Commonbutton'
import AddNewButton from '../../../Components/AddNewButton'
import invoive from './Invoice.less'
import {
    type AdvanceSearchInterface,
    type InvoiceDetailsListInterface,
} from '../../../Types/Invoice/Invoicing'
import { connect, useDispatch } from 'react-redux'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { GetUserList } from '../../../Services/User'
import UnPaidInvoiceList from './unpaidInvoice'
import PaidInvoiceList from './paidInvoice'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { ErrorMessage } from '../../../Utils/Notification'
import axios from '../../../Config/AxiosConfig'

const { RangePicker } = DatePicker

const InvoiceList = (
    props: InvoiceDetailsListInterface
): React.ReactElement => {
    const { userList, serviceDropDownList } = props
    const [activeButton, setActiveButton] = useState(1)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [period, setPeriod] = useState<any>(null)
    const [selectedClienttext, setSelectedClient] = useState('')
    const navigate = useNavigate()
    const [advanceSearchData, setAdvanceSearchData] =
        useState<AdvanceSearchInterface>()

    const [resetData, setReSetData] = useState(false)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        const buttonId = localStorage.getItem('activestatusButton')
        if (buttonId !== null && buttonId !== undefined) {
            handleButtonClick(Number(buttonId))
            setActiveButton(Number(buttonId))
        }
    }, [])
    const handleButtonClick = (buttonName: number): void => {
        localStorage.setItem('activestatusButton', buttonName.toString())
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setActiveButton(buttonName)
        } else if (buttonName === 2) {
            setActiveButton(buttonName)
        }
    }
    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return (
                <>
                    <UnPaidInvoiceList
                        activeButton={activeButton}
                        searchData={advanceSearchData ?? null}
                        resetData={resetData}
                    />
                </>
            )
        }
        return <></>
    }

    const onRangePickerChange = (
        dates: any,
        dateStrings: [string, string]
    ): void => {
        if (dates !== null) {
            const formattedDates = dates.map((date: moment.Moment) =>
                date.format('YYYY-MM-DD')
            )
            setPeriod(formattedDates)
        }
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onClick = (): void => {
        const formData = form.getFieldsValue()
        const parts = selectedClienttext.split('-')
        const searchData = {
            ReferenceId: parts[1] ?? '',
            FromDate:
                period?.[0] !== undefined
                    ? moment(period[0]).format('DD/MM/YYYY')
                    : '',
            ToDate:
                period?.[1] !== undefined
                    ? moment(period[1]).format('DD/MM/YYYY')
                    : '',
            RaisedById: formData.RaisedById ?? '',
            ServiceId: formData.ServiceId ?? '',
            InvoiceNo: formData.InvoiceNo ?? '',
            BusinessType: parts[0] ?? '',
        }
        const advancesearch: AdvanceSearchInterface = {
            searchCriteriaList: [
                {
                    fieldName: 'Period',
                    searchOperator: 7,
                    fieldValue1: searchData.FromDate,
                    fieldValue2: searchData.ToDate,
                },
                {
                    fieldName: 'ReferenceId',
                    searchOperator: 1,
                    fieldValue1: searchData.ReferenceId,
                    fieldValue2: '',
                },
                {
                    fieldName: 'RaisedById',
                    searchOperator: 1,
                    fieldValue1: searchData.RaisedById,
                    fieldValue2: '',
                },
                {
                    fieldName: 'ServiceId',
                    searchOperator: 1,
                    fieldValue1: searchData.ServiceId,
                    fieldValue2: '',
                },
                {
                    fieldName: 'InvoiceNo',
                    searchOperator: 1,
                    fieldValue1: searchData.InvoiceNo,
                    fieldValue2: '',
                },
                {
                    fieldName: 'BusinessType',
                    searchOperator: 1,
                    fieldValue1: searchData.BusinessType,
                    fieldValue2: '',
                },
            ],
            sortOrderList: [],
        }
        setAdvanceSearchData(advancesearch)
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<SelectProps['options']>([])
    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)

        await axios
            .get(`api/GetStandardListingTwo/1/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <div>
                                    <div
                                        className={invoive.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                invoive.clientDropdownListSpan
                                            }
                                        >
                                            Name-
                                            {client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                invoive.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <span
                                            className={
                                                invoive.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Email-
                                            {client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const onChangeSearch = (value: string): void => {
        setSelectedClient(value)
    }
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
        GetServiceDropDownList(dispatch)
    }, [])

    const handleResetFields = (): void => {
        setReSetData(true)
        form.resetFields()
    }

    const openCreateModal = (): void => {
        navigate(`/invoicing/invoic-status/create-invoice`)
    }
    const [searchValue, setSearchValue] = useState<string>('')
    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue?.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Advance Search',
            children: (
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Client">
                                    <Select
                                        loading={isLoading}
                                        showSearch
                                        placeholder="To filter the clients by number /name"
                                        onChange={(value) => {
                                            onChangeSearch(value)
                                        }}
                                        onSearch={(value) => {
                                            setSearchValue(value)
                                        }}
                                        filterOption={false}
                                        allowClear
                                        onClear={() => {
                                            setData([])
                                        }}
                                        optionLabelProp="label"
                                        options={data}
                                        optionRender={(option) => (
                                            <>{option.data.desc}</>
                                        )}
                                        notFoundContent={null}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Period" name="period">
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            format="MM/DD/YYYY"
                                            className={invoive.dateWidth}
                                            onChange={(date, dateString) => {
                                                onRangePickerChange(
                                                    date,
                                                    dateString
                                                )
                                            }}
                                            value={
                                                period != null
                                                    ? [
                                                          dayjs(period[0]),
                                                          dayjs(period[1]),
                                                      ]
                                                    : null
                                            }
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Service" name="ServiceId">
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Please select"
                                        onChange={() => {}}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={serviceDropDownList}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Raised by" name="RaisedById">
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Please select"
                                        onChange={() => {}}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={userList}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Invoice No" name="InvoiceNo">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="end">
                            <Col>
                                <Button
                                    danger
                                    type="primary"
                                    onClick={handleResetFields}
                                    className={invoive.closeButton}
                                    style={{ marginRight: 8 }}
                                >
                                    <CloseOutlined />
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" onClick={onClick}>
                                    <SearchOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            ),
        },
    ]
    return (
        <>
            <Card
                title="Invoicing"
                className={commonStyles.card}
                extra={
                    <AddNewButton
                        name="Create Invoice"
                        clickAction={() => {
                            openCreateModal()
                        }}
                    />
                }
            >
                <div style={{ marginBottom: '20px' }}>
                    <Collapse
                        items={items}
                        defaultActiveKey={['']}
                        expandIconPosition="end"
                    />
                </div>
                <CommonButton
                    name="Unpaid"
                    isActive={activeButton === 1}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(1)
                    }}
                />
                <CommonButton
                    name="Paid"
                    isActive={activeButton === 2}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(2)
                    }}
                />
                {activeButton === 1 && (
                    <UnPaidInvoiceList
                        activeButton={activeButton}
                        searchData={advanceSearchData ?? null}
                        resetData={resetData}
                    />
                )}
                {activeButton === 2 && (
                    <PaidInvoiceList
                        activeButton={activeButton}
                        searchData={advanceSearchData ?? null}
                        resetData={resetData}
                    />
                )}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): InvoiceDetailsListInterface => {
    return {
        clientDropDownList: state.invoice.clientDropDownList,
        userList: state.user.userList,
        serviceDropDownList: state.initial.serviceDropDownList,
    }
}

export default connect(mapStateToProps)(InvoiceList)
