import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import customcss from './preview.less'
import hederimage from '../../../../Assest/Svg/pattern-2.svg'
import { useLocation } from 'react-router-dom'
import { GetInvoicePreview } from '../../../../Services/Invoice'
import { useDispatch } from 'react-redux'
import {
    type InvoiceHeader,
    type BusinessDocumentTemplate,
} from '../../../../Types/Invoice/Invoicing'

const PreviewInvoice = (): React.ReactElement => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { InvoiceId } = location.state
    const [businessDocumentTemplate, setBusinessDocumentTemplate] =
        useState<BusinessDocumentTemplate | null>(null)

    const [invoiceHeader, setInvoiceHeader] = useState<InvoiceHeader | null>(
        null
    )

    useEffect(() => {
        const GetInvoicePreviews = async (): Promise<void> => {
            const previewDatas = await GetInvoicePreview(dispatch, InvoiceId)

            setBusinessDocumentTemplate(previewDatas.businessDocumentTemplate)
            setInvoiceHeader(previewDatas.invoiceHeader)
        }

        void GetInvoicePreviews()
    }, [InvoiceId])

    // const handleDownloadPDFDoc = async (): Promise<void> => {
    //     // const Url = `https://iykons-s3-storage.s3.eu-west-1.amazonaws.com/Document/BusinessLogo/637490793663999379.png`

    //     const pdfDoc = await PDFDocument.create()
    //     const page = pdfDoc.addPage([650, 842]) // A4 size in points
    //     const fontSize = 12
    //     const height = 842
    //     // Load and embed the fonts
    //     const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    //     // const timesItalicFont = await pdfDoc.embedFont('Times-Italic')
    //     const timesRomanBoldFont = await pdfDoc.embedFont(
    //         StandardFonts.TimesRomanBold
    //     )
    //     const convertSvgToPng = async (svgUrl: string): Promise<string> => {
    //         return await new Promise((resolve, reject) => {
    //             const canvas = document.createElement('canvas')
    //             const ctx = canvas.getContext('2d')
    //             const img = new Image()

    //             img.onload = () => {
    //                 // Set canvas dimensions to match SVG
    //                 canvas.width = img.width
    //                 canvas.height = img.height

    //                 // Draw SVG on canvas
    //                 ctx?.drawImage(img, 0, 0)
    //                 resolve(canvas.toDataURL('image/png'))
    //             }

    //             img.onerror = reject
    //             img.src = svgUrl
    //         })
    //     }

    //     try {
    //         // Convert the SVG to PNG data URL
    //         const headerPngDataUrl = await convertSvgToPng(hederimage)
    //         const headerImageBytes = await fetch(headerPngDataUrl).then(
    //             async (res) => await res.arrayBuffer()
    //         )
    //         const headerImage = await pdfDoc.embedPng(headerImageBytes)

    //         // Draw the header image
    //         page.drawImage(headerImage, {
    //             x: 25,
    //             y: 810,
    //             width: 600,
    //             height: 10,
    //         })
    //     } catch (error) {
    //         console.error('Error converting SVG to PNG:', error)
    //     }
    //     // Set the font size and color
    //     const fontColor = rgb(0, 0, 0)

    //     // Draw company name and details
    //     page.drawText(businessDocumentTemplate?.companyName ?? '', {
    //         x: 65,
    //         y: 780,
    //         size: 14,
    //         font: timesRomanBoldFont,
    //         color: fontColor,
    //     })

    //     // Draw additional details
    //     page.drawText(
    //         `${businessDocumentTemplate?.addressOne ?? ''}, ${
    //             businessDocumentTemplate?.addressTwo ?? ''
    //         }, ${businessDocumentTemplate?.town ?? ''}, ${
    //             businessDocumentTemplate?.postCode ?? ''
    //         }, ${businessDocumentTemplate?.country ?? ''}`,
    //         {
    //             x: 65,
    //             y: 765,
    //             size: 7,
    //             font: timesRomanFont,
    //             color: fontColor,
    //         }
    //     )
    //     page.drawText(businessDocumentTemplate?.website ?? '', {
    //         x: 65,
    //         y: 755,
    //         size: 7,
    //         font: timesRomanFont,
    //         color: fontColor,
    //     })

    //     try {
    //         const imageUrl = `https://api.allorigins.win/raw?url=${businessDocumentTemplate?.companyLogo}`
    //         const base64String = await convertImageToBase64(imageUrl)

    //         const imageBytes = await fetch(base64String).then(
    //             async (res) => await res.arrayBuffer()
    //         )
    //         const image = await pdfDoc.embedPng(imageBytes)
    //         page.drawImage(image, {
    //             x: 450,
    //             y: 750,
    //             width: 120,
    //             height: 50,
    //         })
    //     } catch (error) {
    //         console.error('Error embedding image:', error)
    //     }

    //     page.drawText('Invoice', {
    //         x: 50,
    //         y: height - 150,
    //         size: 20,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })

    //     // Invoice Details
    //     const invoiceDetails = [
    //         { label: 'Invoice To', value: invoiceHeader?.clientName },
    //         { label: 'Invoice No', value: invoiceHeader?.invoiceNo },
    //         {
    //             label: 'Invoice Date',
    //             value: getdateOnly(invoiceHeader?.invoiceDate),
    //         },
    //     ]

    //     invoiceDetails.forEach((detail, index) => {
    //         page.drawText(`${detail.label}:`, {
    //             x: 50,
    //             y: height - 200 - index * 20,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(0, 0, 0),
    //         })
    //         page.drawText(detail.value ?? '', {
    //             x: 150,
    //             y: height - 200 - index * 20,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(0, 0, 0),
    //         })
    //     })
    //     const drawLeftAlignedText = (
    //         page1: PDFPage,
    //         text: string,
    //         x: number,
    //         y: number,
    //         width: number,
    //         fontSize: number,
    //         font: PDFFont,
    //         fontColor: RGB
    //     ): void => {
    //         const words = text.split(' ')
    //         let line = ''
    //         const lines = []
    //         const spaceWidth = font.widthOfTextAtSize(' ', 12)
    //         let currentWidth = 0

    //         // Calculate the lines of text
    //         words.forEach((word: string) => {
    //             const wordWidth = font.widthOfTextAtSize(word, fontSize)
    //             if (currentWidth + wordWidth + spaceWidth <= width) {
    //                 line += (line.length > 0 ? ' ' : '') + word
    //                 currentWidth += wordWidth + spaceWidth
    //             } else {
    //                 lines.push(line)
    //                 line = word
    //                 currentWidth = wordWidth + spaceWidth
    //             }
    //         })
    //         lines.push(line)

    //         // Draw each line with left alignment
    //         lines.forEach((line, index) => {
    //             page1.drawText(line, {
    //                 x,
    //                 y: y - index * (fontSize + 2), // Adjust line height as needed
    //                 size: fontSize,
    //                 font,
    //                 color: fontColor,
    //             })
    //         })
    //     }

    //     // Table Header
    //     const tableY = height - 300
    //     page.drawText('Service', {
    //         x: 50,
    //         y: tableY,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText('Description', {
    //         x: 200,
    //         y: tableY,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText('Net', {
    //         x: 390,
    //         y: tableY,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText('Discount', {
    //         x: 430,
    //         y: tableY,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText('Tax', {
    //         x: 490,
    //         y: tableY,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText('Total', {
    //         x: 550,
    //         y: tableY,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawLine({
    //         start: { x: 50, y: 530 },
    //         end: { x: 580, y: 530 },
    //         thickness: 1,
    //         color: rgb(0, 0, 0),
    //     })
    //     // Table Content
    //     let rigitTableY = 0
    //     invoiceHeader?.invoiceBodyList?.forEach((item, index) => {
    //         const yPosition = tableY - 30 - index * 50
    //         rigitTableY = yPosition
    //         page.drawText(item.serviceName, {
    //             x: 50,
    //             y: yPosition,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(0, 0, 0),
    //         })
    //         drawLeftAlignedText(
    //             page,
    //             `${item.lineDescription}`,
    //             150, // x position
    //             yPosition, // y position
    //             200, // Width for wrapping text
    //             12,
    //             timesRomanFont,
    //             fontColor
    //         )
    //         // page.drawText(item.description, {
    //         //     x: 150,
    //         //     y: yPosition,
    //         //     size: fontSize,
    //         //     font: timesRomanFont,
    //         //     color: rgb(0, 0, 0),
    //         // })
    //         page.drawText(item.netAmount.toString(), {
    //             x: 390,
    //             y: yPosition,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(0, 0, 0),
    //         })
    //         page.drawText(
    //             item.discountType === 1
    //                 ? `${item.discount}(%)`
    //                 : `${item.discount}(\u00A3)`,
    //             {
    //                 x: 450,
    //                 y: yPosition,
    //                 size: fontSize,
    //                 font: timesRomanFont,
    //                 color: rgb(0, 0, 0),
    //             }
    //         )
    //         page.drawText(item.tax.toString(), {
    //             x: 500,
    //             y: yPosition,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(0, 0, 0),
    //         })
    //         page.drawText(
    //             `${
    //                 item.totalAfterDiscount === 0
    //                     ? `£${item.netAmount.toString()}`
    //                     : `£${item.totalAfterDiscount.toString()}`
    //             }`,
    //             {
    //                 x: 555,
    //                 y: yPosition,
    //                 size: fontSize,
    //                 font: timesRomanFont,
    //                 color: rgb(0, 0, 0),
    //             }
    //         )
    //         page.drawLine({
    //             start: { x: 50, y: yPosition - 35 },
    //             end: { x: 580, y: yPosition - 35 },
    //             thickness: 1,
    //             color: rgb(0, 0, 0),
    //         })
    //     })

    //     page.drawText(`Sub Total`, {
    //         x: 420,
    //         y: rigitTableY - 60,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`Discount`, {
    //         x: 420,
    //         y: rigitTableY - 80,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`Net Total`, {
    //         x: 420,
    //         y: rigitTableY - 100,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`Tax`, {
    //         x: 420,
    //         y: rigitTableY - 120,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`Invoice Amount`, {
    //         x: 420,
    //         y: rigitTableY - 140,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`${invoiceHeader?.subTotal}`, {
    //         x: 550,
    //         y: rigitTableY - 60,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`${invoiceHeader?.discountTotal}`, {
    //         x: 550,
    //         y: rigitTableY - 80,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`${invoiceHeader?.total}`, {
    //         x: 550,
    //         y: rigitTableY - 100,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`${invoiceHeader?.taxTotal}`, {
    //         x: 550,
    //         y: rigitTableY - 120,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(`£${invoiceHeader?.netTotal}`, {
    //         x: 550,
    //         y: rigitTableY - 140,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })

    //     let yPosition = height - 600
    //     const lineHeight = 20

    //     // Draw Due Date
    //     page.drawText('Due Date:', {
    //         x: 50,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(getdateOnly(invoiceHeader?.paymentDueDate) ?? '', {
    //         x: 150,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     yPosition -= lineHeight

    //     // Draw Account Name
    //     page.drawText('Account Name:', {
    //         x: 50,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(invoiceHeader?.accountName ?? '', {
    //         x: 150,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     yPosition -= lineHeight

    //     // Draw Bank
    //     page.drawText('Bank:', {
    //         x: 50,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(invoiceHeader?.paymentOptionName ?? '', {
    //         x: 150,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     yPosition -= lineHeight

    //     // Draw Sort Code
    //     page.drawText('Sort Code:', {
    //         x: 50,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(invoiceHeader?.swiftCode ?? '', {
    //         x: 150,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     yPosition -= lineHeight

    //     // Draw Account No
    //     page.drawText('Account No:', {
    //         x: 50,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     page.drawText(invoiceHeader?.accountNo ?? '', {
    //         x: 150,
    //         y: yPosition,
    //         size: fontSize,
    //         font: timesRomanFont,
    //         color: rgb(0, 0, 0),
    //     })
    //     // Footer
    //     const footerY = 50
    //     page.drawText(
    //         `Tel: ${businessDocumentTemplate?.mobileNo}${
    //             businessDocumentTemplate?.workNo !== null
    //                 ? `, ${businessDocumentTemplate?.workNo}`
    //                 : ''
    //         }`,
    //         {
    //             x: 50,
    //             y: footerY + 20,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(181 / 255, 182 / 255, 181 / 255),
    //         }
    //     )
    //     page.drawText(
    //         `Email: ${businessDocumentTemplate?.emailAddress}${
    //             businessDocumentTemplate?.website !== null
    //                 ? ` | Web: ${businessDocumentTemplate?.website}`
    //                 : ''
    //         }`,
    //         {
    //             x: 50,
    //             y: footerY,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(181 / 255, 182 / 255, 181 / 255),
    //         }
    //     )
    //     page.drawText(
    //         `Registered in England 06509841 | ACCA Registration number 2819986`,
    //         {
    //             x: 50,
    //             y: footerY - 20,
    //             size: fontSize,
    //             font: timesRomanFont,
    //             color: rgb(181 / 255, 182 / 255, 181 / 255),
    //         }
    //     )
    //     const pdfBytes = await pdfDoc.save()
    //     const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    //     const link = document.createElement('a')
    //     link.href = URL.createObjectURL(blob)
    //     link.download = `Invoice.pdf`
    //     link.click()
    // }

    return (
        <Card>
            <div className={customcss.body}>
                <div
                    data-size="A4"
                    id="pdf-content"
                    className={customcss.printableContent}
                >
                    <div>
                        <div>
                            <img
                                src={hederimage}
                                className={customcss.col_sm}
                            />
                        </div>
                    </div>
                    <div className={customcss.hedertext}>
                        <div>
                            <div className={customcss.headerContainer}>
                                <div>
                                    <h2
                                        className={
                                            customcss.hedertextCompanyName
                                        }
                                    >
                                        {businessDocumentTemplate?.companyName}
                                    </h2>
                                    <p className={customcss.headersmalltext}>
                                        <br />
                                        {businessDocumentTemplate?.addressOne},
                                        {businessDocumentTemplate?.addressTwo},
                                        {businessDocumentTemplate?.town},
                                        {businessDocumentTemplate?.postCode},
                                        {businessDocumentTemplate?.country},
                                    </p>
                                </div>

                                <div className={customcss.headerImage}>
                                    <img
                                        id="barcode"
                                        alt="companyLogo"
                                        className={customcss.headerlogo}
                                        src={
                                            businessDocumentTemplate?.companyLogo
                                        }
                                    />
                                </div>
                            </div>

                            <h2 className={customcss.title}>
                                <b> Invoice</b>
                            </h2>
                        </div>
                        <div>
                            <Row gutter={[48, 8]} className={customcss.text1}>
                                <Col>Invoice To</Col>
                                <Col>{invoiceHeader?.clientName}</Col>
                            </Row>
                            <Row gutter={[44, 8]}>
                                <Col>Invoice No</Col>
                                <Col>{invoiceHeader?.invoiceNo}</Col>
                            </Row>
                            <Row gutter={[32, 8]}>
                                <Col>Invoice Date</Col>
                                <Col>
                                    {getdateOnly(invoiceHeader?.invoiceDate)}
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <table className={customcss.tabletext}>
                                <thead>
                                    <tr className={customcss.tableTrborder}>
                                        <th className={customcss.th1}>
                                            Service
                                        </th>
                                        <th className={customcss.th2}>
                                            Description
                                        </th>
                                        <th className={customcss.th3}>Total</th>
                                        <th className={customcss.th4}>
                                            Discount
                                        </th>
                                        <th className={customcss.th5}>Tax</th>
                                        <th className={customcss.th6}>Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceHeader?.invoiceBodyList?.map(
                                        (item) => (
                                            <tr
                                                key={item.invoiceDetailId}
                                                className={customcss.trbrorder}
                                            >
                                                <td
                                                    className={
                                                        customcss.thService
                                                    }
                                                >
                                                    {item.serviceName}
                                                </td>
                                                <td
                                                    className={
                                                        customcss.tdstyle
                                                    }
                                                >
                                                    {item.lineDescription}
                                                </td>
                                                <td>
                                                    {item.grossTotal?.toFixed(
                                                        2
                                                    )}
                                                </td>
                                                <td>
                                                    {item.discountType === 1
                                                        ? `${item.discountValue?.toFixed(2)}(%)`
                                                        : `${item.discountValue?.toFixed(2)}(\u00A3)`}
                                                </td>
                                                <td>{item.tax?.toFixed(2)}</td>
                                                <td>
                                                    &#163;
                                                    {item.netAmount?.toFixed(2)}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className={customcss.tableBottomtext}>
                            <Row gutter={[48, 8]}>
                                <Col span={5}>Sub Total</Col>
                                <Col span={5} style={{ textAlign: 'end' }}>
                                    {invoiceHeader?.subTotal?.toFixed(2)}
                                </Col>
                            </Row>
                            <Row gutter={[48, 8]}>
                                <Col span={5}>Discount</Col>
                                <Col span={5} style={{ textAlign: 'end' }}>
                                    {invoiceHeader?.discountTotal?.toFixed(2)}
                                </Col>
                            </Row>

                            <Row gutter={[48, 8]}>
                                <Col span={5}>Tax</Col>
                                <Col span={5} style={{ textAlign: 'end' }}>
                                    {invoiceHeader?.taxTotal?.toFixed(2)}
                                </Col>
                            </Row>
                            <Row gutter={[48, 8]}>
                                <Col span={5}>Net Total</Col>
                                <Col span={5} style={{ textAlign: 'end' }}>
                                    {invoiceHeader?.netTotal?.toFixed(2)}
                                </Col>
                            </Row>
                            <Row gutter={[48, 8]}>
                                <Col span={5}>Invoice Amount</Col>
                                <Col span={5} style={{ textAlign: 'end' }}>
                                    <strong>
                                        &#163;
                                        {invoiceHeader?.netTotal?.toFixed(2)}
                                    </strong>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row gutter={[48, 8]}>
                                <Col span={5}>
                                    <strong> Due Date</strong>
                                </Col>
                                <Col>
                                    <strong>
                                        {getdateOnly(
                                            invoiceHeader?.paymentDueDate
                                        )}
                                    </strong>
                                </Col>
                            </Row>
                            <Row gutter={[48, 8]}>
                                <Col span={5}> Account Name</Col>
                                <Col> {invoiceHeader?.accountName}</Col>
                            </Row>
                            <Row gutter={[48, 8]}>
                                <Col span={5}>Bank</Col>
                                <Col> {invoiceHeader?.paymentOptionName}</Col>
                            </Row>
                            <Row gutter={[48, 8]}>
                                <Col span={5}> Sort Code</Col>
                                <Col> {invoiceHeader?.swiftCode}</Col>
                            </Row>
                            <Row gutter={[48, 8]}>
                                <Col span={5}> Account No</Col>
                                <Col> {invoiceHeader?.accountNo}</Col>
                            </Row>
                        </div>
                        <div className={customcss.footer}>
                            Tel: {businessDocumentTemplate?.mobileNo}{' '}
                            {businessDocumentTemplate?.workNo !== '' && (
                                <>
                                    <span>, </span>
                                    {businessDocumentTemplate?.workNo}
                                </>
                            )}
                            | Email:
                            {businessDocumentTemplate?.emailAddress}
                            {businessDocumentTemplate?.website !== '' && (
                                <>
                                    <span> | Web: </span>
                                    {businessDocumentTemplate?.website}
                                </>
                            )}
                            <br />
                            {businessDocumentTemplate?.documentFooterText}
                        </div>
                    </div>
                </div>
            </div>

            {/* <Row gutter={16} className={customcss.button}>
                <Col offset={18} span={2}>
                    <Button type="primary" onClick={downloadPd}>
                        Download
                    </Button>
                </Col>
            </Row> */}
        </Card>
    )
}

export default PreviewInvoice

export const getdateOnly = (dateString: string | undefined): string => {
    if (dateString !== undefined) {
        const dateObject = new Date(dateString)

        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()

        const formattedDate = `${month}/${day}/${year}`

        return formattedDate
    }
    return ''
}
