import React, { useEffect, useState } from 'react'
import { Col, Row, Collapse, Switch, Button, Tooltip } from 'antd'
import type {
    CorporateServicesView,
    ServiceStateInterface,
    ServiceCorporateTapss,
    ServiceCorporatepension,
    ServiceCorporatepaye,
    CompanyCTDetails,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import {
    GetPAYEService,
    GetPensionService,
    GetVATService,
    getClientCompanyDetails,
} from '../../../../../../Services/Corporate'
import { connect, useDispatch } from 'react-redux'
import Servicesless from '../../../../../../Layouts/Less/style.less'
import VATEdit, { vatdefaultdata } from './VATEdit'
import PensionEdit, { PensionData } from './PensionEdit'
import PAYEEdit, { PayeeDefaultData } from './PAYEEdit'
import VATForm from './VATForm'
import PensionForm from './PensionForm'
import PayeForm from './PayeForm'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import CTForm, { defaultCompanyDetails } from './CTForm'
import CtServiceViewPage from './CTServiceViewPage'
import { GetService } from '../../../../../../Services/SoleTrader'

const ServicesMain = (props: CorporateServicesView): React.ReactElement => {
    const { corporateTaps, corporateid } = props
    const { Panel } = Collapse
    const dispatch = useDispatch()
    const [PayeServiceData, setPayeServiceData] =
        useState<ServiceCorporatepaye>()
    const [VatServiceData, setVATServiceData] =
        useState<ServiceCorporateTapss>()
    const [PensionServiceData, setPensionServiceData] =
        useState<ServiceCorporatepension>()
    const [CTServiceData, setCTServiceData] = useState<CompanyCTDetails>()
    const [vateditData, setVatEditData] =
        useState<ServiceCorporateTapss>(vatdefaultdata)
    const [isVatEditMode, setIsVatEditMode] = useState(false)
    const [isPayeeEditMode, setIsPayeeEditMode] = useState(false)
    const [isPenitionEditMode, setIsPenisionEditMode] = useState(false)
    const [isCTEditMode, setIsCTEditMode] = useState(false)
    const [PenistioneditData, setPenitionEditData] =
        useState<ServiceCorporatepension>(PensionData)
    const [peyeeeditData, setpeyeeEditData] =
        useState<ServiceCorporatepaye>(PayeeDefaultData)
    const [CTeditData, setCTEditData] = useState<CompanyCTDetails>(
        defaultCompanyDetails
    )

    useEffect(() => {
        if (Array.isArray(corporateTaps) && corporateTaps?.length > 0) {
            const VATService = corporateTaps?.find(
                (service) => service?.serviceName === 'VAT'
            )
            const VATServiceReferenceId = VATService?.serviceReferenceId ?? 0

            if (VATServiceReferenceId > 0) {
                void GetVATService(dispatch, VATServiceReferenceId).then(
                    (data) => {
                        if (data !== null) {
                            setVATServiceData(data?.data)
                        }
                    }
                )
            }
        }
    }, [corporateTaps])
    useEffect(() => {
        if (Array.isArray(corporateTaps) && corporateTaps?.length > 0) {
            const CTService = corporateTaps?.find(
                (service) => service?.serviceName === 'CT'
            )
            const CTServiceReferenceId = CTService?.serviceReferenceId ?? 0
            const CTserviceId = CTService?.serviceId
            if (CTServiceReferenceId > 0) {
                void GetService(
                    dispatch,
                    CTserviceId,
                    CTServiceReferenceId
                ).then((data) => {
                    if (data !== null) {
                        setCTServiceData(data)
                    }
                })
            }
        }
    }, [corporateTaps])

    useEffect(() => {
        if (Array.isArray(corporateTaps) && corporateTaps?.length > 0) {
            const PayeService = corporateTaps?.find(
                (service) => service?.serviceName === 'PAYE'
            )
            const serviceReferenceId = PayeService?.serviceReferenceId ?? 0

            if (serviceReferenceId > 0) {
                void GetPAYEService(dispatch, serviceReferenceId).then(
                    (data) => {
                        if (data !== null) {
                            setPayeServiceData(data?.data)
                        }
                    }
                )
            }
        }
    }, [corporateTaps])

    useEffect(() => {
        if (Array.isArray(corporateTaps) && corporateTaps?.length > 0) {
            const PayeService = corporateTaps?.find(
                (service) => service?.serviceName === 'Pension'
            )
            const PensionServiceReferenceId =
                PayeService?.serviceReferenceId ?? 0

            if (PensionServiceReferenceId > 0) {
                void GetPensionService(
                    dispatch,
                    PensionServiceReferenceId
                ).then((data) => {
                    if (data !== null) {
                        setPensionServiceData(data?.data)
                    }
                })
            }
        }
    }, [corporateTaps])

    const datareFetch = (): void => {
        void getClientCompanyDetails(dispatch, 1, corporateid, 4)
    }

    const vathandleEditClick = (): void => {
        setIsVatEditMode(true)
        setVatEditData(VatServiceData ?? vatdefaultdata)
    }

    const CThandleEditClick = (): void => {
        setIsCTEditMode(true)
        setCTEditData(CTServiceData ?? defaultCompanyDetails)
    }
    const CThandleChildFormSave = (): void => {
        setIsCTEditMode(false)
        datareFetch()
    }
    const vathandleChildFormSave = (): void => {
        setIsCTEditMode(false)
        datareFetch()
    }
    const PensionhandleEditClick = (): void => {
        setIsPenisionEditMode(true)
        setPenitionEditData(PensionServiceData ?? PensionData)
    }

    const PensionhandleChildFormSave = (): void => {
        setIsPenisionEditMode(false)
        datareFetch()
    }
    const PayehandleEditClick = (): void => {
        setIsPayeeEditMode(true)
        setpeyeeEditData(PayeServiceData ?? PayeeDefaultData)
    }

    const PayehandleChildFormSave = (): void => {
        setIsPayeeEditMode(false)
        datareFetch()
    }
    return (
        <>
            <Collapse
                expandIconPosition="end"
                onChange={() => {
                    setIsCTEditMode(false)
                }}
            >
                <Panel
                    header="CT"
                    key="1"
                    extra={
                        <Switch
                            checked={CTServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Tooltip
                                title={`${isCTEditMode ? 'View Details' : 'Edit Details'}`}
                            >
                                <Button
                                    type="primary"
                                    onClick={CThandleEditClick}
                                >
                                    {isCTEditMode ? (
                                        <EyeOutlined />
                                    ) : ActivePremission(
                                          '3000',
                                          PermissionType.UPDATE
                                      ) ? (
                                        <EditOutlined />
                                    ) : (
                                        <></>
                                    )}
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isCTEditMode ? (
                        <div>
                            <CTForm
                                ctServiceData={CTeditData}
                                isEditMode={isCTEditMode}
                                corporateId={corporateid}
                                onSave={CThandleChildFormSave}
                            />
                        </div>
                    ) : (
                        <CtServiceViewPage ctServiceData={CTServiceData} />
                    )}
                </Panel>
            </Collapse>
            <Collapse
                expandIconPosition="end"
                onChange={() => {
                    setIsVatEditMode(false)
                }}
            >
                <Panel
                    header="VAT"
                    key="2"
                    extra={
                        <Switch
                            checked={VatServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Tooltip
                                title={`${isVatEditMode ? 'View Details' : 'Edit Details'}`}
                            >
                                <Button
                                    type="primary"
                                    onClick={vathandleEditClick}
                                >
                                    {isVatEditMode ? (
                                        <EyeOutlined />
                                    ) : ActivePremission(
                                          '3000',
                                          PermissionType.UPDATE
                                      ) ? (
                                        <EditOutlined />
                                    ) : (
                                        <></>
                                    )}
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isVatEditMode ? (
                        <div>
                            <VATForm
                                vateditdata={vateditData}
                                corporateid={corporateid}
                                handleChildFormSave={vathandleChildFormSave}
                            />
                        </div>
                    ) : (
                        <VATEdit VATServiceData={VatServiceData} />
                    )}
                </Panel>
            </Collapse>
            <Collapse
                expandIconPosition="end"
                onChange={() => {
                    setIsPayeeEditMode(false)
                }}
            >
                <Panel
                    header="PAYE"
                    key="3"
                    extra={
                        <Switch
                            checked={PayeServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Tooltip
                                title={`${isPayeeEditMode ? 'View Details' : 'Edit Details'}`}
                            >
                                <Button
                                    type="primary"
                                    onClick={PayehandleEditClick}
                                >
                                    {isPayeeEditMode ? (
                                        <EyeOutlined />
                                    ) : ActivePremission(
                                          '3000',
                                          PermissionType.UPDATE
                                      ) ? (
                                        <EditOutlined />
                                    ) : (
                                        <></>
                                    )}
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isPayeeEditMode ? (
                        <div>
                            <PayeForm
                                PAYEServiceData={peyeeeditData}
                                onSave={PayehandleChildFormSave}
                                corporateId={corporateid}
                                isEditMode={isPayeeEditMode}
                            />
                        </div>
                    ) : (
                        <PAYEEdit PAYEServiceData={PayeServiceData} />
                    )}
                </Panel>
            </Collapse>
            <Collapse
                expandIconPosition="end"
                className={Servicesless.servicesform}
                onChange={() => {
                    setIsPenisionEditMode(false)
                }}
            >
                <Panel
                    header="Pension"
                    key="4"
                    extra={
                        <Switch
                            checked={PensionServiceData?.status === 'Active'}
                            onChange={() => {}}
                        />
                    }
                >
                    <Row gutter={16} justify="end">
                        <Col>
                            <Tooltip
                                title={`${isPenitionEditMode ? 'View Details' : 'Edit Details'}`}
                            >
                                <Button
                                    type="primary"
                                    onClick={PensionhandleEditClick}
                                >
                                    {isPenitionEditMode ? (
                                        <EyeOutlined />
                                    ) : ActivePremission(
                                          '3000',
                                          PermissionType.UPDATE
                                      ) ? (
                                        <EditOutlined />
                                    ) : (
                                        <></>
                                    )}
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isPenitionEditMode ? (
                        <div>
                            <PensionForm
                                PensionServiceData={PenistioneditData}
                                isEditMode={isPenitionEditMode}
                                corporateId={corporateid}
                                onSave={PensionhandleChildFormSave}
                            />
                        </div>
                    ) : (
                        <PensionEdit PensionServiceData={PensionServiceData} />
                    )}
                </Panel>
            </Collapse>
        </>
    )
}
const mapStateToProps = (state: any): ServiceStateInterface => {
    return {
        corporateTaps: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(ServicesMain)
