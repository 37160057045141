import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type {
    TemplateFormDataInterface,
    TemplateFormInterface,
} from '../../../Types/TemplateTypes'
import { TemplateTypeItemDefaultData } from './templateTypeList'
import { CheckTemplateTypeNameExist } from '../../../Services/TemplateType'
import { useDispatch } from 'react-redux'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const TemplateTypeForm = (props: TemplateFormInterface): React.ReactElement => {
    const { onSave, editData, isLoadingModal, resetdata, setResetData } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    let timeout: any

    useEffect(() => {
        form.setFieldsValue(editData ?? TemplateTypeItemDefaultData)
    }, [editData])
    useEffect(() => {
        if (resetdata === 'true') {
            resetForm()
            form.resetFields()
        }
        setResetData('false')
    }, [resetdata])
    useEffect(() => {
        form.setFieldValue('templateTypeId', '0')
    }, [])

    const resetForm = (): void => {
        form.setFieldsValue(TemplateTypeItemDefaultData)
    }

    const onNamechange = (event: any): void => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            void CheckTemplateTypeNameExist(
                dispatch,
                editData?.templateTypeId,
                event?.target?.value
            )
        }, 1000)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<TemplateFormDataInterface>
                    name="templateTypeId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<TemplateFormDataInterface>
                            label="Email Template Type"
                            name="templateTypeName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your template name!',
                                },
                            ]}
                        >
                            <Input onChange={onNamechange} />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1004', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={Boolean(isLoadingModal)}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

export default TemplateTypeForm
