import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    Space,
    DatePicker,
    Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { HolidayItemDefaultData } from './holidaysDetailsList'
import type {
    HolidayDetailsFormDataInterface,
    HolidayDetailsFormInterface,
    HolidayDetailsFormDataStateInterface,
} from '../../../Types/HolidayDetails'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import { GetCountryList } from '../../../Services/Common'
import type { DatePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
import moment from 'moment'

const { TextArea } = Input
const HolidayForm = (
    props: HolidayDetailsFormInterface
): React.ReactElement => {
    const {
        onSave,
        editData,
        countryList,
        isLoadingModal,
        resetdata,
        setResetData,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')

    useEffect(() => {
        form.setFieldValue('companyHolidayId', '0')
    }, [])
    useEffect(() => {
        if (resetdata === 'true') {
            resetForm()
            form.resetFields()
        }
        setResetData('false')
    }, [resetdata])
    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    useEffect(() => {
        setSelectedDate(editData.date)
    }, [editData.date])

    useEffect(() => {
        const countryData = countryList.find(
            (data: ListFrontEndInterface) => data.label === editData.countryName
        )
        const newData = {
            ...editData,
            countryName: countryData?.value ?? '',
            countryId:
                editData.countryId === 0 ? undefined : editData.countryId,
        }
        form.setFieldsValue(newData)
    }, [editData, countryList])

    useEffect(() => {
        form.setFieldValue('date', selectedDate)
    }, [selectedDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const formattedDate = dayjs(date).format('YYYY-MM-DD')
            setSelectedDate(formattedDate)
        }
    }

    const resetForm = (): void => {
        form.setFieldsValue({ ...HolidayItemDefaultData, countryId: undefined })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<HolidayDetailsFormDataInterface>
                    name="companyHolidayId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<HolidayDetailsFormDataInterface>
                            label="Country"
                            name="countryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select country!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                options={countryList}
                                filterOption={(input, option) => {
                                    return (
                                        option?.label
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) ??
                                        false
                                    )
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<HolidayDetailsFormDataInterface>
                            label="Holiday Name"
                            name="holidayName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your holiday name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<HolidayDetailsFormDataInterface>
                            label="Date"
                            name="date"
                            rules={[{ required: true }]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    className={commonStyles.dateWidth}
                                    disabledDate={(current) =>
                                        current !== null &&
                                        current < moment().startOf('day')
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<HolidayDetailsFormDataInterface>
                            label="Description"
                            name="description"
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1006', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Save">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={Boolean(isLoadingModal)}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): HolidayDetailsFormDataStateInterface => {
    return {
        countryList: state.common.countryList,
        parentCategoryList: state.initial.parentCategoryList,
    }
}
export default connect(mapStateToProps)(HolidayForm)
