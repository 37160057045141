import {
    Form,
    Input,
    Button,
    Switch,
    Col,
    Row,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import { GetCountryList } from '../../../../../../Services/Common'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import { type TogilleAddress } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import dayjs from 'dayjs'
import moment from 'moment'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import { GetAddress, SavePartner } from '../../../../../../Services/Parnership'

interface editPage {
    editData: any
    countryList: ListFrontEndInterface[]
    corporateId: number
    saveCallBack: CallableFunction
    ReFetchData: () => void
}
interface EditDataState {
    countryList: ListFrontEndInterface[]
}
const PartnerMemberEditForm = (props: editPage): React.ReactElement => {
    const { editData, countryList, corporateId, saveCallBack, ReFetchData } =
        props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })
    const [addressDatas, setAddressData] = useState<TogilleAddress | null>(null)
    useEffect(() => {
        form.setFieldValue('referenceId', '0')
        GetCountryList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if (countryList !== null && editData !== null) {
            const countryData = countryList?.find(
                (data: ListFrontEndInterface) => data.label === editData.country
            )
            const newData = {
                ...editData,
                country: countryData?.label ?? 'Please Select',
            }
            form.setFieldsValue(newData)
        }
    }, [editData, countryList])

    useEffect(() => {
        if (editData !== null) {
            const dateOfBirth = moment(
                editData.dateOfBirth,
                'M/D/YYYY h:mm:ss A'
            )
            const formattedDate = dateOfBirth.format('MM/DD/YYYY HH:mm:ss A')
            setSelectedDate(formattedDate)
        }
    }, [editData])

    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDate)
    }, [selectedDate])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }
        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetAddress(
                dispatch,
                corporateId,
                switchId,
                (addressData: TogilleAddress) => {
                    setAddressData(addressData)
                }
            )
        }
        setSwitchStates(newSwitchStates)
    }

    useEffect(() => {
        if (addressDatas !== null && typeof addressDatas === 'object') {
            const newFieldValues = {
                addressLineOne: addressDatas?.addressLineOne,
                addressLineTwo: addressDatas?.addressLineTwo,
                town: addressDatas?.town,
                country: addressDatas?.country,
                postCode: addressDatas?.postCode,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [addressDatas])

    const onPresonEditData = (): void => {
        void onSaveData()
    }

    const onSaveData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formdata = form.getFieldsValue()
            const dataToSave = {
                partnerId: editData.partnerId,
                partnershipId: editData.partnershipId,
                individualId: editData.individualId,
                tagIndividualId: editData.tagIndividualId,
                individualClientNumber: editData.individualClientNumber,
                title: formdata.title ?? '',
                firstName: formdata.firstName,
                middleName: formdata.middleName ?? '',
                surName: formdata.surName,
                dateOfBirth:
                    moment(formdata.dateOfBirth).format('DD/MM/YYYY') ?? '',
                partnerAddressOne: formdata.partnerAddressOne,
                partnerAddressTwo: formdata.partnerAddressTwo ?? '',
                partnerTown: formdata.partnerTown,
                partnerCountry: formdata.partnerCountry ?? '',
                partnerPostCode: formdata.partnerPostCode,
                mobileNumber: formdata.mobileNumber,
                homeNumber: formdata.homeNumber ?? '',
                workNumber: formdata.workNumber ?? '',
                emailAddress: formdata.emailAddress,
                alternativeEmailAddress: formdata.alternativeEmailAddress ?? '',
                individualUTR: formdata.individualUTR ?? '',
                niNumber: formdata.niNumber ?? '',
                isActive: 'Active',
                isDelete: 'Inactive',
            }

            await SavePartner(dispatch, dataToSave)
            saveCallBack(dataToSave)
            ReFetchData()
            form.resetFields()
        } catch (error) {}
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Business"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="2"
                                checked={switchStates[2]}
                                onChange={() => {
                                    handleSwitchChange(2)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Correspondence"
                            wrapperCol={{ span: 12 }}
                        >
                            <Switch
                                id="3"
                                checked={switchStates[3]}
                                onChange={() => {
                                    handleSwitchChange(3)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={[16, 24]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Date of Birth" name="dateOfBirth">
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    showTime
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 48]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item label="Middle Name" name="middleName">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Mobile Number"
                            name="mobileNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile number Should be 10-15 digit',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 48]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item
                            label="SurName"
                            name="surName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your SurName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Home Number"
                            name="homeNumber"
                            rules={[
                                {
                                    pattern: /^\d{14}$/,
                                    message: 'Home Number  Should be 14 digit',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 48]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item
                            label="Address 1"
                            name="partnerAddressOne"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Address One!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Work Number"
                            name="workNumber"
                            rules={[
                                {
                                    pattern: /^\d{14}$/,
                                    message: ' Work Number Should be 14 digit ',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 48]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item label="Address 2" name="partnerAddressTwo">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email Address"
                            name="emailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 48]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item
                            label="Town"
                            name="partnerTown"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Town!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Alternative Email Address"
                            name="alternativeEmailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'Please input your Alternative Email Address!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 48]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        <Form.Item label="Country" name="partnerCountry">
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country.label,
                                    label: country.label,
                                }))}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="NI Number" name="niNumber">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={[16, 48]}
                    className={commonStyles.officerButtonMargin}
                >
                    <Col span={12}>
                        {' '}
                        <Form.Item
                            label="PostCode"
                            name="partnerPostCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your PostCode!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Individual UTR" name="individualUTR">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) && (
                    <Row
                        gutter={[16, 48]}
                        className={commonStyles.officerButtonMargin}
                    >
                        <Col offset={21} span={2}>
                            <Button type="primary" onClick={onPresonEditData}>
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}
const mapStateToProps = (state: any): EditDataState => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(PartnerMemberEditForm)
