import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Space,
    DatePicker,
    Select,
    TimePicker,
    Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type {
    TimeSheetFormDataInterface,
    TimesheetFormInterface,
    TimesheetFormDataStateInterface,
} from '../../../Types/Timesheet'

import dayjs from 'dayjs'

import { GetUserList } from '../../../Services/User'

const TimeSheetForm = (props: TimesheetFormInterface): React.ReactElement => {
    const {
        onSave,
        editData,
        userList,
        resetdata,
        setResetData,
        isLoadingModal,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null)
    const [duration, setDuration] = useState<dayjs.Dayjs | null>(null)
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        form.setFieldValue('timeSheetId', '0')
    }, [form])

    useEffect(() => {
        if (resetdata === 'true') {
            resetForm()
        }
        setResetData('false')
    }, [resetdata])
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [dispatch])
    useEffect(() => {
        if (editData?.dateTime !== '') {
            setSelectedDate(dayjs(editData.dateTime))
        } else {
            setSelectedDate(null)
        }
        if (editData?.duration !== '') {
            setDuration(dayjs(editData.duration, 'HH:mm'))
        }
        form.setFieldsValue({
            ...editData,
            duration:
                (editData?.duration).length > 0
                    ? dayjs(editData.duration, 'HH:mm')
                    : null,
        })
    }, [editData, form])

    useEffect(() => {
        if (selectedDate != null) {
            form.setFieldValue('dateTime', selectedDate.format('DD/MM/YYYY'))
        }
    }, [selectedDate, form])

    const onChange = (date: dayjs.Dayjs | null): void => {
        setSelectedDate(date)
    }

    const onChange1 = (time: dayjs.Dayjs | null): void => {
        setDuration(time)
    }

    const hoursToMins = (time: string): string => {
        const [hours, mins] = time.split(':').map(Number)
        return (hours * 60 + mins).toString()
    }

    const resetForm = (): void => {
        form.resetFields()
        setSelectedDate(null)
    }

    const handleUserSelect = (userId: number): void => {}

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    const time =
                        data?.duration !== null
                            ? dayjs(data.duration.toISOString()).format('HH:mm')
                            : '00:00'
                    data.duration = hoursToMins(time)
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<TimeSheetFormDataInterface>
                    name="timeSheetId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<TimeSheetFormDataInterface>
                            label="Date"
                            name="dateTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Date',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={onChange}
                                    value={selectedDate}
                                    format="DD/MM/YYYY"
                                    className={commonStyles.dateWidth}
                                    inputReadOnly={true}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<TimeSheetFormDataInterface>
                            label="Task"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Task Name!',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<TimeSheetFormDataInterface>
                            label="Duration"
                            name="duration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Time',
                                },
                            ]}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                value={duration}
                                onChange={onChange1}
                                format="HH:mm"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<TimeSheetFormDataInterface>
                            label="Approver"
                            name="firstLevelReportingTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a user!',
                                },
                                {
                                    validator: async (_, value) => {
                                        if (value !== 0) {
                                            await Promise.resolve()
                                            return
                                        }
                                        return await Promise.reject(
                                            new Error('Please select a user!')
                                        )
                                    },
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Please select"
                                options={[
                                    { label: 'Please select', value: 0 },
                                    ...userList?.map((user) => ({
                                        value: user.value,
                                        label: user.label,
                                    })),
                                ]}
                                allowClear
                                onChange={handleUserSelect}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save Timesheet">
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={Boolean(isLoadingModal)}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): TimesheetFormDataStateInterface => {
    return {
        userList: state.user.userList,
    }
}
export default connect(mapStateToProps)(TimeSheetForm)
