import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Table, Space, Modal, Tooltip, Switch } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { connect, useDispatch } from 'react-redux'
import { type TableParamsInterface } from '../../../Types/CommonType'
import { UserAddOutlined } from '@ant-design/icons'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import FullWidthModal from '../../../Components/FullWidthModal'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import CreateNewClientForm from './newform'
import AddNewButton from '../../../Components/AddNewButton'
import {
    type clientcatergorynewlistStateInterface,
    type clientnewListDataTypeInterface,
    type cetetewclient,
} from '../../../Types/Client/RegisteredClients/registerClient'
import {
    ActiveInactivenewclient,
    DeletenewclientDetails,
    GetclientnewData,
    GetnewclientList,
    registerclient,
    SaveNew,
} from '../../../Services/Helpdesk/CreteTask'

export const clientsDefaultData: cetetewclient = {
    clientDynId: '0',
    clientCategoryname: '',
    clientCategoryId: 0,
    clientNo: '#',
    title: '',
    firstName: '',
    middleName: '',
    surName: '',
    emailAddress: '',
    mobileNo: '',
    idDocument: '',
    companyName: '',
    companyRegistrationNo: '',
    addressOne: '',
    addressTwo: '',
    town: '',
    postCode: '',
    country: '',
    referredBy: '',
    remarks: '',
    clientDynCategoryId: 0,
    isActive: '',
}

const newpage = (
    props: clientcatergorynewlistStateInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        creatnewclientList,
        creatnewclientListCount,
        siderClientID,
        clientParentCategoryDropDown,
    } = props

    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Create Adhoc')
    const [editdata, setEditData] = useState<cetetewclient>(clientsDefaultData)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [clientDynId, setotherCompanyId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce1 = useRef(false)
    const [restData, setResetData] = useState('false')
    const [buttonloading, setbuttonLoading] = useState(false)
    useEffect(() => {
        if (!hasFetchedOnce1.current) {
            hasFetchedOnce1.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetnewclientList(dispatch, siderClientID, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        siderClientID,
    ])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: creatnewclientListCount,
            },
        })
    }, [creatnewclientListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        window.scrollTo(0, 0)
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const handlePageSizeChange = (pageSize: number): void => {
        window.scrollTo(0, 0)
        setItemsPerPage(pageSize)
        setCurrentPage(1) // Reset to first page when page size changes
    }
    const pagination = calculatePagination(
        currentPage,
        creatnewclientListCount,
        itemsPerPage,
        handlePageSizeChange
    )
    const isClientOnboarding = (): boolean => {
        const category = clientParentCategoryDropDown.find(
            (item) =>
                item.clientDynCategoryId === siderClientID &&
                item.clientDynCategoryName === 'OnBoarding Clients'
        )
        return !(category == null)
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let clientDynId = ''
        if (!activeInactiveModalStatus) {
            clientDynId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setotherCompanyId(clientDynId)
    }
    const updateActiveInactiveclient = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactivenewclient(dispatch, clientDynId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const columns: ColumnsType<clientnewListDataTypeInterface> = [
        {
            title: 'Client No',
            dataIndex: 'clientNo',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
        },
        {
            title: 'Sur Name',
            dataIndex: 'surName',
        },
        {
            title: 'Email Address',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobileNo',
        },
        {
            title: 'Business Name',
            dataIndex: 'companyName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: clientnewListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit Client">
                            <EditIcon
                                className={commonStyles.clickableIcon}
                                onClick={() => {
                                    void openEditModal(record?.clientDynId)
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                record.isActive === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.isActive === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.clientDynId,
                                        record.isActive
                                    )
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete Client">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(
                                        record?.clientDynId.toString()
                                    )
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                        {isClientOnboarding() && (
                            <Tooltip title="Register Client">
                                <UserAddOutlined
                                    onClick={() => {
                                        void handleRegisterClient(
                                            record?.clientDynId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<clientnewListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }
    const handleRegisterClient = async (clientDynId: string): Promise<void> => {
        const result = await registerclient(dispatch, clientDynId)

        if (result !== null) {
            getTableData()
        } else {
            console.error('Failed to register client')
        }
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let clientDynId = ''
        if (!deleteModalStatus) {
            clientDynId = id
        }
        setotherCompanyId(clientDynId)
    }

    const deleteotherCompanyId = (): void => {
        DeletenewclientDetails(dispatch, clientDynId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = async (id: string): Promise<void> => {
        let data = clientsDefaultData

        if (id !== '') {
            try {
                const result = await GetclientnewData(dispatch, id)

                if (result !== null) {
                    data = result
                }
            } catch (error) {
                console.error('Failed to fetch client details:', error)
            }
        }

        const title = id !== '' ? 'Edit Client' : 'Create Client'
        setEditModalTittle(title)
        setEditData(data)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const saveDepartmentData = async (
        data: cetetewclient,
        callBack: CallableFunction
    ): Promise<void> => {
        setbuttonLoading(true)
        await SaveNew(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    setCreateEditModalStatus(false)
                    getTableData()
                    callBack()
                    setbuttonLoading(false)
                }
            })
            .catch(() => {})
    }
    const onSaveNew = (
        data: cetetewclient,
        callBack: CallableFunction,
        siderClientID: number
    ): void => {
        data.clientDynCategoryId = siderClientID
        const datasave = {
            clientDynId: data.clientDynId ?? 0,
            clientCategoryname: data.clientCategoryname ?? '',
            clientCategoryId: data.clientDynCategoryId ?? 0,
            clientNo: data.clientNo ?? '#',
            title: data.title ?? '',
            firstName: data.firstName ?? '',
            middleName: data.middleName ?? '',
            surName: data.surName ?? '',
            emailAddress: data.emailAddress ?? '',
            mobileNo: data.mobileNo ?? '',
            idDocument: data.idDocument ?? '',
            companyName: data.companyName ?? '',
            companyRegistrationNo: data.companyRegistrationNo ?? '',
            addressOne: data.addressOne ?? '',
            addressTwo: data.addressTwo ?? '',
            town: data.town ?? '',
            postCode: data.postCode ?? '',
            country: data.country ?? '',
            referredBy: data.referredBy ?? '',
            remarks: data.remarks ?? '',
            clientDynCategoryId: siderClientID,
            isActive: data.isActive ?? 'Active',
        }
        void saveDepartmentData(datasave, callBack)
    }

    const onSaveNewHandler = (
        data: cetetewclient,
        callBack: CallableFunction
    ): void => {
        const mergedData = {
            ...clientsDefaultData,
            ...data,
        }
        onSaveNew(mergedData, callBack, siderClientID)
    }

    return (
        <>
            <Card
                title={
                    isClientOnboarding()
                        ? 'OnBoarding Clients'
                        : 'Registered Clients'
                }
                extra={
                    <>
                        {isClientOnboarding() && (
                            <AddNewButton
                                name="Create Client"
                                clickAction={() => {
                                    void openEditModal('')
                                    setResetData('true')
                                }}
                            />
                        )}
                    </>
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Clients by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />

                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={creatnewclientList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Clients Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteotherCompanyId}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Client. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveclient}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <CreateNewClientForm
                        onSave={onSaveNewHandler}
                        editData={editdata}
                        resetdata={restData}
                        setResetData={setResetData}
                        isLoadingModal={buttonloading}
                    />
                </Modal>
            </Card>
        </>
    )
}
const mapStateToProps = (state: any): clientcatergorynewlistStateInterface => {
    return {
        creatnewclientList: state.helpdesk.creatnewclientList,
        creatnewclientListCount: state.helpdesk.creatnewclientListCount,
        siderClientID: state.dashboardFlag.siderClientID,
        clientParentCategoryDropDown: state.initial.clientParentCategory,
    }
}

export default connect(mapStateToProps)(newpage)
