import React, { useEffect } from 'react'
import { Form, Input, Button, Select, Row, Col } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import CoporateTabsless from './ContactInfo.less'
import {
    type ContactEditFormInterface,
    type ContactInfoData,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const ContactInfoEditForm = (
    props: ContactEditFormInterface
): React.ReactElement => {
    const { editData, partnershipId, onSave } = props
    const [form] = Form.useForm()

    useEffect(() => {
        if (editData !== null) {
            form.setFieldsValue(editData)
        }
    }, [editData])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const onSaveData = (): void => {
        void onSaveDataContactdata()
    }

    const onSaveDataContactdata = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const dataToSave = {
                ...formData,
                registerid: partnershipId,
                registertype: 'PARTNERSHIP',
            }
            onSave(dataToSave)
        } catch (error) {}
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<ContactInfoData>
                    name="contactpersonid"
                    hidden={true}
                >
                    <Input defaultValue={''} />
                </Form.Item>

                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoData> label="Partner ">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={''}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Mobile Number"
                            name="mobilenumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Mobile Number!',
                                },
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoData> label="Title " name="title">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={''}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                {' '}
                                <Select.Option value="UNKNOWN">
                                    Unknown
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Home Number"
                            name="homenumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="First Name"
                            name="firstname"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    pattern: /^[a-zA-Z]+$/,
                                    message:
                                        'First Name cannot be a number or contain numbers!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Work Number "
                            name="worknumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Mobile Number must be 10-15 digits!',
                                },
                            ]}
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Middle Name"
                            name="middlename"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Email Address"
                            name="emailaddress"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'The input is not a valid Email!',
                                    pattern:
                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Sure Name "
                            name="surname"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Alternative Email"
                            name="altemailaddress"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={CoporateTabsless.rowform}>
                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Prefered Name "
                            name="preferredname"
                        >
                            <Input defaultValue={''} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item<ContactInfoData>
                            label="Prefered Language"
                            name="preferredlanguage"
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                defaultValue={''}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option value={0}>
                                    Please Select
                                </Select.Option>
                                <Select.Option value={1}>English</Select.Option>
                                <Select.Option value={2}>Tamil</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) ? (
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Button type="primary" onClick={onSaveData}>
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </div>
    )
}

export default ContactInfoEditForm
