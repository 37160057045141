export const formatdate = (date: string | undefined): any => {
    if (date != null) {
        const dateObject = new Date(date)
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()
        const formattedDate = `${day}/${month}/${year}`
        return formattedDate
    }
}

export const formatdatewithMonth = (date: string | undefined): any => {
    if (date != null) {
        // Replace with your timestamp
        const d = new Date(date)
        const formattedDate = `${d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        })}`

        return formattedDate
    }
}

export const formatDateFromISO = (isoDateStr: string): string => {
    const date = new Date(isoDateStr) // Parse the ISO date string into a Date object

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    }

    return date.toLocaleDateString('en-US', options)
}

export const ConvertTime = (minutes: any): string => {
    const hours = Math.floor(minutes / 60)
        .toString()
        .padStart(2, '0')
    const remainingMinutes = (minutes % 60).toString().padStart(2, '0')
    return `${hours} : ${remainingMinutes}`
}

export const convertTo12HourFormat = (time: string): string => {
    // Remove extra spaces and make the input consistent
    const trimmedTime = time.replace(/\s+/g, '').toUpperCase() // e.g., "14:00 PM" -> "14:00PM"

    // Extract hours, minutes, and optional period (AM/PM)
    const match = trimmedTime.match(/^(\d{1,2}):(\d{2})(AM|PM)?$/)
    if (match !== null) {
        const [, hoursStr, minutes, period] = match
        let hours = parseInt(hoursStr, 10)

        const x = hours % 12
        // Handle 24-hour format when there's no AM/PM period
        if (period.length !== 0) {
            // Convert hours to 12-hour format
            const adjustedPeriod = hours >= 12 ? 'PM' : 'AM'
            const hoursIn12HourFormat = x ?? 12 // 12-hour format
            return `${hoursIn12HourFormat}:${minutes} ${adjustedPeriod}`
        }

        // If period exists (e.g., AM/PM), adjust the hour accordingly
        if (period === 'PM' && hours < 12) {
            hours += 12 // Convert PM hours to 24-hour format
        } else if (period === 'AM' && hours === 12) {
            hours = 0 // Convert 12 AM to 0
        }

        // Convert hours back to 12-hour format for output
        const adjustedPeriod = hours >= 12 ? 'PM' : 'AM'
        const hoursIn12HourFormat = x ?? 12

        // Format the time as "h:mm AM/PM"
        return `${hoursIn12HourFormat}:${minutes} ${adjustedPeriod}`
    } else {
        return ''
    }
}
