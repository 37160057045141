/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import {
    Col,
    Row,
    Form,
    Button,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
    Card,
    Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CloudDownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
    type StatusReporData,
    type InvoiceReportsInterface,
} from '../../../Types/Invoicing/InvoiceReports'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetRegisteClientDetails } from '../../../Services/Common'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import { GetInvoiceTypeReporDetailsList } from '../../../Services/Invoicing/InvoiceReport'
import jsPDF from 'jspdf'
import hederimage from '../../../Assest/Svg/pattern-2.svg'
import { WarningMessage } from '../../../Utils/Notification'
const InvoiceTypeReport = (
    props: InvoiceReportsInterface
): React.ReactElement => {
    const { categoryDropDownList, serviceDropDownList, regiterClientDetails } =
        props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selectedToDate, setSelectedToDate] = useState<string>('')
    const [selectedSatusId, setSelectedStatusId] = useState(1)
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const [selectedCompanyGroupId, setSelectedCompanyGroupsId] = useState(0)
    const [selectedBusinessId, setSelectedBusinessId] = useState(0)
    const hasFetchedOnce = useRef(false)
    const [availableData, setAvailableData] = useState(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetCategoryDropDownList(dispatch)
        GetRegisteClientDetails(dispatch)
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const handleSelectChangeStatus = (value: number): void => {
        setSelectedStatusId(value)
        // You can also perform other actions based on the selected value here
    }

    const handleSelectChangeService = (value: number): void => {
        setSelectedServiceId(value)
    }

    const handleSelectChangeCompanyGroup = (value: number): void => {
        setSelectedCompanyGroupsId(value)
    }

    const handleSelectChangeBusinesType = (value: number): void => {
        setSelectedBusinessId(value)
    }

    const onChange = (date: DatePickerProps['value']): void => {
        if (date) {
            form.setFieldsValue({ fromDate: date })
            setSelectedDate(date.format('DD-MM-YYYY'))
        }
    }

    const onChangeTodate = (date: DatePickerProps['value']): void => {
        if (date) {
            form.setFieldsValue({ toDate: date })
            setSelectedToDate(date.format('DD-MM-YYYY'))
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const statusDropdownList = [
        { value: 1, label: 'TO BE RAISED' },
        { value: 2, label: 'DD' },
        { value: 3, label: 'NO NEED' },
        { value: 4, label: 'PAID BY CASH' },
        { value: 5, label: 'PAID BY BANK' },
    ]

    const getCompanyGroupLabel = (id: number): string => {
        if (id === 0) return 'All'
        const group = categoryDropDownList.find((item) => item.value === id)
        return group != null ? group.label : id.toString()
    }

    const disabledToDate = (current: dayjs.Dayjs): boolean => {
        const fromDate = form.getFieldValue('fromDate')
        return fromDate
            ? current && current.isBefore(dayjs(fromDate), 'day')
            : false
    }
    const getTableData = async (): Promise<void> => {
        try {
            setAvailableData(false)
            await form.validateFields()
            const formattedSelectedDate = selectedDate
                ? dayjs(selectedDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
                : ''
            const formattedSelectedToDate = selectedToDate
                ? dayjs(selectedToDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
                : ''

            const datatoPost = {
                fromDate: formattedSelectedDate,
                toDate: formattedSelectedToDate,
            }
            const reportData: StatusReporData =
                await GetInvoiceTypeReporDetailsList(
                    dispatch,
                    selectedCompanyGroupId,
                    selectedBusinessId,
                    selectedSatusId,
                    selectedServiceId,

                    datatoPost
                )
            if (
                reportData?.invoiceStatusList !== null &&
                reportData?.invoiceStatusList !== undefined &&
                reportData?.invoiceStatusList.length > 0
            ) {
                handleDownloadPDF(reportData)
            } else {
                WarningMessage('There are no data availble for dwonload')
                setAvailableData(true)
            }
        } catch (error) {
            console.error('Error in getData:', error)
        }
    }
    const handleDownloadPDF = (data: any): void => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        const missingCompanyCategoryLabel = getCompanyGroupLabel(
            selectedCompanyGroupId
        )

        const headerColor: [number, number, number] = [0, 51, 153]
        doc.setTextColor(headerColor[0], headerColor[1], headerColor[2])
        const headerFontSize = 18
        const subHeaderFontSize = 20
        const addressFontSize = 10
        const categoryFontSize = 10
        const img = new Image()
        img.src = hederimage

        img.onload = function () {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const dataUrl = canvas.toDataURL('image/png')
            const logo = data?.businessDocumentTemplate?.companyLogo
            const marginTop = 5
            const marginLeftRight = 10
            const imageWidth =
                doc.internal.pageSize.getWidth() - 2 * marginLeftRight

            doc.addImage(
                dataUrl,
                'PNG',
                marginLeftRight,
                marginTop,
                imageWidth,
                5
            )

            const logoWidth = 50
            const logoHeight = 10
            const logoXPosition =
                doc.internal.pageSize.getWidth() - logoWidth - 10
            const logoYPosition = marginTop + 10

            if (logo) {
                doc.addImage(
                    logo,
                    'PNG',
                    logoXPosition,
                    logoYPosition,
                    logoWidth,
                    logoHeight
                )
            }

            const textXPosition = 14
            const textYPosition = logoYPosition + marginTop

            doc.setFontSize(headerFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text(missingCompanyCategoryLabel, textXPosition, textYPosition)

            const addressYPosition = textYPosition + 5
            doc.setFontSize(addressFontSize)
            doc.setTextColor(160, 160, 160)
            doc.text(
                '14 Elm Road, Chessington, KT9 1AW, UNITED KINGDOM',
                textXPosition,
                addressYPosition
            )

            const subHeaderYPosition = addressYPosition + 10
            doc.setFontSize(subHeaderFontSize)
            doc.setTextColor(0, 51, 153)
            doc.text(
                'Invoice list by action status Reports',
                textXPosition,
                subHeaderYPosition
            )

            const lineStartX =
                textXPosition +
                doc.getTextWidth('Invoice list by action status Reports') +
                5
            const lineEndX = lineStartX + 50
            const lineY = subHeaderYPosition
            doc.setDrawColor(0, 51, 153)
            doc.setLineWidth(1)
            doc.line(lineStartX, lineY, lineEndX, lineY)

            doc.setFontSize(categoryFontSize)
            doc.setTextColor(0, 0, 0)
            const dateRangeYPosition = lineY + 10
            const dateRangeText = `From Date :- ${selectedDate} To Date:- ${selectedToDate} Status-${selectedSatusId}`
            doc.text(dateRangeText, textXPosition, dateRangeYPosition)

            // Remaining code...

            const headers = [
                [
                    {
                        content: 'Number',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Client',
                        styles: {
                            halign: 'left',
                            cellWidth: 'wrap',
                        },
                    },
                    {
                        content: 'Service',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Invoice Date',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                    {
                        content: 'Net Total',
                        styles: { halign: 'left', cellWidth: 'wrap' },
                    },
                ],
            ]

            const tableData = data?.invoiceStatusList?.map((item: any) => {
                // Attempt to parse the date with multiple formats
                const possibleFormats = [
                    'MMM DD YYYY h:mmA',
                    'MMM D YYYY h:mmA',
                ]
                let parsedDate = dayjs(item.invoiceDate, possibleFormats[0])

                if (!parsedDate.isValid()) {
                    parsedDate = dayjs(item.invoiceDate, possibleFormats[1])
                }

                const formattedDate = parsedDate.isValid()
                    ? parsedDate.format('MMM DD YYYY') // e.g., "Aug 11 2022"
                    : item.invoiceDate // fallback in case of parsing error
                let dateInfo = ''
                if (item.serviceName === 'AA') {
                    dateInfo = `Next accounts made up to ${item.periodStartDate} due by 21/05/2021 in 2020`
                } else {
                    dateInfo = 'Period of 19/05/2021 to 19/05/2021 in 2021'
                }
                return [
                    item.clientNumber,
                    `${item.clientName}\n${dateInfo}`,
                    item.serviceName,
                    formattedDate, // Use the formatted date here
                    0.0,
                ]
            })
            doc.autoTable({
                head: headers,
                body: tableData,
                startY: lineY + 20,
                theme: 'grid',
                styles: {
                    halign: 'left',
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineColor: [160, 160, 160],
                    lineWidth: 0.05,
                    fontSize: 8,
                },
                bodyStyles: {
                    lineColor: [160, 160, 160],
                    lineWidth: 0.5,
                    fontSize: 8,
                },
                columnStyles: {
                    1: { cellWidth: 78 },
                },
            })

            const today = new Date()
            const formattedDate = `${
                today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}`
            const totalPages = doc.getNumberOfPages()

            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i)
                doc.setFontSize(10)

                const footerText = `Printed on: ${formattedDate} ${i}/${totalPages} Total Count: ${tableData?.length}`
                const textWidth = doc.getTextWidth(footerText)
                const pageWidth = doc.internal.pageSize.getWidth()
                const xPosition = (pageWidth - textWidth) / 2
                const yPosition = doc.internal.pageSize.getHeight() - 10

                doc.text(footerText, xPosition, yPosition)
            }

            doc.save('Missing_Info_report.pdf')
        }
    }

    return (
        <>
            <Card
                title="Invoice list by action status Reports"
                style={{ marginTop: '20px' }}
            >
                <div className={commonStyles.formWrapper}>
                    <Form
                        name="complex-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        layout={'vertical'}
                        autoComplete="off"
                        form={form}
                        requiredMark={customizeRequiredMark}
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    label="From Date"
                                    name="fromDate"
                                    rules={[{ required: true }]}
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={onChange}
                                            value={form.getFieldValue(
                                                'fromDate'
                                            )}
                                            className={commonStyles.dateWidth}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="To Date"
                                    name="toDate"
                                    rules={[{ required: true }]}
                                >
                                    <Space direction="vertical" size={12}>
                                        <DatePicker
                                            onChange={onChangeTodate}
                                            value={form.getFieldValue('toDate')}
                                            className={commonStyles.dateWidth}
                                            disabledDate={disabledToDate}
                                        />
                                    </Space>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Service">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeService}
                                        value={selectedServiceId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...serviceDropDownList,
                                        ]}
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Company Group">
                                    <Select
                                        placeholder="Please select"
                                        onChange={
                                            handleSelectChangeCompanyGroup
                                        }
                                        value={selectedCompanyGroupId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...categoryDropDownList,
                                        ]}
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Business Type">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeBusinesType}
                                        value={selectedBusinessId}
                                        options={[
                                            { value: 0, label: 'All' },
                                            ...regiterClientDetails,
                                        ]}
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Invoice Status">
                                    <Select
                                        placeholder="Please select"
                                        onChange={handleSelectChangeStatus}
                                        value={selectedSatusId}
                                        options={statusDropdownList}
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            !(option == null) &&
                                            option.label
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col offset={21} span={2}>
                                <Tooltip title="Download Report">
                                    <Button
                                        type="primary"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={getTableData}
                                    >
                                        <CloudDownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            {availableData && (
                                <div>
                                    <h4>
                                        There are no data availble for dwonload
                                    </h4>
                                </div>
                            )}
                        </Row>
                    </Form>
                </div>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): InvoiceReportsInterface => {
    return {
        parentCategoryList: state.initial.parentCategoryList,
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
        regiterClientDetails: state.common.regiterClientDetails,
        invoiceStatusReportList: state.invoice.invoiceStatusReportList,
    }
}

export default connect(mapStateToProps)(InvoiceTypeReport)
