import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Avatar,
    Card,
    Drawer,
    Dropdown,
    Flex,
    Menu,
    Modal,
    Spin,
    Tooltip,
} from 'antd'
import TodoStyles from './createaskcss.less'
import {
    FormOutlined,
    UsergroupDeleteOutlined,
    FieldTimeOutlined,
    UnorderedListOutlined,
    PaperClipOutlined,
    WechatOutlined,
} from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { getusersdroplist } from '../../../Services/Helpdesk/Backlog'
import {
    type inprogressForminterface,
    type inprogressSatteInterface,
    type TaskData,
    type Task,
    type User,
    type setTasks,
} from '../../../Types/HelpDesk/Cretetask'
import {
    GetSatusList,
    GetInprogressTaskList,
    Saveprojectstaus,
    Saveuserstaus,
    GetTaskData,
} from '../../../Services/Helpdesk/CreteTask'
import CreateTaskForm from './CreateTaskForm'
import CreateFollowersModal from './CreateFoolwers'
import CreateHours from './CreateHours'
import CreateattachmentModal from './Attachment'

import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { type TableParamsInterface } from '../../../Types/CommonType'
import { type ProjectManagmentStausListDataTypeInterface } from '../../../Types/ProjectMangement'
import ChatClient from './chatClient'
import CreatesubtaskModal from './Tabs'
import { TaskdefaultData } from './toDoTasks'

export const TaskItemDefaultData = {
    taskId: '',
    taskNo: 0,
    taskName: '',
    priorityName: '',
    priorityColourCode: '',
    taskTypeName: '',
    sortOrder: 0,
    businessTypeName: '',
    clientName: '',
    serviceName: '',
    description: '',
    assignedByName: '',
    assignedToName: '',
    fromDate: '',
    toDate: '',
    daysCount: 0,
    daysColourCode: '',
    status: '',
    statusColourCode: '',
    notifyToStatusChange: 0,
    subTaskViews: [],
    taskFollowerViews: [],
    subTaskStatus: 0,
    isNewChatExist: 0,
    projectTierInfo: '',
    projectStatusId: 0,
    projectStatusLbl: '',
    userStatusId: 0,
    userStatusLbl: '',
}

const InprogressTasks = (
    props: inprogressForminterface & { activeStates: string }
): React.ReactElement => {
    const dispatch = useDispatch()
    const [modalTitleCreate, setModalTitleCreate] = useState('Add Dates')
    const [modalVisibleCreate, setModalVisibleCreate] = useState(false)
    const [modalVisibleCreateHours, setModalVisibleCreateHours] =
        useState(false)
    const [selectedTaskId, setSelectedTaskId] = useState<string>('')
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [, setSelectedStatusProjectId] = useState<string>('')
    const [modalVisibleCreateAttachment, setModalVisibleCreateAttachment] =
        useState(false)
    const [modalVisibleCreateFollowers, setModalVisibleCreateFollowers] =
        useState(false)
    const [modalVisibleCreateSubTask, setModalVisibleCreateSubTask] =
        useState(false)
    const [attachmentTaskId, setAttachmentTaskId] = useState('')
    const [editData, setEditData] = useState<setTasks>(TaskdefaultData)
    const [tableParams] = useState<TableParamsInterface>(commonTableParams)
    const [isLoading, setLoading] = useState(false)
    const [uerStatusfilter, setUerStatusfilter] = useState(2)
    const [modalVisibleCreateChat, setModalVisibleCreateChat] = useState(false)
    const [getTask, setTask] = useState<Task>(TaskItemDefaultData)
    const [referenceId, setRefernceId] = useState(0)
    const [businessId, setBusinessId] = useState(0)
    const {
        ProjectManagmentStausListInprogress,
        userimageList,
        ProjectManagmentStausList,
        setSelectedTaskIds,
        selectedProjectID,
        AssignBY,
        activeStates,
        activeTab,
        AssignTo,
        searchString,
        createTaskModalStatus,
    } = props

    const [selectedUsers, setSelectedUsers] = useState<number[]>([])
    // const [filterProjectStatusID, setFilterProjectStatusID] = useState<
    //     string | undefined
    // >('0')
    const [idChange, setIDchange] = useState('')
    const hasFetchedOnce = useRef(false)
    const hasFetchedOnce2 = useRef(false)
    const hasFetchedOnce3 = useRef(false)
    const hasFetchedOnce4 = useRef(false)
    const [restData, setResetData] = useState('false')
    const [inprogressDataList, setInprogressDatalist] = useState<TaskData[]>([])
    useEffect(() => {
        if (!hasFetchedOnce3.current) {
            hasFetchedOnce3.current = true
            return
        }
        if (selectedProjectID === 0) {
            setUerStatusfilter(2)
            GetInprogressTaskList(
                dispatch,
                '0',
                searchString,
                1,
                100,
                AssignBY ?? 0,
                AssignTo ?? 0
            )
        }
    }, [
        AssignTo,
        AssignBY,
        selectedProjectID,
        searchString,
        createTaskModalStatus,
        idChange,
        activeStates,
    ])

    const getTableDatas = useCallback(() => {
        let projectStatusName = ''

        if (activeTab === '1') {
            projectStatusName = 'In-Progress'
        } else if (activeTab === '3') {
            projectStatusName = 'QA'
        }

        const todoProject = ProjectManagmentStausList.find(
            (project) => project.projectStatusName === projectStatusName
        )

        const id = todoProject?.projectStatusId

        // if (id !== filterProjectStatusID) {
        //     setFilterProjectStatusID(id?.toString())
        // }

        if (id !== undefined && selectedProjectID !== 0) {
            setUerStatusfilter(2)
            GetInprogressTaskList(
                dispatch,
                id?.toString(),
                searchString,
                1,
                10,
                AssignBY ?? 0,
                AssignTo ?? 0
            )
        } else {
            if (selectedProjectID !== 0) {
                setUerStatusfilter(10)
            }
        }
    }, [
        ProjectManagmentStausList,
        selectedProjectID,
        idChange,
        activeStates,
        activeTab,
        AssignBY,
        AssignTo,
        searchString,
    ])
    useEffect(() => {
        if (!hasFetchedOnce4.current) {
            hasFetchedOnce4.current = true
            return
        }
        getTableDatas()
    }, [getTableDatas])
    useEffect(() => {
        if (activeTab !== '2') {
            const projectStatusLbl = activeTab === '3' ? 'QA' : 'In-Progress'
            const InprogressData = ProjectManagmentStausListInprogress.filter(
                (data) =>
                    data.projectStatusLbl === projectStatusLbl &&
                    data.userStatusLbl === 'In-Progress'
            )

            setInprogressDatalist(InprogressData)
        }
    }, [ProjectManagmentStausListInprogress, activeTab])
    const handleModalOpenCreate = (taskId: string): void => {
        setModalVisibleCreate(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Edit Task')
    }

    const handleModalOpenCreateHours = (taskId: string): void => {
        setModalVisibleCreateHours(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Hours')
    }

    const handleModalOpenCreateFollowers = (taskId: string): void => {
        setModalVisibleCreateFollowers(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Followers')
    }

    const handleModalOpenSubTasks = (task: Task): void => {
        setModalVisibleCreateSubTask(true)
        setAttachmentTaskId(task.taskId)
        setTask(task)
        setModalTitleCreate('Add SubTask')
    }

    const onAttachment = (taskId: string): void => {
        setModalVisibleCreateAttachment(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Attachment')
    }

    const onChat = (
        taskId: string,
        referenceId: number,
        businessId: number
    ): void => {
        setRefernceId(referenceId)
        setBusinessId(businessId)
        setModalVisibleCreateChat(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Staff Communication')
    }

    const handleModalClose = (): void => {
        setModalVisibleCreate(false)
        setModalVisibleCreateAttachment(false)
        setModalVisibleCreateHours(false)
        setModalVisibleCreateFollowers(false)
        setModalVisibleCreateSubTask(false)
        setModalVisibleCreateChat(false)
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveTask = (callBack: CallableFunction): void => {
        changeCreateEditModalStatus()
        callBack()
    }

    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        void getusersdroplist(dispatch)
    }, [])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetSatusList(dispatch, selectedProjectID.toString(), {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [selectedProjectID])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleAssignByMenuClick = (
        taskId: string,
        statusProjectId: string
    ): void => {
        setSelectedTaskId(taskId)
        setSelectedStatusProjectId(statusProjectId)
    }

    const todoProjectStatusId = (
        name: string | undefined
    ): string | undefined => {
        const todoProject = ProjectManagmentStausList.find(
            (project: ProjectManagmentStausListDataTypeInterface) =>
                project.projectStatusName === name
        )
        const todoProjectId = todoProject?.projectStatusId.toString()

        return todoProjectId
    }
    const handleProgressChange = async (
        value: number,
        projectStatusName: string
    ): Promise<void> => {
        try {
            setLoading(true)
            const statusId = todoProjectStatusId(projectStatusName)
            let StatusIDS = 0

            if (statusId !== undefined) {
                StatusIDS = Number(statusId)
            } else {
                StatusIDS = 0
            }
            await Saveuserstaus(dispatch, selectedTaskId, value.toString())
            await Saveprojectstaus(
                dispatch,
                selectedTaskId,
                Number(StatusIDS),
                projectStatusName
            )

            setIDchange(selectedTaskId)
            setSelectedTaskIds(selectedTaskId)
            getTableDatas()
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }
    const openEditModal = (id: string): void => {
        void openEditData(id)
    }
    const openEditData = async (id: string): Promise<void> => {
        // const data = ProjectManagmentStausListInprogress.find(
        //     (data: TaskData) => {
        //         return data.taskId === id
        //     }
        // )
        try {
            const data = await GetTaskData(dispatch, id)
            setEditData(data ?? TaskdefaultData)
        } catch (err) {}
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                {inprogressDataList.length === 0 ? (
                    <Card className={TodoStyles.todoCard} title="IN-PROGRESS">
                        <div>No records available</div>
                    </Card>
                ) : (
                    <Card className={TodoStyles.todoCard} title="IN-PROGRESS">
                        {inprogressDataList
                            .filter(
                                (task) => task.userStatusId === uerStatusfilter
                            )
                            .map((task: TaskData) => (
                                <Card
                                    key={task.taskId}
                                    className={TodoStyles.todoItem}
                                >
                                    <div className={TodoStyles.tasknameStyles}>
                                        <div>
                                            <strong>{task.taskName}</strong>
                                            {task.clientName !== '' && (
                                                <span>({task.clientName})</span>
                                            )}
                                        </div>
                                        <div
                                            className={TodoStyles.taskAndColor}
                                        >
                                            <div>
                                                <Dropdown
                                                    overlay={
                                                        <Menu
                                                            onClick={({
                                                                key,
                                                            }) => {
                                                                handleAssignByMenuClick(
                                                                    task.taskId,
                                                                    key
                                                                )
                                                            }}
                                                        >
                                                            {activeTab ===
                                                            '1' ? (
                                                                <>
                                                                    <Menu.Item
                                                                        key="todo"
                                                                        onClick={() => {
                                                                            void handleProgressChange(
                                                                                1,
                                                                                'To-Do'
                                                                            )
                                                                        }}
                                                                    >
                                                                        To Do
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        key="inprogress"
                                                                        onClick={() => {
                                                                            void handleProgressChange(
                                                                                2,
                                                                                'In-Progress'
                                                                            )
                                                                        }}
                                                                    >
                                                                        In
                                                                        Progress
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        key="completed"
                                                                        onClick={() => {
                                                                            void handleProgressChange(
                                                                                3,
                                                                                'Complete'
                                                                            )
                                                                        }}
                                                                    >
                                                                        Complete
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        key="QA"
                                                                        onClick={() => {
                                                                            void handleProgressChange(
                                                                                1,
                                                                                'QA'
                                                                            )
                                                                        }}
                                                                    >
                                                                        QA
                                                                    </Menu.Item>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Menu.Item
                                                                        key="todo"
                                                                        onClick={() => {
                                                                            void handleProgressChange(
                                                                                1,
                                                                                'QA'
                                                                            )
                                                                        }}
                                                                    >
                                                                        To Do
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        key="inprogress"
                                                                        onClick={() => {
                                                                            void handleProgressChange(
                                                                                2,
                                                                                'QA'
                                                                            )
                                                                        }}
                                                                    >
                                                                        In
                                                                        Progress
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        key="completed"
                                                                        onClick={() => {
                                                                            void handleProgressChange(
                                                                                3,
                                                                                'Complete'
                                                                            )
                                                                        }}
                                                                    >
                                                                        Complete
                                                                    </Menu.Item>
                                                                </>
                                                            )}
                                                        </Menu>
                                                    }
                                                    visible={
                                                        selectedTaskId ===
                                                        task.taskId
                                                    }
                                                    onVisibleChange={(
                                                        visible
                                                    ) => {
                                                        if (!visible) {
                                                            setSelectedTaskId(
                                                                ''
                                                            )
                                                            setSelectedStatusProjectId(
                                                                ''
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <a
                                                        className="ant-dropdown-link"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setSelectedTaskId(
                                                                task.taskId
                                                            )
                                                        }}
                                                    >
                                                        IN-PROGRESS
                                                    </a>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className={TodoStyles.circle}
                                                style={{
                                                    backgroundColor:
                                                        task.daysColourCode,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <Flex justify="space-between">
                                        <div
                                            className={TodoStyles.iconContainer}
                                        >
                                            <Tooltip title="Edit Task">
                                                <FormOutlined
                                                    className={
                                                        TodoStyles.todoIcons
                                                    }
                                                    onClick={() => {
                                                        handleModalOpenCreate(
                                                            task.taskId
                                                        )
                                                        openEditModal(
                                                            task.taskId
                                                        )
                                                        setResetData('false')
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Add Followers">
                                                <UsergroupDeleteOutlined
                                                    className={
                                                        TodoStyles.todoIcons
                                                    }
                                                    onClick={() => {
                                                        handleModalOpenCreateFollowers(
                                                            task.taskId
                                                        )
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Add Hours">
                                                <FieldTimeOutlined
                                                    className={
                                                        TodoStyles.todoIcons
                                                    }
                                                    onClick={() => {
                                                        handleModalOpenCreateHours(
                                                            task.taskId
                                                        )
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Add subtask">
                                                <UnorderedListOutlined
                                                    className={
                                                        TodoStyles.todoIcons
                                                    }
                                                    onClick={() => {
                                                        handleModalOpenSubTasks(
                                                            task
                                                        )
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Add Attachment">
                                                <PaperClipOutlined
                                                    className={
                                                        TodoStyles.todoIcons
                                                    }
                                                    onClick={() => {
                                                        onAttachment(
                                                            task.taskId
                                                        )
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Staff Communication">
                                                <WechatOutlined
                                                    className={
                                                        TodoStyles.todoIcons
                                                    }
                                                    onClick={() => {
                                                        onChat(
                                                            task.taskId,
                                                            task.referenceId,
                                                            task.businessTypeId
                                                        )
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                        <div>
                                            {userimageList.map((user: User) => {
                                                if (
                                                    user.userName ===
                                                    task.assignedByName
                                                ) {
                                                    return (
                                                        <div
                                                            key={task.taskId}
                                                            className={
                                                                TodoStyles.todoAssignAvatar
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    TodoStyles.todotaskassignedname
                                                                }
                                                            >
                                                                {
                                                                    task.assignedByName
                                                                }
                                                            </div>
                                                            <Avatar
                                                                key={
                                                                    user.userName
                                                                }
                                                                size={30}
                                                                src={
                                                                    user.imageUrl
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })}

                                            {userimageList.map((user: User) => {
                                                if (
                                                    user.userName ===
                                                    task.assignedToName
                                                ) {
                                                    return (
                                                        <div
                                                            key={task.taskId}
                                                            className={
                                                                TodoStyles.todoAssignAvatar
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    TodoStyles.todotaskassignedname
                                                                }
                                                            >
                                                                {
                                                                    task.assignedToName
                                                                }
                                                            </div>
                                                            <Avatar
                                                                key={
                                                                    user.userName
                                                                }
                                                                size={30}
                                                                src={
                                                                    user.imageUrl
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })}
                                        </div>
                                    </Flex>
                                </Card>
                            ))}
                    </Card>
                )}
            </Spin>
            <Modal
                title={modalTitleCreate}
                open={modalVisibleCreate}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <CreateTaskForm
                    taskId={attachmentTaskId.toString()}
                    onSave={onSaveTask}
                    editData={editData}
                    setResetData={setResetData}
                    resetdata={restData}
                />
            </Modal>
            <Modal
                title={modalTitleCreate}
                open={modalVisibleCreateAttachment}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <CreateattachmentModal taskId={attachmentTaskId} />
            </Modal>
            <Modal
                title={modalTitleCreate}
                open={modalVisibleCreateHours}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <CreateHours taskid={attachmentTaskId} />
            </Modal>
            <Modal
                title={modalTitleCreate}
                open={modalVisibleCreateFollowers}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <CreateFollowersModal
                    onSave={onSaveTask}
                    taskid={attachmentTaskId.toString()}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                />
            </Modal>
            <Modal
                title={modalTitleCreate}
                open={modalVisibleCreateSubTask}
                onCancel={handleModalClose}
                footer={null}
                width={'50%'}
            >
                <CreatesubtaskModal
                    taskId={attachmentTaskId}
                    getTask={getTask}
                />
            </Modal>
            <Drawer
                title={
                    <span style={{ color: 'white' }}>{modalTitleCreate}</span>
                }
                open={modalVisibleCreateChat}
                onClose={handleModalClose}
                footer={null}
                width={550}
                styles={{
                    header: {
                        backgroundColor: '#085394',
                        padding: '20px',
                        marginBottom: '0',
                        color: 'white',
                    },
                    content: { padding: '0' },
                    body: { backgroundColor: 'yello' },
                }}
            >
                <ChatClient
                    taskId={Number(attachmentTaskId)}
                    messageTypeId={2}
                    referenceId={referenceId}
                    businessId={businessId}
                />
            </Drawer>
        </div>
    )
}

const mapStateToProps = (state: any): inprogressSatteInterface => {
    return {
        userList: state.user.userList,
        projectDropDownList: state.helpdesk.projectDropDownList,
        taskDetailsListCount: state.helpdesk.taskDetailsListCount,
        userimageList: state.helpdesk.userimageList,
        ProjectManagmentStausList: state.initial.ProjectManagmentStausList,
        ProjectManagmentStausListInprogress:
            state.helpdesk.ProjectManagmentStausListInprogress,
    }
}

export default connect(mapStateToProps)(InprogressTasks)
