import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Avatar, Card, Dropdown, Menu, Modal, Spin } from 'antd'
import TodoStyles from '../../BackLog/backLogList.less'
import {
    FormOutlined,
    UsergroupDeleteOutlined,
    FieldTimeOutlined,
    UnorderedListOutlined,
    PaperClipOutlined,
    WechatOutlined,
} from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { getusersdroplist } from '../../../Services/Helpdesk/Backlog'
import {
    type todoForminterface,
    type todoSatteInterface,
    type TaskData,
    type User,
    type setTasks,
} from '../../../Types/HelpDesk/Cretetask'
import {
    GetSatusList,
    GetTaskData,
    GetTaskList,
    Saveprojectstaus,
    Saveuserstaus,
} from '../../../Services/Helpdesk/CreteTask'
import CreateTaskForm from './CreateTaskForm'
import CreateFollowersModal from './CreateFoolwers'
import CreateHours from './CreateHours'
import CreateattachmentModal from './Attachment'

import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { type TableParamsInterface } from '../../../Types/CommonType'
import { type ProjectManagmentStausListDataTypeInterface } from '../../../Types/ProjectMangement'
import { TaskdefaultData } from './toDoTasks'

export const TaskItemDefaultData = {
    taskId: '',
    taskNo: 0,
    taskName: '',
    priorityName: '',
    priorityColourCode: '',
    taskTypeName: '',
    sortOrder: 0,
    businessTypeName: '',
    clientName: '',
    serviceName: '',
    description: '',
    assignedByName: '',
    assignedToName: '',
    fromDate: '',
    toDate: '',
    daysCount: 0,
    daysColourCode: '',
    status: '',
    statusColourCode: '',
    notifyToStatusChange: 0,
    subTaskViews: [],
    taskFollowerViews: [],
    subTaskStatus: 0,
    isNewChatExist: 0,
    projectTierInfo: '',
    projectStatusId: 0,
    projectStatusLbl: '',
    userStatusId: 0,
    userStatusLbl: '',
}

const CompletedTask = (
    props: todoForminterface & { activeStates: string }
): React.ReactElement => {
    const dispatch = useDispatch()
    const [modalTitleCreate, setModalTitleCreate] = useState('Add Dates')
    const [modalVisibleCreate, setModalVisibleCreate] = useState(false)
    const [modalVisibleCreateHours, setModalVisibleCreateHours] =
        useState(false)
    const [selectedTaskId, setSelectedTaskId] = useState<string>('')
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [, setSelectedStatusProjectId] = useState<string>('')
    const [modalVisibleCreateAttachment, setModalVisibleCreateAttachment] =
        useState(false)
    const [modalVisibleCreateFollowers, setModalVisibleCreateFollowers] =
        useState(false)
    const [attachmentTaskId, setAttachmentTaskId] = useState('')
    const [editData, setEditData] = useState<setTasks>(TaskdefaultData)
    const [tableParams] = useState<TableParamsInterface>(commonTableParams)
    const [isLoading, setLoading] = useState(false)
    const [idChange, setIDchange] = useState('')
    const [, setUerStatusfilter] = useState(3)
    const hasFetchedOnce2 = useRef(false)
    const [CompletedTaskList, setCompletedTask] = useState<TaskData[]>([])
    const {
        userimageList,
        ProjectManagmentStausList,
        setSelectedTaskIds,
        selectedProjectID,
        AssignBY,
        activeItDepartment,
        activeStates,
        taskDetailsList,
        activeTab,
        AssignTo,
        searchString,
        createTaskModalStatus,
    } = props

    const [selectedUsers, setSelectedUsers] = useState<number[]>([])
    const hasFetchedOnce = useRef(false)
    // const [filterProjectStatusID, setFilterProjectStatusID] = useState<
    //     string | undefined
    // >('0')
    const [restData, setResetData] = useState('false')
    const hasFetchedOnce4 = useRef(false)
    const getTableDatas = useCallback(() => {
        let projectStatusName = 'Complete'

        if (activeTab === '2') {
            projectStatusName = 'Complete'
        }

        const todoProject = ProjectManagmentStausList.find(
            (project) => project.projectStatusName === projectStatusName
        )

        const id = todoProject?.projectStatusId

        if (id !== undefined) {
            setUerStatusfilter(3)
            GetTaskList(
                dispatch,
                id?.toString(),
                searchString,
                1,
                100,
                AssignBY,
                AssignTo
            )
        } else {
            setUerStatusfilter(10)
        }
    }, [
        ProjectManagmentStausList,
        selectedProjectID,
        idChange,
        activeStates,
        activeTab,
        AssignBY,
        AssignTo,
        searchString,
        createTaskModalStatus,
    ])
    useEffect(() => {
        if (!hasFetchedOnce4.current) {
            hasFetchedOnce4.current = true
            return
        }
        getTableDatas()
    }, [getTableDatas])

    useEffect(() => {
        const completedList = taskDetailsList.filter(
            (data) =>
                data.projectStatusLbl === 'Complete' &&
                data.userStatusLbl === 'Completed'
        )
        setCompletedTask(completedList)
    }, [taskDetailsList, activeTab])
    const handleModalOpenCreate = (taskId: string): void => {
        setModalVisibleCreate(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Dates')
    }

    const handleModalOpenCreateHours = (taskId: string): void => {
        setModalVisibleCreateHours(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Dates')
    }

    const handleModalOpenCreateFollowers = (taskId: string): void => {
        setModalVisibleCreateFollowers(true)
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Dates')
    }

    const handleModalOpenSubTasks = (taskId: string): void => {
        setAttachmentTaskId(taskId)
        setModalTitleCreate('Add Dates')
    }

    const onAttachment = (taskId: string): void => {
        setModalVisibleCreateAttachment(true)
        setAttachmentTaskId(taskId)
    }

    const handleModalClose = (): void => {
        setModalVisibleCreate(false)
        setModalVisibleCreateAttachment(false)
        setModalVisibleCreateHours(false)
        setModalVisibleCreateFollowers(false)
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveTask = (callBack: CallableFunction): void => {
        changeCreateEditModalStatus()
        callBack()
    }

    useEffect(() => {
        if (!hasFetchedOnce2.current) {
            hasFetchedOnce2.current = true
            return
        }
        void getusersdroplist(dispatch)
    }, [])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetSatusList(dispatch, selectedProjectID.toString(), {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [selectedProjectID])

    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        getTableData()
    }, [getTableData])

    const handleAssignByMenuClick = (
        taskId: string,
        statusProjectId: string
    ): void => {
        setSelectedTaskId(taskId)
        setSelectedStatusProjectId(statusProjectId)
    }

    const todoProjectStatusId = (
        name: string | undefined
    ): string | undefined => {
        const todoProject = ProjectManagmentStausList.find(
            (project: ProjectManagmentStausListDataTypeInterface) =>
                project.projectStatusName === name
        )
        const todoProjectId = todoProject?.projectStatusId.toString()

        return todoProjectId
    }

    const handleProgressChange = async (
        value: number,
        projectStatusName: string
    ): Promise<void> => {
        try {
            setLoading(true)
            const statusId = todoProjectStatusId(projectStatusName)
            let StatusIDS = 0

            if (statusId !== undefined) {
                StatusIDS = Number(statusId)
            } else {
                StatusIDS = 0
            }
            await Saveuserstaus(dispatch, selectedTaskId, value.toString())
            await Saveprojectstaus(
                dispatch,
                selectedTaskId,
                Number(StatusIDS),
                projectStatusName
            )

            setIDchange(selectedTaskId)
            setSelectedTaskIds(selectedTaskId)
            getTableDatas()
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const openEditModal = (id: string): void => {
        void openEditData(id)
    }
    const openEditData = async (id: string): Promise<void> => {
        // const data = taskDetailsList.find((data: TaskData) => {
        //     return data.taskId === id
        // })
        try {
            const data = await GetTaskData(dispatch, id)
            setEditData(data ?? TaskdefaultData)
        } catch (err) {}
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                <Card className={TodoStyles.todoCard} title="">
                    {CompletedTaskList
                        // .filter(
                        //     (task) => task.userStatusId === uerStatusfilter
                        // )
                        .map((task: TaskData) => (
                            <Card
                                key={task.taskId}
                                className={TodoStyles.todoItem}
                            >
                                <div className={TodoStyles.tasknameStyles}>
                                    <div>
                                        <strong>{task.taskName}</strong>
                                        {task.clientName !== '' && (
                                            <span>({task.clientName})</span>
                                        )}
                                    </div>
                                    <div className={TodoStyles.taskAndColor}>
                                        <div>
                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        onClick={({ key }) => {
                                                            handleAssignByMenuClick(
                                                                task.taskId,
                                                                key
                                                            )
                                                        }}
                                                    >
                                                        <Menu.Item
                                                            key="todo"
                                                            onClick={() => {
                                                                void handleProgressChange(
                                                                    1,
                                                                    'To-Do'
                                                                )
                                                            }}
                                                        >
                                                            To Do
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            key="inprogress"
                                                            onClick={() => {
                                                                void handleProgressChange(
                                                                    2,
                                                                    'In-Progress'
                                                                )
                                                            }}
                                                        >
                                                            In Progress
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            key="completed"
                                                            onClick={() => {
                                                                void handleProgressChange(
                                                                    1,
                                                                    'Complete'
                                                                )
                                                            }}
                                                        >
                                                            Completed
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            key="QA"
                                                            onClick={() => {
                                                                void handleProgressChange(
                                                                    1,
                                                                    'QA'
                                                                )
                                                            }}
                                                        >
                                                            QA
                                                        </Menu.Item>
                                                        {Boolean(
                                                            activeItDepartment
                                                        ) && (
                                                            <Menu.Item
                                                                key="QA"
                                                                onClick={() => {
                                                                    void handleProgressChange(
                                                                        1,
                                                                        'QA'
                                                                    )
                                                                }}
                                                            >
                                                                QA
                                                            </Menu.Item>
                                                        )}
                                                    </Menu>
                                                }
                                                visible={
                                                    selectedTaskId ===
                                                    task.taskId
                                                }
                                                onVisibleChange={(visible) => {
                                                    if (!visible) {
                                                        setSelectedTaskId('')
                                                        setSelectedStatusProjectId(
                                                            ''
                                                        )
                                                    }
                                                }}
                                            >
                                                <a
                                                    className="ant-dropdown-link"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setSelectedTaskId(
                                                            task.taskId
                                                        )
                                                    }}
                                                >
                                                    Completed
                                                </a>
                                            </Dropdown>
                                        </div>
                                        <div
                                            className={TodoStyles.circle}
                                            style={{
                                                backgroundColor:
                                                    task.daysColourCode,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    className={TodoStyles.todoAllIconContainer}
                                >
                                    <div className={TodoStyles.iconContainer}>
                                        <FormOutlined
                                            className={TodoStyles.todoIcons}
                                            onClick={() => {
                                                handleModalOpenCreate(
                                                    task.taskId
                                                )
                                                openEditModal(task.taskId)
                                                setResetData('true')
                                            }}
                                        />
                                        <UsergroupDeleteOutlined
                                            className={TodoStyles.todoIcons}
                                            onClick={() => {
                                                handleModalOpenCreateFollowers(
                                                    task.taskId
                                                )
                                            }}
                                        />
                                        <FieldTimeOutlined
                                            className={TodoStyles.todoIcons}
                                            onClick={() => {
                                                handleModalOpenCreateHours(
                                                    task.taskId
                                                )
                                            }}
                                        />
                                        <UnorderedListOutlined
                                            className={TodoStyles.todoIcons}
                                            onClick={() => {
                                                handleModalOpenSubTasks(
                                                    task.taskId
                                                )
                                            }}
                                        />
                                        <PaperClipOutlined
                                            className={TodoStyles.todoIcons}
                                            onClick={() => {
                                                onAttachment(task.taskId)
                                            }}
                                        />
                                        <WechatOutlined
                                            className={TodoStyles.todoIcons}
                                        />
                                    </div>
                                    <div
                                        className={
                                            TodoStyles.todoAvatarContainer
                                        }
                                    >
                                        {userimageList.map((user: User) => {
                                            if (
                                                user.userName ===
                                                task.assignedByName
                                            ) {
                                                return (
                                                    <div
                                                        key={task.taskId}
                                                        className={
                                                            TodoStyles.todoAssignAvatar
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                TodoStyles.todotaskassignedname
                                                            }
                                                        >
                                                            {
                                                                task.assignedByName
                                                            }
                                                        </div>
                                                        <Avatar
                                                            key={user.userName}
                                                            size={30}
                                                            src={user.imageUrl}
                                                        />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}

                                        {userimageList.map((user: User) => {
                                            if (
                                                user.userName ===
                                                task.assignedToName
                                            ) {
                                                return (
                                                    <div
                                                        key={task.taskId}
                                                        className={
                                                            TodoStyles.todoAssignAvatar
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                TodoStyles.todotaskassignedname
                                                            }
                                                        >
                                                            {
                                                                task.assignedToName
                                                            }
                                                        </div>
                                                        <Avatar
                                                            key={user.userName}
                                                            size={30}
                                                            src={user.imageUrl}
                                                        />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                                    </div>
                                </div>
                            </Card>
                        ))}
                </Card>
            </Spin>
            <Modal
                title={modalTitleCreate}
                visible={modalVisibleCreate}
                onCancel={handleModalClose}
                footer={null}
                width={1500}
            >
                <CreateTaskForm
                    taskId={attachmentTaskId.toString()}
                    onSave={onSaveTask}
                    editData={editData}
                    setResetData={setResetData}
                    resetdata={restData}
                />
            </Modal>
            <Modal
                title={modalTitleCreate}
                visible={modalVisibleCreateAttachment}
                onCancel={handleModalClose}
                footer={null}
                width={1500}
            >
                <CreateattachmentModal taskId={attachmentTaskId} />
            </Modal>
            <Modal
                title={modalTitleCreate}
                visible={modalVisibleCreateHours}
                onCancel={handleModalClose}
                footer={null}
                width={1500}
            >
                <CreateHours taskid={attachmentTaskId} />
            </Modal>
            <Modal
                title={modalTitleCreate}
                visible={modalVisibleCreateFollowers}
                onCancel={handleModalClose}
                footer={null}
                width={1500}
            >
                <CreateFollowersModal
                    onSave={onSaveTask}
                    taskid={attachmentTaskId.toString()}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): todoSatteInterface => {
    return {
        userList: state.user.userList,
        projectDropDownList: state.helpdesk.projectDropDownList,
        taskDetailsListCount: state.helpdesk.taskDetailsListCount,
        userimageList: state.helpdesk.userimageList,
        ProjectManagmentStausList: state.initial.ProjectManagmentStausList,
        taskDetailsList: state.helpdesk.taskDetailsList,
    }
}

export default connect(mapStateToProps)(CompletedTask)
