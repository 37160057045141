import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Form, Button, Select, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type ServiceUserAddAssigneeInterface,
    type ServiceUserAddAssigneeStateInterface,
} from '../../../Types/ServiceSetup/ServiveUser'
import { GetDefaultAssignee } from '../../../Services/ServiceSetup'
import { GetUserList } from '../../../Services/User'

const AddAssigneeForm = (
    props: ServiceUserAddAssigneeInterface
): React.ReactElement => {
    const { serviceId, userList, onSave } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [getUserIds, setUserIds] = useState<number[]>([])
    const hasFetchedOnce = useRef(false)
    useEffect(() => {
        if (!hasFetchedOnce.current) {
            hasFetchedOnce.current = true
            return
        }
        GetUserList(dispatch)
    }, [])

    useEffect(() => {
        void GetDefaultAssignee(
            dispatch,
            serviceId,
            (response: { data: string }) => {
                const userIds = response.data
                    ?.split(',')
                    ?.map((id) => parseInt(id.trim()))
                setUserIds(userIds)

                form.setFieldsValue({ userIds })
            }
        )
    }, [serviceId])

    const handleChange = (selectedUserIds: number[]): void => {
        setUserIds(selectedUserIds)
        form.setFieldsValue({ userIds: selectedUserIds })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const resetForm = (): void => {
        setUserIds([])
    }
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    const newObj = {
                        serviceId,
                        userIds: getUserIds,
                    }
                    onSave(newObj, resetForm)
                }}
                requiredMark={customizeRequiredMark}
                form={form}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Default Assignee"
                            name="userIds"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select at least one User!',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Please select"
                                options={userList}
                                size="large"
                                optionLabelProp="label"
                                showSearch
                                defaultActiveFirstOption
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                onChange={handleChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save Assignee">
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): ServiceUserAddAssigneeStateInterface => {
    return {
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(AddAssigneeForm)
